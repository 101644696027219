enum HttpHeader {
  ACCEPT = 'accept',
  AUTHORIZATION = 'authorization',
  CONTENT_TYPE = 'content-type',
  CLIENT_REQUEST_ID = 'x-request-client-id',
}

const CLIENT_REQUEST_ID = 'ta.admin-portal'

export { HttpHeader, CLIENT_REQUEST_ID }
