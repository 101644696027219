type RouteParamValue = string | boolean | number
type RouteParams = Record<string, RouteParamValue>
type Entry = [string, RouteParamValue]

const longestKeyFirst = ([a]: Entry, [b]: Entry) => b.length - a.length

const hydrateRoute = (pathname = '', routeParams: RouteParams = {}) => {
  const trimmed = pathname.replace(/^\//gu, '')

  const hydrated = Object.entries(routeParams)
    .sort(longestKeyFirst)
    .reduce((acc, [key, value]) => acc.replace(new RegExp(`:${key}`, 'g'), encodeURIComponent(`${value}`)), trimmed)

  return `/${hydrated}`
}

export { hydrateRoute }
