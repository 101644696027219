import { CONTENTFUL_MANAGEMENT_API_PERSONAL_ACCESS_TOKEN } from 'config'
import { createClient } from 'contentful-management'
import { useMemo } from 'react'

const MAX_TIMEOUT_SECONDS = 60

const useContentfulClient = () => {
  const client = useMemo(
    () =>
      createClient(
        {
          accessToken: CONTENTFUL_MANAGEMENT_API_PERSONAL_ACCESS_TOKEN,
          timeout: MAX_TIMEOUT_SECONDS * 1_000,
        },
        { type: 'plain' }
      ),
    [CONTENTFUL_MANAGEMENT_API_PERSONAL_ACCESS_TOKEN]
  )

  return client
}

export { useContentfulClient }
