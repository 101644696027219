import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../../../NotFound'

const ProviderDetails = lazy(() =>
  import(
    /* webpackChunkName: "ProviderDetails" */
    'components/pages/Providers/Provider/Details'
  ).then(namedExportToDefaultExport('Details'))
)

const Details = () => (
  <Routes>
    <Route element={<Outlet />} path="/">
      <Route element={<ProviderDetails />} index />
      <Route element={<NotFound />} path="*" />
    </Route>
  </Routes>
)

export { Details }
