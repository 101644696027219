import AppBar from '@material-ui/core/AppBar'
import { DeliveryModelToggle } from './DeliveryModelToggle'
import { Home } from './Home'
import { useStyles } from './index.styles'
import { UserItems } from './UserItems'

const Header = () => {
  const classes = useStyles()

  return (
    <AppBar className={classes.appBar}>
      <div className={classes.root}>
        <div className={classes.left}>
          <Home />
          <DeliveryModelToggle />
        </div>
        <div className={classes.userItems}>
          <UserItems />
        </div>
      </div>
    </AppBar>
  )
}

export { Header }
