import { ReactComponent as OutlookIcon } from 'assets/integrations/outlook.svg'
import { ReactComponent as BlueprintIcon } from 'assets/integrations/blueprint.svg'
import { ReactComponent as SyclePrivateIcon } from 'assets/integrations/sycle-private.svg'
import { ReactComponent as SycleProIcon } from 'assets/integrations/sycle-pro.svg'
import { ReactComponent as TcmIcon } from 'assets/integrations/tcm.svg'
import type { UiCalendarIntegration } from 'types'
import { pick } from '../utils/fp'

enum CalendarIntegrationType {
  BLUEPRINT = 'BLUEPRINT',
  OUTLOOK_INTERNAL = 'OUTLOOK_INTERNAL',
  SYCLE = 'SYCLE',
  SYCLE_PRO = 'SYCLE_PRO',
  TCM = 'TCM',
}

type CalendarIntegrationTypes = Record<CalendarIntegrationType, UiCalendarIntegration>

const calendarIntegrationTypes: CalendarIntegrationTypes = {
  [CalendarIntegrationType.BLUEPRINT]: {
    displayName: 'Blueprint',
    icon: BlueprintIcon,
    subEntityType: 'Email',
    subtext: 'Manage a Blueprint integration',
    type: CalendarIntegrationType.BLUEPRINT,
  },
  [CalendarIntegrationType.OUTLOOK_INTERNAL]: {
    displayName: 'Outlook (Internal)',
    icon: OutlookIcon,
    subEntityType: 'Email',
    subtext: 'Manage an Outlook (Internal) integration',
    type: CalendarIntegrationType.OUTLOOK_INTERNAL,
  },
  [CalendarIntegrationType.SYCLE]: {
    displayName: 'Sycle Private',
    externalId: { length: 6, regex: /\d{4}-\d{1}/ },
    icon: SyclePrivateIcon,
    subEntityType: 'ID',
    subtext: 'Manage a Sycle Private integration',
    type: CalendarIntegrationType.SYCLE,
  },
  [CalendarIntegrationType.SYCLE_PRO]: {
    displayName: 'Sycle Pro',
    externalId: { length: 15, regex: /\d-\d/ },
    icon: SycleProIcon,
    subEntityType: 'ID',
    subtext: 'Manage a Sycle Pro integration',
    type: CalendarIntegrationType.SYCLE_PRO,
  },
  [CalendarIntegrationType.TCM]: {
    displayName: 'TCM',
    externalId: { length: 6, regex: /\d{4}-\d{1}/ },
    icon: TcmIcon,
    subEntityType: 'ID',
    subtext: 'Manage a TCM integration',
    type: CalendarIntegrationType.TCM,
  },
}

const shopCalendarIntegrations: Record<CalendarIntegrationType, UiCalendarIntegration> = pick(
  calendarIntegrationTypes,
  [
    CalendarIntegrationType.SYCLE,
    CalendarIntegrationType.SYCLE_PRO,
    CalendarIntegrationType.TCM,
    CalendarIntegrationType.OUTLOOK_INTERNAL,
    CalendarIntegrationType.BLUEPRINT,
  ]
)

export { CalendarIntegrationType, calendarIntegrationTypes, shopCalendarIntegrations }
export type { CalendarIntegrationTypes }
