import { z } from 'zod'
import { schemaVendorAdditionalFile, schemaVendorCountry, schemaVendorStatus, schemaVendorType } from './vendor'

const schemaFilterableSortBy = z.enum([
  'country',
  'createdAt',
  'email',
  'gid',
  'id',
  'name',
  'status',
  'type',
  'updatedAt',
])

const schemaSortDirection = z.enum(['ASC', 'DESC'])

const schemaGetManyVendorsRequest = z.object({
  country: z.array(z.string()).optional(),
  createdAt: z.array(z.date()).optional(),
  email: z.array(z.string()).optional(),
  gid: z.array(z.string()).optional(),
  id: z.array(z.string()).optional(),
  limit: z.number().optional(),
  name: z.array(z.string()).optional(),
  offset: z.number().optional(),
  sort: z.array(schemaSortDirection).optional(),
  sortBy: z.array(schemaFilterableSortBy).optional(),
  status: z.array(schemaVendorStatus).optional(),
  type: z.array(schemaVendorType).optional(),
  updatedAt: z.array(z.date()).optional(),
  updatedBy: z.array(z.string()).optional(),
})

const schemaCreateVendorRequest = z.object({
  approverEmail: z.string(),
  comments: z.string().optional(),
  country: schemaVendorCountry,
  email: z.string(),
  expiresAt: z.date(),
  name: z.string(),
  phone: z.string(),
  type: schemaVendorType,
})

const schemaActivateVendorRequest = z.object({
  comments: z.string().optional(),
  id: z.string(),
})

const schemaRejectVendorRequest = z.object({
  comments: z.string().optional(),
  id: z.string(),
})

const schemaUpdateVendorRequest = z.object({
  additionalFiles: z.array(schemaVendorAdditionalFile).optional(),
  approverEmail: z.string().optional(),
  email: z.string().optional(),
  expiresAt: z.date().optional(),
  id: z.string(),
  phone: z.string().optional(),
})

export type ActivateVendorRequest = z.infer<typeof schemaActivateVendorRequest>
export type GetManyVendorsRequest = z.infer<typeof schemaGetManyVendorsRequest>
export type CreateVendorRequest = z.infer<typeof schemaCreateVendorRequest>
export type RejectVendorRequest = z.infer<typeof schemaRejectVendorRequest>
export type UpdateVendorRequest = z.infer<typeof schemaUpdateVendorRequest>
export type FilterableSortBySchema = z.infer<typeof schemaFilterableSortBy>

export {
  schemaActivateVendorRequest,
  schemaFilterableSortBy,
  schemaSortDirection,
  schemaGetManyVendorsRequest,
  schemaCreateVendorRequest,
  schemaRejectVendorRequest,
  schemaUpdateVendorRequest,
}
