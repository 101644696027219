import { FitterAccount } from 'types'
import { useRequest } from '../useRequest'
import { useServiceCountryUrl } from '../useServiceCountryUrl'

enum ApiPathname {
  ACCOUNT = '/accounts/:accountId',
  ACCOUNT_SEARCH = '/accounts/search',
  MY_ACCOUNT = '/accounts/me',
}

type GetMyAccountQuery = undefined
type GetMyAccountResponse = FitterAccount

interface UseHookContext {
  getMyAccount: (query: GetMyAccountQuery) => Promise<GetMyAccountResponse>
}

const useAccountService = (): UseHookContext => {
  const baseUrl = useServiceCountryUrl('account')

  const getMyAccount = useRequest<GetMyAccountQuery, GetMyAccountResponse>({
    baseUrl,
    pathname: ApiPathname.MY_ACCOUNT,
  })

  return { getMyAccount }
}

export { useAccountService }
