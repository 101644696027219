enum Role {
  ADMIN = 'Admin',
  EMPLOYEE_FITTER = 'EmployeeFitter',
  PARTNER_FITTER = 'PartnerFitter',
  OPERATIONS = 'Ops',
}

enum RoleFlag {
  isAdmin = 'isAdmin',
  isEmployeeFitter = 'isEmployeeFitter',
  isFitter = 'isFitter',
  isInternalFitter = 'isInternalFitter',
  isPartnerFitter = 'isPartnerFitter',
  isOperations = 'isOperations',
}

export { Role, RoleFlag }
