import { DeliveryModel, textConstants } from 'constants/'

const deliveryModelDisplayText: Record<DeliveryModel, string> = {
  [DeliveryModel.DIRECT_TO_CONSUMER]: 'Direct To Consumer',
  [DeliveryModel.NETWORK]: 'Network',
  [DeliveryModel.NETWORK_HOME_DELIVERY]: 'Network Home Delivery',
  [DeliveryModel.TELEAUDIOLOGY]: 'Teleaudiology',
}

const toDeliveryModelDisplayText = (type: DeliveryModel) => deliveryModelDisplayText[type] ?? textConstants.unknown

export { toDeliveryModelDisplayText }
