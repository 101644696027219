import { ReactNode, createContext, useContext } from 'react'
import { UseQueryResult, useQuery } from 'react-query'
import { logFactory } from 'utils'
import { UserRole, useUserService } from '../services/useUserService'

const hookName = 'useUserRoles'
const log = logFactory(hookName)

type UserRolesContext = UseQueryResult<UserRole[]>

const context = createContext<Partial<UserRolesContext>>({})
const { Provider } = context

const UserRolesProvider = ({ children }: { children: ReactNode }) => {
  const { getRoles } = useUserService()

  const doQuery = async () => {
    log(`🙄 Fetching user roles...`)

    const options = await getRoles(undefined)

    log(`🙄 Returned user roles...`, options)

    return options
  }

  const value = useQuery([hookName], doQuery, {
    refetchOnWindowFocus: false,
  })

  return <Provider value={value}>{children}</Provider>
}

const useUserRoles = () => useContext(context)

export { UserRolesProvider, useUserRoles, hookName as useUserRolesHookName }
