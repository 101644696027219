import { CurrentVendorProvider } from 'hooks'
import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../../NotFound'

const Vendor = lazy(() =>
  import(
    /* webpackChunkName: "Vendor" */
    'components/pages/Vendors/Vendor'
  ).then(namedExportToDefaultExport('Vendor'))
)

const Router = () => (
  <CurrentVendorProvider>
    <Routes>
      <Route element={<Outlet />} path="/">
        <Route element={<Vendor />} index />
        <Route element={<NotFound />} path="*" />
      </Route>
    </Routes>
  </CurrentVendorProvider>
)

export { Router as Vendor }
