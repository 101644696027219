import { Typography } from '@material-ui/core'
import cx from 'clsx'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useStyles } from './index.styles'

interface Props {
  children?: ReactNode
  className?: string
  title: string
  entity?: string
  pageTitle?: string
}

const appTitle = 'Hear.com | Admin Portal'

const PageTitle = ({ children, className, title, entity, pageTitle }: Props) => {
  const styles = useStyles()
  const [isPinned, bePinned] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const el = ref.current

    const observer = new IntersectionObserver(([e]) => bePinned(e.intersectionRatio < 1), {
      rootMargin: '-88px 0px 0px 0px',
      threshold: [1],
    })

    observer.observe(el as HTMLDivElement)

    return () => observer.disconnect()
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${appTitle}: ${pageTitle ?? title}`}</title>
      </Helmet>
      <div className={cx(styles.container, { [styles.pinned]: isPinned })} ref={ref}>
        <Typography variant="h1">
          {title && <span className={styles.title}>{title}</span>}
          {entity && (
            <>
              <span className={styles.title}>:&nbsp;</span>
              <span className={styles.entity}>{entity}</span>
            </>
          )}
        </Typography>
        {children && <div className={cx(styles.children, className)}>{children}</div>}
        <div className={cx(styles.after, { [styles.afterPinned]: isPinned })} />
      </div>
    </>
  )
}

export { PageTitle }
