import { schemaSortDirection, validation } from 'utils'
import { z } from 'zod'
import { schemaUser } from './user'
import { schemaRole } from './role'

const schemaCreateUserRequest = schemaUser
  .pick({
    allowedCountries: true,
    allowedDeliveryModels: true,
    email: true,
    familyName: true,
    givenName: true,
    isActive: true,
    photoUrl: true,
    roles: true,
  })
  .strict()

type CreateUserRequest = z.infer<typeof schemaCreateUserRequest>

const sortableProps = ['createdAt', 'email', 'isActive', 'updatedAt', 'name'] as const
const schemaSortBy = z.enum(sortableProps)

const schemaGetManyUsersRequest = z
  .object({
    createdAt: z.array(validation.date).optional(),
    email: z.array(validation.email).optional(),
    gid: z.array(validation.string).optional(),
    id: z.array(validation.uuid).optional(),
    isActive: z.array(validation.boolean).optional(),
    limit: validation.number.optional(),
    offset: validation.number.optional(),
    roles: z.array(schemaRole).default([]).optional(),
    search: validation.string.optional(),
    sort: z.array(schemaSortDirection).optional(),
    sortBy: z.array(schemaSortBy).optional(),
    updatedAt: z.array(validation.date).optional(),
  })
  .strict()

type GetManyUsersRequest = z.infer<typeof schemaGetManyUsersRequest>

const schemaGetImpersonationRequest = schemaUser.pick({ id: true })

type GetImpersonationRequest = z.infer<typeof schemaGetImpersonationRequest>

const schemaCreateOneImpersonation = schemaUser.pick({ id: true }).extend({ userIdToImpersonate: validation.uuid })

type CreateOneImpersonationRequest = z.infer<typeof schemaCreateOneImpersonation>

const schemaDeleteOneImpersonationRequest = schemaUser.pick({ id: true })

type DeleteOneImpersonationRequest = z.infer<typeof schemaDeleteOneImpersonationRequest>

const schemaUpdateOneUserRequest = schemaUser
  .pick({
    allowedCountries: true,
    allowedDeliveryModels: true,
    // email: true, // Email update not allowed at this time [2023-10-09]
    familyName: true,
    givenName: true,
    isActive: true,
    photoUrl: true,
    roles: true,
  })
  .partial()
  .extend({
    id: validation.uuid,
  })
  .strict()

type UpdateOneUserRequest = z.infer<typeof schemaUpdateOneUserRequest>

const schemaDeleteOneUserRequest = validation.uuid

type DeleteOneUserRequest = z.infer<typeof schemaDeleteOneUserRequest>

export {
  schemaCreateOneImpersonation,
  schemaCreateUserRequest,
  schemaDeleteOneUserRequest,
  schemaGetImpersonationRequest,
  schemaGetManyUsersRequest,
  schemaUpdateOneUserRequest,
}

export type {
  CreateUserRequest,
  DeleteOneUserRequest,
  GetImpersonationRequest,
  GetManyUsersRequest,
  CreateOneImpersonationRequest as CreateOneImpersonationRequest,
  DeleteOneImpersonationRequest as DeleteOneImpersonationRequest,
  UpdateOneUserRequest,
}
