import {
  Box,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableSortLabel as MuiTableSortLabel,
  Typography,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { SortDirection } from 'constants/'
import { MouseEvent, useCallback } from 'react'

interface Props<Data> {
  cells: Column<Data>[]
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void
  sort: {
    by: keyof Data
    direction: SortDirection
  }
}

interface Column<Data> {
  disablePadding?: boolean
  hide?: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  id: keyof Data | (string & {})
  isSortable?: boolean
  justify?: 'left' | 'right'
  label: string
  width?: string | number
}

const toMuiOrder = (order: SortDirection) => (order === SortDirection.DESCENDING ? 'desc' : 'asc')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableHead = <Data extends Record<string, any>>({ cells, onRequestSort, sort }: Props<Data>) => {
  const onHeaderSortClick = (property: keyof Data) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const toSortDirection = useCallback(
    (cell: Column<Data>) => {
      if (cell.isSortable === false || sort.by !== cell.id) {
        return undefined
      }

      return toMuiOrder(sort.direction)
    },
    [sort]
  )

  return (
    <MuiTableHead>
      <MuiTableRow>
        {cells
          .filter((cell) => !cell.hide)
          .map((cell) => (
            <MuiTableCell
              align={cell.justify ?? 'left'}
              key={cell.id as string}
              padding={cell.disablePadding ? 'none' : 'normal'}
              sortDirection={toSortDirection(cell)}
              width={cell.width}
            >
              {cell.isSortable === false ? (
                <Typography fontWeight={500} variant="body2">
                  {cell.label}
                </Typography>
              ) : (
                <MuiTableSortLabel
                  active={sort.by === cell.id}
                  direction={sort.by === cell.id ? toMuiOrder(sort.direction) : 'asc'}
                  onClick={cell.id && onHeaderSortClick(cell.id)}
                >
                  {cell.label}
                  {sort.by === cell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {sort.direction === SortDirection.DESCENDING ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </MuiTableSortLabel>
              )}
            </MuiTableCell>
          ))}
      </MuiTableRow>
    </MuiTableHead>
  )
}

export type { Column as HeadCell }
export { TableHead }
