import { RouteParameter } from 'constants/'
import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport, pathnameToRouteParameter } from 'utils'
import { NotFound } from '../../../../NotFound'

const Integration = lazy(() =>
  import(
    /* webpackChunkName: "ShopIntegration" */
    'components/pages/Scheduling/Shops/Shop/Integrations/Integration'
  ).then(namedExportToDefaultExport('Integration'))
)

const IntegrationsList = lazy(() =>
  import(
    /* webpackChunkName: "ShopIntegrations" */
    'components/pages/Scheduling/Shops/Shop/Integrations'
  ).then(namedExportToDefaultExport('Integrations'))
)

const shopIntegrationRouteParameter = pathnameToRouteParameter(RouteParameter.INTEGRATION_TYPE)

const Integrations = () => (
  <Routes>
    <Route element={<Outlet />} path="/">
      <Route element={<IntegrationsList />} index />
      <Route element={<Integration />} path={`/${shopIntegrationRouteParameter}`} />
      <Route element={<NotFound />} path="*" />
    </Route>
  </Routes>
)

export { Integrations }
