import { App } from 'app'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { startup } from 'startup'
import reportWebVitals from './reportWebVitals'

// Initialize anything that needs to be init'd before the React app is mounted
startup()

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
