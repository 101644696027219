import { PathEntityName } from 'constants/'
import { CurrentResourceProvider } from 'hooks'
import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../../../NotFound'
import { Integrations } from './Integrations'

const Entity = lazy(() =>
  import(
    /* webpackChunkName: "Resource" */
    'components/pages/Scheduling/Resources/Resource'
  ).then(namedExportToDefaultExport('Resource'))
)

const Availabilities = lazy(() =>
  import(
    /* webpackChunkName: "Resource" */
    'components/pages/Scheduling/Resources/Resource/Availabilities'
  ).then(namedExportToDefaultExport('Availabilities'))
)

const Schedule = lazy(() =>
  import(
    /* webpackChunkName: "ManageResourceSchedule" */
    'components/pages/Scheduling/Resources/Resource/Schedule'
  ).then(namedExportToDefaultExport('Schedule'))
)

const Router = () => (
  <CurrentResourceProvider>
    <Routes>
      <Route element={<Outlet />} path="/">
        <Route element={<Entity />} index />
        <Route element={<Availabilities />} path={PathEntityName.AVAILABILITIES} />
        <Route element={<Schedule />} path={PathEntityName.SCHEDULE} />
        <Route element={<Integrations />} path={`${PathEntityName.INTEGRATIONS}/*`} />
        <Route element={<NotFound />} path="*" />
      </Route>
    </Routes>
  </CurrentResourceProvider>
)

export { Router as Resource }
