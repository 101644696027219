import { PartnerServiceHearingTestPackageType } from 'hooks'
import { Account } from './account'

const Affiliation = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
} as const

type Affiliation = (typeof Affiliation)[keyof typeof Affiliation]

const Certification = {
  DOCTOR_AUDIOLOGY: 'DOCTOR_AUDIOLOGY',
  HEARING_AID_DISPENSER: 'HEARING_AID_DISPENSER',
  HEARING_INSTRUMENT_SPECIALIST: 'HEARING_INSTRUMENT_SPECIALIST',
} as const

type Certification = (typeof Certification)[keyof typeof Certification]

const Country = {
  CA: 'CA',
  CH: 'CH',
  DE: 'DE',
  US: 'US',
} as const

type Country = (typeof Country)[keyof typeof Country]

const Role = {
  FITTER: 'FITTER',
  OWNER: 'OWNER',
  PARTNER_CARE_COORDINATOR: 'PCC',
} as const

type Role = (typeof Role)[keyof typeof Role]

const Salutation = {
  DOCTOR: 'DOCTOR',
  DOCTOR_MEDICAL: 'DOCTOR_MEDICAL',
  MISS: 'MISS',
  MISSES: 'MISSES',
  MISTER: 'MISTER',
  PROFESSOR: 'PROFESSOR',
  PROFESSOR_DOCTOR: 'PROFESSOR_DOCTOR',
  PROFESSOR_DOCTOR_DOCTOR: 'PROFESSOR_DOCTOR_DOCTOR',
} as const

type Salutation = (typeof Salutation)[keyof typeof Salutation]

interface Address {
  city: string
  line1: string
  state: string
  country: string
  postalCode: string
}

interface Exclusion {
  expiration: Date
  deliveryModels: string[]
}

interface PartnerEmployee {
  address?: Address
  affiliation: Affiliation
  certification?: Certification
  country: Country
  createdAt: string
  createdBy: Account
  dateOfBirth?: string
  email: string
  exclusion?: Exclusion
  familyName: string
  gid?: string
  givenName: string
  id: string
  isActive: boolean
  phone?: string
  roles: Role[]
  salesforceId?: string
  salutation?: Salutation
  timezone?: string
  updatedAt: string
  updatedBy: Account
  userId?: string
}

interface License {
  country: Country
  createdAt: string
  createdBy: Account
  hearingTestPackageType: PartnerServiceHearingTestPackageType | null
  id: string
  number: string
  partnerEmployeeId: string
  state?: string
  updatedAt: string
  updatedBy: Account
  validFrom: string
  validTo?: string
}

export type {
  Address as PartnerServicePartnerEmployeeAddress,
  License as PartnerServicePartnerEmployeeLicense,
  PartnerEmployee as PartnerServicePartnerEmployee,
  Exclusion as PartnerServiceExclusion,
}

export {
  Affiliation as PartnerServicePartnerEmployeeAffiliation,
  Country as PartnerServicePartnerEmployeeCountry,
  Role as PartnerServicePartnerEmployeeRole,
  Certification as PartnerServicePartnerEmployeeCertification,
  Salutation as PartnerServicePartnerEmployeeSalutation,
}
