import { PathEntityName } from 'constants/'
import { CurrentPartnerEmployeeProvider } from 'hooks'
import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../../NotFound'
import { Licenses } from './Licenses'
import { Details } from './Details'

const Provider = lazy(() =>
  import(
    /* webpackChunkName: "Provider" */
    'components/pages/Providers/Provider'
  ).then(namedExportToDefaultExport('Provider'))
)

const Router = () => (
  <CurrentPartnerEmployeeProvider>
    <Routes>
      <Route element={<Outlet />} path="/">
        <Route element={<Provider />} index />
        <Route element={<Details />} path={`${PathEntityName.DETAILS}/*`} />
        <Route element={<Licenses />} path={`${PathEntityName.LICENSES}/*`} />
        <Route element={<NotFound />} path="*" />
      </Route>
    </Routes>
  </CurrentPartnerEmployeeProvider>
)

export { Router as Provider }
