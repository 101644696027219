import { HttpMethod } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { ApiPathname } from '../../constants'
import {
  PartnerServiceCreateOneHearingTestPackageTypeConfigRequest,
  PartnerServiceHearingTestPackageTypeConfig,
  PartnerServiceUpdateHearingTestPackageTypeConfigRequest,
} from '../../types'

const service = (baseUrl: URL) => {
  const createHearingTestPackageTypeConfig = useRequest<
    PartnerServiceCreateOneHearingTestPackageTypeConfigRequest,
    PartnerServiceHearingTestPackageTypeConfig
  >({
    baseUrl,
    method: HttpMethod.POST,
    pathname: ApiPathname.HEARING_TEST_PACKAGE_TYPE_CONFIG,
  })

  const getHearingTestPackageTypeConfigRequest = useRequest<undefined, PartnerServiceHearingTestPackageTypeConfig>({
    baseUrl,
    pathname: ApiPathname.HEARING_TEST_PACKAGE_TYPE_CONFIG,
  })

  const getHearingTestPackageTypeConfig = useCallback(getHearingTestPackageTypeConfigRequest, [
    getHearingTestPackageTypeConfigRequest,
  ])

  const updateHearingTestPackageTypeConfigRequest = useRequest<
    PartnerServiceUpdateHearingTestPackageTypeConfigRequest,
    PartnerServiceHearingTestPackageTypeConfig
  >({
    baseUrl,
    filterQueryToRequestBody: (request) => request,
    method: HttpMethod.POST,
    pathname: ApiPathname.HEARING_TEST_PACKAGE_TYPE_CONFIG,
  })

  const updateHearingTestPackageTypeConfig = useCallback(
    (request: PartnerServiceUpdateHearingTestPackageTypeConfigRequest) =>
      updateHearingTestPackageTypeConfigRequest(request),
    [updateHearingTestPackageTypeConfigRequest]
  )

  return {
    createHearingTestPackageTypeConfig,
    getHearingTestPackageTypeConfig,
    updateHearingTestPackageTypeConfig,
  }
}

export { service as useHearingTestPackageTypeConfigService }
