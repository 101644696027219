import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  SvgIconTypeMap,
  Tooltip as MuiTooltip,
} from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { CalendarTodayOutlined, Home, Loyalty, PeopleAlt, RecentActors } from '@material-ui/icons'
import { Permission, Route } from 'constants/'
import { useFeatureFlags, useKeyBindings, useUser, useUserSettings } from 'hooks'
import { ReactElement, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from '../../../shared/Link'
import { Button } from './Button'
import { useListIconStyles, useListItemStyles, useListItemTextStyles, useListStyles, useStyles } from './index.styles'

interface TopLevelRoute {
  children?: TopLevelRoute[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>>
  name: string
  requiredPermissions?: Permission[]
  requiresAdmin?: boolean
  route: Route
}

interface ConditionalTooltipProps {
  children: ReactElement
  hasTooltip?: boolean
  title: string
}

const drawerWidth = '10rem'

const Container = styled('div')(() => ({
  position: 'sticky',
  top: '5.75rem',
  zIndex: 1_000,

  /* eslint-disable sort-keys */
  '&:hover > button': {
    display: 'flex',
    opacity: 1,
  },

  '&:focus-within > button': {
    display: 'flex',
    opacity: 1,
  },
  /* eslint-enable sort-keys */
}))

// This is the display order
const topLevelRoutes: TopLevelRoute[] = [
  { icon: Home, name: 'Home', route: Route.HOME },
  { icon: CalendarTodayOutlined, name: 'Scheduling', route: Route.SCHEDULING },
  { icon: PeopleAlt, name: 'Providers', route: Route.PROVIDERS },
  { icon: RecentActors, name: 'Users', requiresAdmin: true, route: Route.USERS },
  { icon: Loyalty, name: 'Vendors', requiredPermissions: [Permission.VENDORS_MARKETING_READ], route: Route.VENDORS },
]

// First match found is considered active. This should be the exhaustive list of top level nav items in the Sidebar
const activeRouteOrder: Route[] = [Route.PROVIDERS, Route.USERS, Route.SCHEDULING, Route.VENDORS, Route.HOME]

const ConditionalTooltip = ({ children, hasTooltip, title }: ConditionalTooltipProps) => {
  if (!hasTooltip) {
    return <>{children}</>
  }

  return (
    <MuiTooltip arrow enterDelay={1_000} placement="right" title={title}>
      {children}
    </MuiTooltip>
  )
}

const Sidebar = () => {
  const { read: readUserSetting, write: writeUserSetting } = useUserSettings()
  const [isOpen, beOpen] = useState(Boolean(readUserSetting('isSidebarOpen')))
  const [enabledVendorManagement] = useFeatureFlags(['ENABLE_VENDOR_MANAGEMENT'])
  const width = isOpen ? drawerWidth : '3.5rem'
  const classes = useStyles({ width })
  const listClasses = useListStyles()
  const listItemClasses = useListItemStyles()
  const listIconClasses = useListIconStyles()
  const listItemTextClasses = useListItemTextStyles({ isOpen })
  const location = useLocation()
  const user = useUser()

  const isAdmin = user?.roleFlags?.isAdmin

  const onToggleDrawer = () => {
    const newIsOpen = !isOpen

    beOpen(newIsOpen)
    writeUserSetting('isSidebarOpen', newIsOpen)
  }

  useKeyBindings({
    '{': onToggleDrawer,
  })

  const filteredTopLevelRoutes = enabledVendorManagement
    ? topLevelRoutes
    : topLevelRoutes.filter((route) => route.name !== 'Vendors')

  return (
    <Container>
      <Button isOpen={isOpen} onClose={onToggleDrawer} onOpen={onToggleDrawer} />
      <Drawer classes={classes} open={isOpen} variant="permanent">
        <List classes={listClasses}>
          {filteredTopLevelRoutes.map(({ icon: Icon, name, route, requiredPermissions, requiresAdmin }) => {
            const activeRoute = activeRouteOrder.find((route) => location.pathname.toLowerCase().startsWith(route))
            const isActive = activeRoute === route

            if (requiresAdmin && !isAdmin) return null
            if (
              requiredPermissions?.length &&
              !requiredPermissions.some((permission) => user.permissions.has(permission))
            )
              return null

            return (
              <ListItem aria-selected={isActive} classes={listItemClasses} component={Link} key={route} to={route}>
                <ConditionalTooltip hasTooltip={!isOpen} title={name}>
                  <ListItemIcon classes={listIconClasses}>
                    <Icon />
                  </ListItemIcon>
                </ConditionalTooltip>
                <ListItemText classes={listItemTextClasses} primary={name} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
    </Container>
  )
}

export { Sidebar }
