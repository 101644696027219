import { z } from 'zod'
import { schemaCountry } from './country'
import { schemaDeliveryModel } from './delivery-model'
import { validation } from 'utils'

const auditUserTypes = {
  SYSTEM: 'SYSTEM',
  USER: 'USER',
} as const

type AuditUserType = keyof typeof auditUserTypes

const schemaAuditUserType = z.preprocess((value: unknown) => {
  if (typeof value === 'string') {
    return value.toUpperCase()
  }

  return value
}, z.nativeEnum(auditUserTypes))

const schemaAuditHumanUser = z
  .object({
    allowedCountries: z.array(schemaCountry).optional().default([]),
    allowedDeliveryModels: z.array(schemaDeliveryModel).optional().default([]),
    email: validation.email,
    id: validation.uuid.optional(),
    type: z.literal(auditUserTypes.USER),
  })
  .passthrough()

type AuditHumanUser = z.infer<typeof schemaAuditHumanUser>

const auditSystems = {
  ACCOUNT_SERVICE: 'ACCOUNT_SERVICE',
  SALESFORCE: 'SALESFORCE',
} as const

type AuditSystem = string | (typeof auditSystems)[keyof typeof auditSystems]

const schemaAuditSystem = z.preprocess((value: unknown) => {
  if (typeof value === 'string') {
    return value.toUpperCase()
  }

  return value
}, validation.string)

const schemaAuditSystemUser = z
  .object({
    system: schemaAuditSystem,
    type: z.literal(auditUserTypes.SYSTEM),
  })
  .passthrough()

type AuditSystemUser = z.infer<typeof schemaAuditSystemUser>

const schemaAuditUser = z.discriminatedUnion('type', [schemaAuditHumanUser, schemaAuditSystemUser])

type AuditUser = z.infer<typeof schemaAuditUser>

export type { AuditHumanUser, AuditSystem, AuditSystemUser, AuditUser, AuditUserType }

export {
  auditSystems,
  auditUserTypes,
  schemaAuditHumanUser,
  schemaAuditSystemUser,
  schemaAuditUser,
  schemaAuditUserType,
}
