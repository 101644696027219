import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'grid',
      height: '100%',
      placeItems: 'center',
      width: '100%',
    },
  })
)

export { useStyles }
