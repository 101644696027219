import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material'
import { SortDirection } from 'constants/'
import { ChangeEvent, MouseEvent, ReactNode } from 'react'
import { HeadCell, TableHead } from '../TableHead'
import { Loading } from './Loading'

interface Props<Data> {
  columns: HeadCell<Data>[]
  hasFetched?: boolean
  noData?: ReactNode
  onPageChange: (nextPage: number) => void
  onPageSizeChange: (nextSize: number) => void
  onSortChange: (sort: { by: keyof Data; direction: SortDirection }) => void
  page: number
  pageSize: number
  renderRow: (row: Data) => ReactNode
  rows: Data[]
  sort: {
    by: keyof Data
    direction: SortDirection
  }
  totalRows: number
}

const Status = {
  FETCHING_WITH_NO_DATA: 'FETCHING_WITH_NO_DATA',
  FETCHING_WITH_STALE_DATA: 'FETCHING_WITH_STALE_DATA',
  IDLE: 'IDLE',
  IDLE_NO_DATA: 'IDLE_NO_DATA',
  IDLE_WITH_DATA: 'IDLE_WITH_DATA',
} as const

type Status = (typeof Status)[keyof typeof Status]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EnhancedTable = <Data extends Record<string, any>>({
  columns: headCells,
  hasFetched = false,
  noData = 'No data',
  onPageChange,
  onPageSizeChange,
  onSortChange,
  page,
  pageSize,
  renderRow,
  rows,
  sort,
  totalRows,
}: Props<Data>) => {
  const onSortChangeProxy = (_event: MouseEvent<unknown>, by: keyof Data) => {
    const isAsc = sort.by === by && sort.direction === SortDirection.ASCENDING
    const direction = isAsc ? SortDirection.DESCENDING : SortDirection.ASCENDING

    onSortChange({ by, direction })
  }

  const onPageChangeProxy = (_event: unknown, newPage: number) => onPageChange(newPage)

  const onPageSizeChangeProxy = (event: ChangeEvent<HTMLInputElement>) =>
    onPageSizeChange(parseInt(event.target.value, 10))

  let dataState: Status

  switch (true) {
    case rows.length === 0 && hasFetched:
      dataState = Status.IDLE_NO_DATA
      break
    case rows.length === 0 && !hasFetched:
      dataState = Status.FETCHING_WITH_NO_DATA
      break
    case rows.length > 0 && !hasFetched:
      dataState = Status.FETCHING_WITH_STALE_DATA
      break
    case rows.length > 0 && hasFetched:
      dataState = Status.IDLE_WITH_DATA
      break
    default:
      dataState = Status.IDLE
      break
  }

  let Component
  let Overlay = <></>

  switch (dataState) {
    case Status.FETCHING_WITH_NO_DATA:
      Component = (
        <TableRow>
          <TableCell colSpan={headCells.length}>
            <Box
              sx={{
                display: 'grid',
                placeItems: 'center',
                width: '100%',
              }}
            >
              <Loading />
            </Box>
          </TableCell>
        </TableRow>
      )
      break
    case Status.IDLE_NO_DATA:
      Component = (
        <TableRow>
          <TableCell colSpan={headCells.length}>
            <Box
              sx={{
                display: 'grid',
                placeItems: 'center',
                width: '100%',
              }}
            >
              {noData}
            </Box>
          </TableCell>
        </TableRow>
      )
      break
    case Status.FETCHING_WITH_STALE_DATA:
      Component = <>{rows.map(renderRow)}</>

      Overlay = (
        <Box
          sx={{
            alignItems: 'start',
            background: 'rgba(255, 255, 255, 0.5)',
            bottom: 0,
            display: 'grid',
            justifyItems: 'center',
            left: 0,
            paddingTop: '8rem',
            position: 'absolute',
            right: 0,
            top: 0,
            width: '100%',
          }}
        >
          <Loading />
        </Box>
      )
      break
    case Status.IDLE_WITH_DATA:
      Component = <>{rows.map(renderRow)}</>
      break
    default:
      Component = <></>
      break
  }

  return (
    <TableContainer style={{ position: 'relative' }}>
      <Table aria-labelledby="tableTitle" size="medium" sx={{ minWidth: 750 }}>
        <TableHead<Data> cells={headCells} onRequestSort={onSortChangeProxy} sort={sort} />
        <TableBody>{Component}</TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalRows}
        onPageChange={onPageChangeProxy}
        onRowsPerPageChange={onPageSizeChangeProxy}
        page={page}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[10, 25, 50, 100]}
        showFirstButton
        showLastButton
      />
      {Overlay}
    </TableContainer>
  )
}

export { EnhancedTable }
