import { LogrocketTracker } from '@audibene/ta-browser-tracker'
import { APP_VERSION, isLiveEnvironment, LOGROCKET_PROJECT_ID } from 'config'
import { logFactory } from 'utils'

const log = logFactory('startup:logRocket')

const logRocket = new LogrocketTracker({ appVersion: APP_VERSION, id: LOGROCKET_PROJECT_ID })

const init = () => {
  if (isLiveEnvironment && Boolean(LOGROCKET_PROJECT_ID)) {
    log('🚀 LogRocket initializing...', LOGROCKET_PROJECT_ID)
    logRocket.init()
    log('🚀 LogRocket initialized')
  }
}

export { logRocket, init }
