const textConstants = {
  appName: 'Admin Portal',
  missingData: 'Not available',
  nameUnknown: '-- no name provided --',
  requestFailed: 'An error occurred. Please try again.',
  unknown: 'Unknown',
}

const failureMessage = 'Please try refreshing the page. If issue persists, please reach out to your Partner Manager'

const documentTypeMapper: Record<string, string> = {
  'medical-waiver': 'Statement of Medical Waiver',
  notice: 'HIPAA Notice of Privacy Practices',
  'purchase-delivery-receipt': 'Purchase Delivery Receipt',
}

export { documentTypeMapper, failureMessage, textConstants }
