enum ApiPathname {
  HEARING_TEST_PACKAGE_TYPE_CONFIG = '/api/v1/hearing-test-package-type-config',
  LICENSE = '/api/v1/licenses/:id',
  LICENSE_ELIGIBLE_HEARING_TEST_PACKAGE_TYPES = '/api/v1/licenses/eligible-hearing-test-package-types',
  LICENSES = '/api/v1/licenses',
  OPTIONS_COUNTRIES = '/api/v1/options/countries',
  OPTIONS_COUNTRY_STATES = '/api/v1/options/countries/:country/states',
  PARTNER_EMPLOYEE = '/api/v1/partner-employees/:id',
  PARTNER_EMPLOYEES = '/api/v1/partner-employees',
}

export { ApiPathname }
