import { legacyDefaultPagination } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { ApiPathname } from '../../constants'
import { appointmentsQueryToSearchParams } from '../../filters'
import { GetAppointmentsQuery, GetAppointmentsResponse } from '../../types'

const appointmentMethods = (baseUrl: URL) => {
  const getAppointmentsRequest = useRequest<GetAppointmentsQuery, GetAppointmentsResponse>({
    baseUrl,
    filterQueryToSearchParams: appointmentsQueryToSearchParams,
    pathname: ApiPathname.APPOINTMENTS,
  })

  const getAppointments = useCallback(
    (query: GetAppointmentsQuery) =>
      getAppointmentsRequest({
        ...legacyDefaultPagination,
        ...query,
      }),
    [getAppointmentsRequest]
  )

  return { getAppointments }
}

export { appointmentMethods }
