import { PartnerServicePartnerEmployeeLicense } from 'types'
import { useServiceCountryUrl } from '../useServiceCountryUrl'
import {
  useHearingTestPackageTypeConfigService,
  useOptionsService,
  usePartnerEmployeeLicensesService,
  usePartnerEmployeesService,
} from './methods'
import {
  CreatePartnerEmployeeLicenseRequest,
  DeletePartnerEmployeeLicenseRequest,
  GetCountryStatesRequest,
  GetCountryStatesResponse,
  GetPartnerEmployeeEligibleHearingTestPackageTypesRequest,
  GetPartnerEmployeeEligibleHearingTestPackageTypesResponse,
  GetPartnerEmployeeLicensesQuery,
  GetPartnerEmployeeLicensesResponse,
  GetPartnerEmployeesQuery,
  GetPartnerEmployeesResponse,
  PartnerServiceCreateOneHearingTestPackageTypeConfigRequest,
  PartnerServiceHearingTestPackageTypeConfig,
  PartnerServiceUpdateHearingTestPackageTypeConfigRequest,
  UpdatePartnerEmployeeLicenseRequest,
} from './types'

interface UseHookContext {
  createHearingTestPackageTypeConfig: (
    request: PartnerServiceCreateOneHearingTestPackageTypeConfigRequest
  ) => Promise<PartnerServiceHearingTestPackageTypeConfig>
  createPartnerEmployeeLicense: (
    request: CreatePartnerEmployeeLicenseRequest
  ) => Promise<PartnerServicePartnerEmployeeLicense>
  deletePartnerEmployeeLicense: (request: DeletePartnerEmployeeLicenseRequest) => Promise<boolean>
  getHearingTestPackageTypeConfig: (query?: undefined) => Promise<PartnerServiceHearingTestPackageTypeConfig>
  getPartnerEmployeeEligibleHearingTestPackageTypes: (
    request: GetPartnerEmployeeEligibleHearingTestPackageTypesRequest
  ) => Promise<GetPartnerEmployeeEligibleHearingTestPackageTypesResponse>
  getPartnerEmployeeLicenses: (query: GetPartnerEmployeeLicensesQuery) => Promise<GetPartnerEmployeeLicensesResponse>
  getPartnerEmployees: (query: GetPartnerEmployeesQuery) => Promise<GetPartnerEmployeesResponse>
  getCountryStates: (query: GetCountryStatesRequest) => Promise<GetCountryStatesResponse>
  updatePartnerEmployeeLicense: (
    request: UpdatePartnerEmployeeLicenseRequest
  ) => Promise<PartnerServicePartnerEmployeeLicense>
  updateHearingTestPackageTypeConfig: (
    request: PartnerServiceUpdateHearingTestPackageTypeConfigRequest
  ) => Promise<PartnerServiceHearingTestPackageTypeConfig>
}

const usePartnerService = (): UseHookContext => {
  const baseUrl = useServiceCountryUrl('partner')

  return {
    ...useHearingTestPackageTypeConfigService(baseUrl),
    ...useOptionsService(baseUrl),
    ...usePartnerEmployeeLicensesService(baseUrl),
    ...usePartnerEmployeesService(baseUrl),
  }
}

export * from './types'
export { usePartnerService }
