import { PathEntityName, RouteParameter } from 'constants/'
import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport, pathnameToRouteParameter } from 'utils'
import { NotFound } from '../NotFound'
import { Provider } from './$partnerEmployeeId'
import { HearingTestPackageTypes } from './HearingTestPackageTypes'
import { ProviderSearch } from './Search'
import { ProviderLicenses } from './Licenses'

const partnerEmployeeIdRouteParameter = pathnameToRouteParameter(RouteParameter.PARTNER_EMPLOYEE_ID)

const Providers = lazy(() =>
  import(
    /* webpackChunkName: "Providers" */
    'components/pages/Providers'
  ).then(namedExportToDefaultExport('Providers'))
)

const Router = () => (
  <Routes>
    <Route element={<Outlet />} path="/">
      <Route element={<Providers />} index />
      <Route element={<ProviderLicenses />} path={`${PathEntityName.LICENSES}/*`} />
      <Route element={<ProviderSearch />} path={`${PathEntityName.SEARCH}/*`} />
      <Route element={<HearingTestPackageTypes />} path={`${PathEntityName.HEARING_TEST_PACKAGE_TYPES}/*`} />
      <Route element={<Provider />} path={`${partnerEmployeeIdRouteParameter}/*`} />
      <Route element={<NotFound />} path="*" />
    </Route>
  </Routes>
)

export { Router as Providers }
