import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      alignItems: 'center',
      color: '#ffffff',
      display: 'flex',
      height: '100%',
      lineHeight: 1,
      margin: 0,
      padding: 0,
      width: 'fit-content',

      '&:focus:focus-visible': {
        outlineColor: theme.palette.primary.light,
        outlineOffset: '-1px',
        outlineStyle: 'auto',
        outlineWidth: '1px',
      },
    },
  })
) as unknown as UseStylesFunction

export { useStyles }
