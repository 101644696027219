import { useAuth0 } from '@auth0/auth0-react'
import { Avatar } from '@material-ui/core'
import { ArrowDropDown, ExitToApp as ExitToAppIcon } from '@material-ui/icons'
import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { Flag } from 'components/shared/Flag'
import { Country } from 'constants/'
import { useMyAccount, useSelectedCountry, useUserSettings } from 'hooks'
import { useStyles } from './index.styles'

const itemStyles = {
  active: {
    background: '#dedede',
    color: '#000000de',
  },
  color: '#000000de',
}

const alphaSort = (a: string, b: string) => {
  if (a > b) return 1
  if (a < b) return -1

  return 0
}

const CountryItem = ({
  classes,
  country,
  name,
}: {
  classes: Record<'item' | 'itemText', string>
  country?: Country
  name?: string
}) => (
  <>
    <Flag country={country} />
    <span className={classes.itemText}>{name}</span>
  </>
)

const Profile = () => {
  const classes = useStyles()
  const { allowedCountries, countryMap, selectedCountry, setSelectedCountry } = useSelectedCountry()
  const { purgeAll: purgeAllUserSettings } = useUserSettings()
  const { logout } = useAuth0()
  const { data: myAccount, isLoading } = useMyAccount()

  const onLogoutClick = () => {
    purgeAllUserSettings()
    logout({ returnTo: window.location.origin })
  }

  const welcomeText = isLoading ? '' : `Hi, ${myAccount?.firstname}`
  const name = `${myAccount?.firstname} ${myAccount?.lastname}`

  if (isLoading) {
    return <div data-spacer />
  }

  const menuButton = (
    <button className={classes.dropdownButton} data-testid="profile-btn">
      <Avatar alt={name} className={classes.avatar} src={myAccount?.photo_url} />
      <span className={classes.dropdownButtonText}>{welcomeText}</span>
      {selectedCountry && allowedCountries.length > 1 && (
        <div className={classes.flag}>
          <Flag country={selectedCountry} />
        </div>
      )}
      <ArrowDropDown />
    </button>
  )

  const onCountrySelected = (country: Country) => setSelectedCountry(country)

  const canChangeSelectedCountry = Boolean(allowedCountries && allowedCountries.length > 1)
  const countryName = countryMap?.[selectedCountry as Country]

  const allowedCountryNames = allowedCountries
    .map((countryCode) => countryMap[countryCode] as string)
    .sort(alphaSort)
    .map((name) => ({
      countryCode: countryMap[name as Country] as Country,
      name,
    }))

  return (
    <>
      <div className={classes.hidden}>
        {/* Preload images */}
        {allowedCountries.map((country) => (
          <Flag country={country} key={country} />
        ))}
      </div>
      <Menu align="end" className={classes.menu} menuButton={menuButton}>
        <>
          {canChangeSelectedCountry ? (
            // Allow the user to change their country
            <SubMenu
              className={classes.subMenu}
              label={
                <div className={classes.item}>
                  <CountryItem
                    classes={classes}
                    country={selectedCountry}
                    name={countryMap[selectedCountry as Country]}
                  />
                </div>
              }
            >
              {allowedCountryNames.map(({ countryCode, name }) => (
                <MenuItem
                  className={classes.item}
                  key={countryCode}
                  onClick={() => onCountrySelected(countryCode)}
                  styles={itemStyles}
                >
                  <CountryItem classes={classes} country={countryCode} name={name} />
                </MenuItem>
              ))}
            </SubMenu>
          ) : (
            // Show the user's read-only country
            <MenuItem className={classes.item} disabled styles={itemStyles}>
              <CountryItem classes={classes} country={selectedCountry} name={countryName} />
            </MenuItem>
          )}
        </>
        <MenuItem className={classes.item} data-testid="logout-btn" onClick={onLogoutClick}>
          <ExitToAppIcon />
          <span className={classes.itemText}>Logout</span>
        </MenuItem>
      </Menu>
    </>
  )
}

export { Profile }
