import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    flag: {
      boxShadow: '0 0 1px 0 #fff',
      display: 'block',
      height: '1rem',
      width: '1.5rem',
    },
  })
) as UseStylesFunction

export { useStyles }
