import { AppointmentType, textConstants } from 'constants/'

const appointmentTypeDisplayText: Record<AppointmentType, string> = {
  [AppointmentType.FOLLOW_UP]: 'Follow-up',
  [AppointmentType.HEARING_AID_EVALUATION]: 'Hearing Aid Evaluation',
  [AppointmentType.AFTERCARE_FOLLOW_UP]: 'Aftercare Follow-up',
  [AppointmentType.VIDEO_CARE_CALL]: 'Video Care Call',
}

const toAppointmentTypeDisplayText = (type: AppointmentType) =>
  appointmentTypeDisplayText[type] ?? textConstants.unknown

export { toAppointmentTypeDisplayText }
