const ApiPathname = {
  VENDOR: '/api/v1/vendors/:id',
  VENDOR_ACTIVATE: '/api/v1/vendors/:id/activate',
  VENDOR_APPROVE: '/api/v1/vendors/:id/approve',
  VENDOR_REJECT: '/api/v1/vendors/:id/reject',
  VENDORS: '/api/v1/vendors',
}

type ApiPathname = (typeof ApiPathname)[keyof typeof ApiPathname]

export { ApiPathname }
