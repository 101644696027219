import { validation } from 'utils'
import { z } from 'zod'
import { schemaUser } from './user'

const schemaMeta = z.object({
  itemsTotal: validation.number,
  limit: validation.number,
  offset: validation.number,
  pagesTotal: validation.number,
})

const schemaGetManyUsersResponse = z.object({
  data: z.array(schemaUser),
  meta: schemaMeta,
})

type GetManyUsersResponse = z.infer<typeof schemaGetManyUsersResponse>

const schemaImpersonation = schemaUser.extend({})

type Impersonation = z.infer<typeof schemaImpersonation>

const schemaGetImpersonationResponse = z.union([schemaImpersonation, z.undefined()])

type GetImpersonationResponse = z.infer<typeof schemaGetImpersonationResponse>

export type { GetImpersonationResponse, GetManyUsersResponse, Impersonation }
export { schemaGetImpersonationResponse, schemaGetManyUsersResponse }
