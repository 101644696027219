import { Suspense } from 'react'
import { Outlet } from 'react-router'
import { ErrorBoundary } from '../../shared/ErrorBoundary'
import { Loader } from '../../shared/Loader'
import { useStyles } from './index.styles'
import { Sidebar } from './Sidebar'

const Main = () => {
  const classes = useStyles()

  return (
    <Suspense fallback={<Loader hasDelay />}>
      <ErrorBoundary>
        <nav className={classes.nav}>
          <Sidebar />
        </nav>
        <main className={classes.main} data-testid="main-container">
          <Outlet />
        </main>
      </ErrorBoundary>
    </Suspense>
  )
}

export { Main }
