import { PathEntityName } from 'constants/'
import { lazy } from 'react'
import { Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../NotFound'
import { Resources } from './Resources'
import { Shops } from './Shops'

const Index = lazy(() =>
  import(
    /* webpackChunkName: "Scheduling" */
    'components/pages/Scheduling'
  ).then(namedExportToDefaultExport('Scheduling'))
)

const Router = () => (
  <Routes>
    <Route element={<Index />} index />
    <Route element={<Resources />} path={`${PathEntityName.RESOURCES}/*`} />
    <Route element={<Shops />} path={`${PathEntityName.SHOPS}/*`} />
    <Route element={<NotFound />} path="*" />
  </Routes>
)

export { Router as Scheduling }
