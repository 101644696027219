import { omit, pick } from 'utils'
import {
  DeletePartnerEmployeeLicenseRequest,
  GetPartnerEmployeeLicensesQuery,
  UpdatePartnerEmployeeLicenseRequest,
} from './types'

const transformLicenseValues = (request: Partial<UpdatePartnerEmployeeLicenseRequest>) => {
  const newRequest = { ...request }
  const { state, validFrom, validTo } = request

  if (validFrom) {
    // Force UTC start of day for the given short date
    newRequest.validFrom = `${validFrom}T00:00:00.000Z`
  }

  if (request?.id && !validTo) {
    // We're updating and didn't pass validTo, we should null it out
    newRequest.validTo = null
  } else if (validTo) {
    // Force UTC end of day for the given short date
    newRequest.validTo = `${validTo}T23:59:59.999Z`
  }

  if (request?.id && !state) {
    // We're updating and we didn't send state, we should null it out
    newRequest.state = null
  }

  return newRequest
}

const createPartnerEmployeeLicensesRequestToBody = transformLicenseValues

const deletePartnerEmployeeLicenseRequestToRouteParams = (request: DeletePartnerEmployeeLicenseRequest) =>
  pick(request, ['id'])

const getPartnerEmployeeLicensesQueryToSearchParams = ({
  ids,
  partnerEmployeeIds,
  ...rest
}: GetPartnerEmployeeLicensesQuery) => ({
  ...rest,
  id: ids,
  partnerEmployeeId: partnerEmployeeIds,
})

const getPartnerEmployeesQueryToSearchParams = ({ ids, ...rest }: GetPartnerEmployeeLicensesQuery) => ({
  ...rest,
  id: ids,
})

const updatePartnerEmployeeLicenseRequestToBody = (request: UpdatePartnerEmployeeLicenseRequest) => {
  const transformed = transformLicenseValues(request)

  return omit(transformed, ['id'])
}

const updatePartnerEmployeeLicenseRequestToRouteParams = (request: UpdatePartnerEmployeeLicenseRequest) =>
  pick(request, ['id'])

export {
  createPartnerEmployeeLicensesRequestToBody,
  deletePartnerEmployeeLicenseRequestToRouteParams,
  getPartnerEmployeeLicensesQueryToSearchParams,
  getPartnerEmployeesQueryToSearchParams,
  updatePartnerEmployeeLicenseRequestToBody,
  updatePartnerEmployeeLicenseRequestToRouteParams,
}
