import { RouteParameter } from 'constants/'
import { UserAllowedCountriesProvider, UserRolesProvider } from 'hooks'
import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport, pathnameToRouteParameter } from 'utils'
import { NotFound } from '../NotFound'
import { Vendor } from './$vendorId'

const vendorIdRouteParameter = pathnameToRouteParameter(RouteParameter.VENDOR_ID)

const Vendors = lazy(() =>
  import(
    /* webpackChunkName: "Vendors" */
    'components/pages/Vendors'
  ).then(namedExportToDefaultExport('Vendors'))
)

const NewVendor = lazy(() =>
  import(
    /* webpackChunkName: "NewVendor" */
    'components/pages/Vendors/NewVendor'
  ).then(namedExportToDefaultExport('NewVendor'))
)

const Router = () => {
  return (
    <UserRolesProvider>
      <UserAllowedCountriesProvider>
        <Routes>
          <Route element={<Outlet />} path="/">
            <Route element={<Vendors />} index />
            <Route element={<NewVendor />} path="new" />
            <Route element={<Vendor />} path={`${vendorIdRouteParameter}/*`} />
            <Route element={<NotFound />} path="*" />
          </Route>
        </Routes>
      </UserAllowedCountriesProvider>
    </UserRolesProvider>
  )
}

export { Router as Vendors }
