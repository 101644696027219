import { Link as MuiLink } from '@material-ui/core'
import cx from 'clsx'
import { forwardRef } from 'react'
import { Link as RrdLink } from 'react-router-dom'
import { VerticalSpacer } from '../VerticalSpacer'
import { useStyles } from './index.styles'

// eslint-disable-next-line @typescript-eslint/no-explicit-any,react/display-name
const StyledLink = forwardRef(({ className, ...props }: any, ref) => {
  const styles = useStyles()

  return <RrdLink {...props} className={cx(styles.container, className)} ref={ref} />
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Link = ({ children, subText, ...props }: any) => {
  const styles = useStyles()

  return (
    <MuiLink component={StyledLink} {...props}>
      {children}
      {subText && (
        <>
          <VerticalSpacer count={0.25} />
          <div className={styles.subText} data-no-underline="true">
            {subText}
          </div>
        </>
      )}
    </MuiLink>
  )
}

export { Link }
