import { FC } from 'react'
import { AccessTokenProvider } from './AccessToken'
import { Auth0Provider } from './Auth0'

const AuthProvider: FC = ({ children }) => (
  <Auth0Provider>
    <AccessTokenProvider>{children}</AccessTokenProvider>
  </Auth0Provider>
)

export { AuthProvider }
