import { useLocale } from 'hooks'
import { toSafeDate } from 'utils'

interface Props {
  date: Date | string
  variant: 'date' | 'full' | 'time'
}

const Date = ({ date: tmpDate, variant = 'date' }: Props) => {
  const locale = useLocale()
  const date = toSafeDate(tmpDate)

  let text = date?.toLocaleString(locale)

  switch (true) {
    case variant === 'date':
      text = date?.toLocaleDateString(locale, { day: '2-digit', month: '2-digit', year: 'numeric' })
      break
    case variant === 'full':
      text = date?.toLocaleString(locale, {
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      break
    case variant === 'time':
      text = date?.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })
      break
    default:
      break
  }

  return <span data-date={date?.toISOString()}>{text ?? ''}</span>
}

export { Date }
