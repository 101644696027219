import { PathEntityName, RouteParameter } from 'constants/'
import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport, pathnameToRouteParameter } from 'utils'
import { NotFound } from '../../NotFound'
import { Resource as Entity } from './$resourceId'

const entityIdRouteParameter = pathnameToRouteParameter(RouteParameter.RESOURCE_ID)

const EntityList = lazy(() =>
  import(
    /* webpackChunkName: "Resources" */
    'components/pages/Scheduling/Resources'
  ).then(namedExportToDefaultExport('Resources'))
)

const ScheduleWithDropdown = lazy(() =>
  import(
    /* webpackChunkName: "ResourceSchedules" */
    'components/pages/Scheduling/Resources/Schedules'
  ).then(namedExportToDefaultExport('ScheduleWithDropdown'))
)

const Router = () => (
  <Routes>
    <Route element={<Outlet />} path="/">
      <Route element={<EntityList />} index />
      <Route element={<ScheduleWithDropdown />} path={PathEntityName.SCHEDULES} />
      <Route element={<Entity />} path={`${entityIdRouteParameter}/*`} />
      <Route element={<NotFound />} path="*" />
    </Route>
  </Routes>
)

export { Router as Resources }
