import { AppointmentStatus, textConstants } from 'constants/'

const appointmentStatusDisplayText: Record<AppointmentStatus, string> = {
  [AppointmentStatus.CANCELED]: 'Cancelled',
  [AppointmentStatus.COMPLETED]: 'Completed',
  [AppointmentStatus.CONFIRMED]: 'Confirmed',
  [AppointmentStatus.DENIED]: 'Denied',
  [AppointmentStatus.PENDING]: 'Pending',
  [AppointmentStatus.RESCHEDULED]: 'Rescheduled',
}

const toAppointmentStatusDisplayText = (type: AppointmentStatus) =>
  appointmentStatusDisplayText[type] ?? textConstants.unknown

export { toAppointmentStatusDisplayText }
