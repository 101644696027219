import { z } from 'zod'

const roles = {
  ADMIN: 'ADMIN', // Full CRUD rights on all services
  ADMIN_LIGHT: 'ADMIN_LIGHT', // Full CRUD rights on all services, but no access to sensitive data
  EXTERNAL_TA_FITTER: 'EXTERNAL_TA_FITTER', // External Teleaudiology fitter

  /**
   * @deprecated Use `EXTERNAL_TA_FITTER` instead
   */
  FITTER_EXTERNAL: 'FITTER_EXTERNAL', // Hear.com partner fitter

  /**
   * @deprecated Use `INTERNAL_TA_FITTER` instead
   */
  FITTER_INTERNAL: 'FITTER_INTERNAL', // Hear.com employee fitter
  INTERNAL_TA_FITTER: 'INTERNAL_TA_FITTER', // Internal Teleaudiology fitter
  OPERATIONS: 'OPERATIONS', // Hear.com operations employee

  /**
   * @deprecated Use `OPERATIONS` instead
   */
  OPS: 'OPS', // LogPit Operations employee
  PARTNER_MANAGEMENT: 'PARTNER_MANAGEMENT', // Hear.com partner management employee
  READ_ONLY: 'READ_ONLY', // Read only access to all things
  SALES: 'SALES', // Hear.com sales employee
  SUPPORT: 'SUPPORT', // Hear.com support employee
  VENDOR_MARKETING_APPROVER: 'VENDOR_MARKETING_APPROVER', // Hear.com vendor onboarding approver employee
  VENDOR_MARKETING_MANAGER: 'VENDOR_MARKETING_MANAGER', // Hear.com vendor onboarding manager employee
} as const

const schemaRole = z.preprocess((value: unknown) => {
  if (typeof value === 'string') {
    return value.toUpperCase()
  }

  return value
}, z.nativeEnum(roles))

type Role = (typeof roles)[keyof typeof roles]

export type { Role }
export { roles, schemaRole }
