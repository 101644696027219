import styled from '@emotion/styled'
import { Link as MuiLink, Typography } from '@material-ui/core'
import { theme } from 'app/GlobalStyles'
import { Link, VerticalSpacer } from 'components/shared'
import { ComponentProps } from 'react'
import { Section } from '../Section'
import { useStyles } from './index.styles'

type Props = Omit<ComponentProps<typeof Section>, 'children'> & {
  emptyLinksText?: string
  links: {
    name: string
    subText?: string
    to: string
  }[]
}

const List = styled('ul')`
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: row;
  list-style: none;
  margin: 0;
  padding: 0;
`

const BlockLink = styled(Link)`
  display: block;
`

const BlockAnchor = styled(MuiLink)`
  display: block;
`

const hrefToTarget = (href: string) => {
  if (href.startsWith('http')) {
    const url = new URL(href)

    return url.origin
  }

  return undefined
}

const LinksSection = ({ emptyLinksText = '--', links, title }: Props) => {
  const styles = useStyles()

  return (
    <Section title={title}>
      <List>
        {links?.length ? (
          links.map(({ name, subText, to, ...rest }) => (
            <li key={name}>
              {to.startsWith('http') ? (
                <BlockAnchor href={to} {...rest} referrerPolicy="no-referrer" target={hrefToTarget(to)}>
                  {name}
                  {subText && (
                    <>
                      <VerticalSpacer count={0.25} />
                      <div className={styles.subText} data-no-underline="true">
                        {subText}
                      </div>
                    </>
                  )}
                </BlockAnchor>
              ) : (
                <BlockLink subText={subText} to={to} underline="hover" {...rest}>
                  {name}
                </BlockLink>
              )}
            </li>
          ))
        ) : (
          <Typography style={{ color: theme.palette.grey[400] }}>{emptyLinksText}</Typography>
        )}
      </List>
    </Section>
  )
}

export { LinksSection }
