import { NewrelicTracker } from '@audibene/ta-browser-tracker'
import { logRocket } from 'startup'
import { ENVIRONMENT, isLiveEnvironment } from './processEnv'

let newRelicTracker: NewrelicTracker

const newRelicConfig = {
  development: {
    loaderConfig: {
      accountID: '709901',
      trustKey: '709901',
      agentID: '647467670',
      licenseKey: 'a92c973af2',
      applicationID: '647467670',
    },
    info: {
      beacon: 'bam-cell.nr-data.net',
      errorBeacon: 'bam-cell.nr-data.net',
      licenseKey: 'a92c973af2',
      applicationID: '647467670',
      sa: 1,
    },
  },
  testing: {
    loaderConfig: {
      accountID: '3071889',
      trustKey: '709901',
      agentID: '647473752',
      licenseKey: 'NRJS-b55833d9cd2a5947a83',
      applicationID: '647473752',
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-b55833d9cd2a5947a83',
      applicationID: '647473752',
      sa: 1,
    },
  },
  staging: {
    loaderConfig: {
      accountID: '3071890',
      trustKey: '709901',
      agentID: '647474447',
      licenseKey: 'NRJS-a2373751c49a273e7f2',
      applicationID: '647474447',
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRJS-a2373751c49a273e7f2',
      applicationID: '647474447',
      sa: 1,
    },
  },
  production: {
    loaderConfig: {
      accountID: '709901',
      trustKey: '709901',
      agentID: '647471048',
      licenseKey: 'a92c973af2',
      applicationID: '647471048',
    },
    info: {
      beacon: 'bam-cell.nr-data.net',
      errorBeacon: 'bam-cell.nr-data.net',
      licenseKey: 'a92c973af2',
      applicationID: '647471048',
      sa: 1,
    },
  },
}

function initNewrelic(): void {
  if (!isLiveEnvironment) return

  const config = newRelicConfig[ENVIRONMENT]

  if (!config || !window.NREUM) {
    console.error('Newrelic initialization failed')
    return
  }

  newRelicTracker = new NewrelicTracker(config)

  if (logRocket.isInitialized()) {
    logRocket
      .getSessionURL()
      .then((logrocketSessionId: string) => newRelicTracker.setSessionId({ id: logrocketSessionId }))
      .catch(() => console.warn(`failed to set logrocket's session id to newrelic`))
  }
}

export { initNewrelic }
