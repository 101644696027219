abstract class CustomError extends Error {
  readonly code: string

  constructor(message: string, code: string) {
    super(message)

    this.code = code
    this.name = this.constructor.name

    // Capturing stack trace, excluding constructor call from it
    Error.captureStackTrace(this, this.constructor)
  }
}

const ErrorCode = {
  MINIMUM_HEARING_TEST_PACKAGE_TYPE_FOR_COUNTRY_STATE_NOT_SATISFIED:
    'MINIMUM_HEARING_TEST_PACKAGE_TYPE_FOR_COUNTRY_STATE_NOT_SATISFIED',
} as const

type ErrorCode = (typeof ErrorCode)[keyof typeof ErrorCode]

class ResponseError extends CustomError {}

export { CustomError, ErrorCode, ResponseError }
