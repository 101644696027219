enum EventType {
  EXTERNAL_REDIRECT = 'External Redirect',
  FORM_ABANDONED = 'Form Abandoned',
  FORM_COMPLETED = 'Form Completed',
  FORM_SUBMISSION_FAILED = 'Form Submission Failed',
  LICENSES_FILTERED = 'Licenses Filtered',
  PAGE_VIEW = 'Page View',
  PARTNER_LICENSE_HEARING_TEST_PACKAGE_TYPE_UPDATED = 'Partner License Hearing Test Package Type Updated',
  NOTE_ADDED = 'Note Added',
  PARTNER_EXCLUSION_ADDED = 'Partner Exclusion Added',
  PARTNER_EXCLUSION_REMOVED = 'Partner Exclusion Removed',
}

export { EventType }
