import { DeliveryModel } from './deliveryModel'

enum Solution {
  /**
   * @deprecated Use MEDICARE_ADVANTAGE instead
   */
  ADVANTAGE = 'ADVANTAGE',
  BEST_CHOICE = 'BEST_CHOICE',
  /**
   * @deprecated This solution is no longer used
   */
  DIRECT_SALE = 'DIRECT_SALE',
  ECONOMY = 'ECONOMY',
  HEAR_TODAY = 'HEAR_TODAY',
  /**
   * @deprecated Use MEDICARE_ADVANTAGE instead
   */
  MEDICAL_ADVANTAGE = 'MEDICAL_ADVANTAGE',
  MEDICARE_ADVANTAGE = 'MEDICARE_ADVANTAGE',
  OVER_THE_COUNTER = 'OVER_THE_COUNTER',
  STANDARD = 'STANDARD',
  TRUHEARING = 'TRUHEARING',
  YOUNG_HEARING_AID = 'YOUNG_HEARING_AID',
}

enum SolutionToDisplayText {
  ADVANTAGE = 'Advantage',
  BEST_CHOICE = 'Best Choice',
  DIRECT_SALE = 'Direct Sale',
  ECONOMY = 'Economy',
  HEAR_TODAY = 'Hear Today',
  MEDICAL_ADVANTAGE = 'Medical Advantage',
  MEDICARE_ADVANTAGE = 'Medicare Advantage',
  OVER_THE_COUNTER = 'Over the Counter',
  STANDARD = 'Standard',
  TRUHEARING = 'Truhearing',
  YOUNG_HEARING_AID = 'Young Hearing Aid',
}

const getSolutionsOfDeliveryModel = (deliveryModel: DeliveryModel): Solution[] => {
  switch (deliveryModel) {
    case DeliveryModel.DIRECT_TO_CONSUMER:
      return [Solution.STANDARD]
    case DeliveryModel.NETWORK:
      return [
        Solution.BEST_CHOICE,
        Solution.HEAR_TODAY,
        Solution.MEDICARE_ADVANTAGE,
        Solution.STANDARD,
        Solution.TRUHEARING,
        Solution.YOUNG_HEARING_AID,
      ]
    case DeliveryModel.NETWORK_HOME_DELIVERY:
      return [
        Solution.BEST_CHOICE,
        Solution.HEAR_TODAY,
        Solution.MEDICARE_ADVANTAGE,
        Solution.STANDARD,
        Solution.TRUHEARING,
        Solution.YOUNG_HEARING_AID,
      ]
    case DeliveryModel.TELEAUDIOLOGY:
      return [
        Solution.BEST_CHOICE,
        Solution.ECONOMY,
        Solution.HEAR_TODAY,
        Solution.MEDICARE_ADVANTAGE,
        Solution.STANDARD,
        Solution.TRUHEARING,
      ]
    default:
      return []
  }
}

export { getSolutionsOfDeliveryModel, Solution, SolutionToDisplayText }
