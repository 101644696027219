import { defaultQueryOptions } from 'constants/'
import { useVendorService } from 'hooks/services/useVendorService'
import { GetManyVendorsRequest } from 'hooks/services/useVendorService/types/requests'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'

const hookName = 'useVendors'
const log = logFactory(hookName)
const defaultOptions: QueryOptions = { enabled: true }

const useVendors = (request?: GetManyVendorsRequest, options: QueryOptions = defaultOptions) => {
  const { getVendors } = useVendorService()

  const params: GetManyVendorsRequest = {
    ...request,
  }

  const doQuery = async () => {
    log(`👥 Fetching vendors...`, params)

    const results = await getVendors(params)

    log(`👥 Returned vendors...`, results.data)

    return results
  }

  return useQuery([hookName, params.id, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useVendors, hookName as useVendorsHookName }
