import debug, { Debugger } from 'debug'

const loggers: Record<string, Debugger> = {}
const appPrefix = 'partner-portal'

/**
 *
 * @param module The module namespace of the generated logger instance
 * @param method Optional method suffix for the generated logger instance
 * @returns Either a fancy console log function or a no-op depending on the environment's
 * `localStorage.debug` value. See the [debug](https://www.npmjs.com/package/debug) documentation
 * for more details.
 */
const logFactory = (module: string, method?: string): Debugger => {
  const key = method ? `${appPrefix}:${module}:${method}` : `${appPrefix}:${module}`

  if (loggers[key]) {
    return loggers[key]
  }

  const logger = debug(key)

  loggers[key] = logger

  return logger
}

export { logFactory }
