import { SnackbarProvider } from 'notistack'
import { ReactNode } from 'react'
import { useStyles } from './index.styles'

interface Props {
  children: ReactNode
}

const Provider = ({ children }: Props) => {
  const classes = useStyles()

  return (
    <SnackbarProvider
      classes={{
        variantError: classes.error,
        variantInfo: classes.info,
        variantSuccess: classes.success,
        variantWarning: classes.warning,
      }}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  )
}

export { Provider }
