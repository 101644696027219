import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useTrackPageView } from 'hooks'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { Routes as App } from 'routes'
import { useAccessToken } from '../Providers'

const RouteContainer = () => {
  const { isLoading } = useAccessToken()
  const client = useLDClient()
  const [areFeatureFlagsLoaded, beFeatureFlagsLoaded] = useState(false)
  const isAppReady = Boolean(areFeatureFlagsLoaded && !isLoading)

  useEffect(() => {
    if (areFeatureFlagsLoaded || !client) {
      return
    }

    const async = async () => {
      await client.waitForInitialization()

      // HACK: There is lag time between the LD client saying it's ready and the flag value being accurate
      const timeout = setTimeout(() => beFeatureFlagsLoaded(true), 100)

      return () => clearTimeout(timeout)
    }

    async()
  }, [client])

  useTrackPageView()

  if (!isAppReady) {
    // Block the auth routes from rendering until our dependencies are loaded
    return null
  }

  return <App />
}

const AuthenticatedRoutes = withAuthenticationRequired(RouteContainer)

export { AuthenticatedRoutes }
