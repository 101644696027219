import { makeStyles } from '@material-ui/core/styles'

interface Props {
  width: string
}

interface OpenProps {
  isOpen: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    height: 'calc(100vh - 6.25rem)',
    position: 'sticky',
    top: '5.75rem',
  },
  paper: {
    border: '1px solid rgb(0 0 0 / 10%)',
    borderRadius: '0.375rem',
    position: 'sticky',
    transition: 'width 0.25s ease',
    width: (props: Props) => props.width,
  },
}))

const useListStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
  },
}))

const useListIconStyles = makeStyles(() => ({
  root: {
    minWidth: '2.5rem',
  },
}))

const useListItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    overflow: 'hidden',

    '&[aria-selected="true"]': {
      background: `${theme.palette.primary.light}1a`,
      textDecoration: 'none',
    },
    '&[aria-selected="true"] > *': {
      color: theme.palette.primary.light,
    },
    '&:hover': {
      background: `${theme.palette.primary.light}33`,
      textDecoration: 'none',
    },
    '&:focus': {
      background: `${theme.palette.primary.light}33`,
      textDecoration: 'none',
    },
  },
}))

const useListItemTextStyles = makeStyles(() => ({
  root: {
    opacity: (props: OpenProps) => (props.isOpen ? 1 : 0),
    transition: 'opacity 0.4s ease',
  },
}))

export { useListStyles, useListIconStyles, useListItemStyles, useListItemTextStyles, useStyles }
