import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../../../NotFound'

const Licenses = lazy(() =>
  import(
    /* webpackChunkName: "ProviderLicenses" */
    'components/pages/Providers/Provider/Licenses'
  ).then(namedExportToDefaultExport('Licenses'))
)

const Router = () => (
  <Routes>
    <Route element={<Outlet />} path="/">
      <Route element={<Licenses />} index />
      <Route element={<NotFound />} path="*" />
    </Route>
  </Routes>
)

export { Router as Licenses }
