import { EventType } from 'constants/'
import { useEventTracking } from 'hooks'
import { useEffect } from 'react'
import { PageViewData } from 'types'
import { useLocation } from 'react-router-dom'

const rootPathNames = ['', 'customers']
const idRegEx = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/gi

const normalizeRoute = (path: string) => path.replace(idRegEx, ':id').replace(/^\//, '').replace(/\/$/, '')

const readPageViewData = (): PageViewData => {
  const { href, pathname } = window.location

  let route = normalizeRoute(pathname).toLowerCase()

  if (rootPathNames.includes(route)) {
    route = ''
  }

  return {
    href,
    pathname,
    route: `/${route}`,
  }
}

const useTrackPageView = () => {
  const location = useLocation()
  const { track } = useEventTracking()

  useEffect(() => {
    const data = readPageViewData()

    track(EventType.PAGE_VIEW, { data })
  }, [location, track])
}

export { useTrackPageView }
