import { ReactComponent as HearIcon } from 'assets/hear-logo-header.svg'
import { Route } from 'constants/'
import { Link } from '../../../shared/Link'
import { useStyles } from './index.styles'

const Home = () => {
  const classes = useStyles()

  return (
    <Link aria-label="Navigate to home" className={classes.link} to={Route.HOME}>
      <HearIcon />
    </Link>
  )
}

export { Home }
