import { styled } from '@material-ui/core'
import { Divider as MuiDivider } from '@mui/material'

const Divider = styled(MuiDivider)(() => ({
  fontSize: '0.75rem',
  textTransform: 'uppercase',
}))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Container = (props: any) => (
  <div>
    <Divider {...props} />
  </div>
)

export { Container as HorizontalDivider }
