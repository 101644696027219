import { useServiceCountryUrl } from '../useServiceCountryUrl'
import { optionMethods, userMethods } from './methods'
import {
  CreateOneImpersonationRequest,
  CreateUserRequest,
  DeleteOneImpersonationRequest,
  GetImpersonationRequest,
  GetImpersonationResponse,
  GetManyUsersRequest,
  GetManyUsersResponse,
  Impersonation,
  UpdateOneUserRequest,
  User,
  UserCountry,
  UserRole,
} from './types'

interface UseHookContext {
  createImpersonation: (request: CreateOneImpersonationRequest) => Promise<Impersonation>
  createUser: (request: CreateUserRequest) => Promise<User>
  deleteImpersonation: (request: DeleteOneImpersonationRequest) => Promise<void>
  deleteUser: (id: string) => Promise<void>
  getAllowedCountries: (request: undefined) => Promise<UserCountry[]>
  getImpersonation: (request: GetImpersonationRequest) => Promise<GetImpersonationResponse>
  getRoles: (request: undefined) => Promise<UserRole[]>
  getRolePermissions: (request: undefined) => Promise<Record<UserRole, Record<string, string>>>
  getUsers: (request: GetManyUsersRequest) => Promise<GetManyUsersResponse>
  updateUser: (request: UpdateOneUserRequest) => Promise<User>
}

const useUserService = (): UseHookContext => {
  const baseUrl = useServiceCountryUrl('user')
  const { getAllowedCountries, getRolePermissions, getRoles } = optionMethods(baseUrl)

  const { createImpersonation, createUser, deleteImpersonation, deleteUser, getImpersonation, getUsers, updateUser } =
    userMethods(baseUrl)

  return {
    createImpersonation,
    createUser,
    deleteImpersonation,
    deleteUser,
    getAllowedCountries,
    getImpersonation,
    getRolePermissions,
    getRoles,
    getUsers,
    updateUser,
  }
}

export * from './types'
export { useUserService }
