import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { FC } from 'react'
import { theme } from '../../../GlobalStyles'

const ThemeProvider: FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <StylesProvider injectFirst>{children}</StylesProvider>
  </MuiThemeProvider>
)

export { ThemeProvider }
