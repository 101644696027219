import { Environment } from 'constants/'
import { hydrateRoute } from 'utils'

const PATHNAME = '/lightning/r/Contact/:salesforceGid/view' as const

const baseUrls = new Map<Environment, string>([
  [Environment.TESTING, 'https://betterhearing--qanew.sandbox.lightning.force.com'],
  [Environment.STAGING, 'https://test.salesforce.com'],
  [Environment.PRODUCTION, 'https://betterhearing.lightning.force.com'],
])

const origin = window.location.origin.toLowerCase()

const environment = origin.includes('shared-testing')
  ? Environment.TESTING
  : origin.includes('shared-staging')
  ? Environment.STAGING
  : Environment.PRODUCTION

const makeSalesforceProviderLink = (salesforceGid: string) => {
  if (!salesforceGid) {
    return '#'
  }

  const url = new URL(
    hydrateRoute(PATHNAME, {
      salesforceGid,
    }),
    baseUrls.get(environment)
  )

  return url.href
}

export { makeSalesforceProviderLink }
