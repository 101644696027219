import { lazy } from 'react'
import { namedExportToDefaultExport } from 'utils'

const componentName = 'NotFound'

const NotFound = lazy(() =>
  import(
    /* webpackChunkName: "NotFound" */
    'components/pages/NotFound'
  ).then(namedExportToDefaultExport(componentName))
)

export { NotFound }
