import { defaultPagination, HttpMethod } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { PartnerServicePartnerEmployee } from 'types'
import { omit, pick } from 'utils'
import { ApiPathname } from '../../constants'
import { getPartnerEmployeesQueryToSearchParams } from '../../filters'
import { GetPartnerEmployeesQuery, GetPartnerEmployeesResponse, UpdatePartnerEmployeeRequest } from '../../types'

const service = (baseUrl: URL) => {
  const getPartnerEmployeesRequest = useRequest<GetPartnerEmployeesQuery, GetPartnerEmployeesResponse>({
    baseUrl,
    filterQueryToSearchParams: getPartnerEmployeesQueryToSearchParams,
    pathname: ApiPathname.PARTNER_EMPLOYEES,
  })

  const getPartnerEmployees = useCallback(
    (query: GetPartnerEmployeesQuery) =>
      getPartnerEmployeesRequest({
        ...defaultPagination,
        ...query,
      }),
    [getPartnerEmployeesRequest]
  )

  const updatePartnerEmployeeRequest = useRequest<UpdatePartnerEmployeeRequest, PartnerServicePartnerEmployee>({
    baseUrl,
    filterQueryToRequestBody: omit(['id']),
    filterQueryToRouteParams: pick(['id']),
    method: HttpMethod.PATCH,
    pathname: ApiPathname.PARTNER_EMPLOYEE,
  })

  const updatePartnerEmployee = useCallback(
    (request: UpdatePartnerEmployeeRequest) => updatePartnerEmployeeRequest(request),
    [updatePartnerEmployeeRequest]
  )

  return { getPartnerEmployees, updatePartnerEmployee }
}

export { service as usePartnerEmployeesService }
