import { useCountryStates as useSharedCountryStates } from 'hooks'
import { createContext, useContext, useMemo } from 'react'

type State = {
  code: string
  name: string
}

type Context = Record<State['code'], State['name']>

const context = createContext<Context>({})
const { Provider } = context

const CountryStatesProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: states = [] } = useSharedCountryStates()

  const value = useMemo(
    () =>
      states.reduce((acc, state) => {
        acc[state.code] = state.name
        return acc
      }, {} as Context),
    [states]
  )

  return <Provider value={value}>{children}</Provider>
}

const useCountryStates = () => useContext(context)

export { CountryStatesProvider, useCountryStates }
