import { z } from 'zod'

const countries = {
  CA: 'CA',
  CH: 'CH',
  DE: 'DE',
  US: 'US',
} as const

type Country = (typeof countries)[keyof typeof countries]

const schemaCountry = z.preprocess((value: unknown) => {
  if (typeof value === 'string') {
    return value.toUpperCase()
  }

  return value
}, z.nativeEnum(countries))

export type { Country }
export { countries, schemaCountry }
