import { validation } from 'utils'
import { z } from 'zod'
import { schemaCountry } from './country'

const schemaGlobalSettings = z.object({
  locale: validation.locale.optional().nullable(),
  selectedCountry: schemaCountry.optional().nullable(),
  timeZone: validation.timeZone.optional().nullable(),
})

const schemaPrimitive = z.union([validation.string, validation.number, validation.boolean, z.null()])

type JsonPrimitive = z.infer<typeof schemaPrimitive>
type Json = Json[] | JsonPrimitive | { [key: string]: Json }

// @ts-expect-error ???
const schemaJson: z.ZodType<Json> = z.lazy(() =>
  z.union([schemaPrimitive, z.record(validation.string, schemaJson), z.array(schemaJson)])
)

const schemaClientSetting = schemaJson

const schemaGlobalSetting = z.object({
  global: schemaGlobalSettings.strict().default({}).optional(),
})

const schemaGenericSettings = z.record(schemaClientSetting)
const schemaUserSettings = schemaGlobalSetting.catchall(schemaGenericSettings)

type UserClientSettings = z.infer<typeof schemaClientSetting>
type UserGlobalSettings = z.infer<typeof schemaGlobalSetting>
type UserSettings = z.infer<typeof schemaUserSettings>
type UserSettingsKey = string | 'global'
type UserSettingsValue = UserSettings[UserSettingsKey]

export type { UserClientSettings, UserGlobalSettings, UserSettings, UserSettingsKey, UserSettingsValue }
export { schemaClientSetting, schemaGenericSettings, schemaUserSettings }
