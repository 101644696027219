import type { QueryOptions } from 'types'

const defaultQueryOptions: QueryOptions = {
  enabled: true,
  instanceKey: 'default',
  refetchOnWindowFocus: false,
  retry: false,
}

export { defaultQueryOptions }
