import { CalendarIntegrationType } from './calendarIntegrationType'

const calendarIntegrationsToDisplayText: Record<
  CalendarIntegrationType,
  {
    name: string
    referralLabels?: {
      external: string
      internal: string
    }
    resourceLabels: {
      external: string
      internal: string
    }
    shopLabels: {
      external: string
      internal: string
    }
  }
> = Object.freeze({
  [CalendarIntegrationType.BLUEPRINT]: {
    name: 'Blueprint',
    resourceLabels: {
      external: 'Provider ID',
      internal: 'Name',
    },
    shopLabels: {
      external: 'Clinic name or ID',
      internal: 'Name',
    },
  },
  [CalendarIntegrationType.OUTLOOK_INTERNAL]: {
    name: 'Outlook Internal',
    resourceLabels: {
      external: 'Email',
      internal: 'Name',
    },
    shopLabels: {
      external: '',
      internal: '',
    },
  },
  [CalendarIntegrationType.SYCLE]: {
    name: 'Sycle Private',
    resourceLabels: {
      external: 'Sycle Private Staff ID',
      internal: 'Name',
    },
    shopLabels: {
      external: 'Sycle Private Clinic ID',
      internal: 'Name',
    },
  },
  [CalendarIntegrationType.SYCLE_PRO]: {
    name: 'Sycle Pro',
    referralLabels: {
      external: 'Sycle Pro Referral Source ID',
      internal: 'Name',
    },
    resourceLabels: {
      external: 'Sycle Pro Staff ID',
      internal: 'Name',
    },
    shopLabels: {
      external: 'Sycle Pro Clinic ID',
      internal: 'Name',
    },
  },
  [CalendarIntegrationType.TCM]: {
    name: 'TCM',
    resourceLabels: {
      external: 'TCM Provider ID',
      internal: 'Name',
    },
    shopLabels: {
      external: 'TCM Clinic ID',
      internal: 'Name',
    },
  },
})

export { calendarIntegrationsToDisplayText }
