import { isDate, isValid, parseISO } from 'date-fns'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toSafeDate = (value: any): Date | undefined => {
  if (isDate(value)) {
    return value as Date
  }

  const date = parseISO(value)

  if (isValid(date)) {
    return date
  }

  return undefined
}

export { toSafeDate }
