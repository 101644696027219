import { RouteParameter } from 'constants/'
import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport, pathnameToRouteParameter } from 'utils'
import { NotFound } from '../../../../NotFound'

const Integration = lazy(() =>
  import(
    /* webpackChunkName: "ResourceIntegration" */
    'components/pages/Scheduling/Resources/Resource/Integrations/Integration'
  ).then(namedExportToDefaultExport('Integration'))
)

const IntegrationsList = lazy(() =>
  import(
    /* webpackChunkName: "ResourceIntegrations" */
    'components/pages/Scheduling/Resources/Resource/Integrations'
  ).then(namedExportToDefaultExport('Integrations'))
)

const resourceIntegrationRouteParameter = pathnameToRouteParameter(RouteParameter.INTEGRATION_TYPE)

const Integrations = () => (
  <Routes>
    <Route element={<Outlet />} path="/">
      <Route element={<IntegrationsList />} index />
      <Route element={<Integration />} path={`/${resourceIntegrationRouteParameter}`} />
      <Route element={<NotFound />} path="*" />
    </Route>
  </Routes>
)

export { Integrations }
