import { textConstants } from 'constants/'
import { Vendor } from 'hooks'
import { User } from 'hooks/services/useUserService'
import { AppointmentServiceResource, AppointmentServiceShop, PartnerServicePartnerEmployee } from 'types'

interface Params {
  fallbackText?: string
  isLoading?: boolean
  loadingText?: string
  partnerEmployee?: Pick<PartnerServicePartnerEmployee, 'affiliation' | 'familyName' | 'givenName' | 'isActive'>
  resource?: Pick<AppointmentServiceResource, 'name'>
  shop?: Pick<AppointmentServiceShop, 'country' | 'name'>
  user?: Pick<User, 'familyName' | 'givenName'>
  vendor?: Pick<Vendor, 'name'>
}

/**
 *
 * @param Params
 * @returns the formatted name for either a resource or shop
 */
const toDisplayName = ({
  fallbackText = textConstants.nameUnknown,
  isLoading,
  loadingText = 'Loading...',
  partnerEmployee,
  resource,
  shop,
  user,
  vendor,
}: Params): string => {
  switch (true) {
    case !!partnerEmployee && !isLoading:
      return `${partnerEmployee?.givenName} ${partnerEmployee?.familyName}`
    case !!resource && !isLoading:
      return resource?.name ?? ''
    case !!user && !isLoading:
      return `${user?.givenName} ${user?.familyName}`
    case shop && !isLoading:
      return `${shop?.name} (${shop?.country})`
    case vendor && !isLoading:
      return vendor?.name ?? ''
    default:
      return (typeof isLoading !== 'undefined' ? loadingText : '') || fallbackText
  }
}

export { toDisplayName }
