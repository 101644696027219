import { createStyles, makeStyles } from '@material-ui/core'

const reset = {
  display: 'grid',
  minHeight: '100vh',
  minWidth: '100%',
  margin: 0,
  padding: 0,
}

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      html: reset,
      '*, *::before, *::after': {
        boxSizing: 'border-box',
        fontFamily: 'Roboto, Sans Serif',
      },

      body: {
        ...reset,
        fontSize: '16px',
        fontWeight: 100,
      },

      '#root': {
        ...reset,
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: "'header header' 'nav main'",
      },
    },
  })
)

const GlobalStyles = () => {
  useStyles()

  return null
}

export { GlobalStyles }
