import { legacyDefaultPagination, HttpMethod } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { identity } from 'remeda'
import { ApiPathname } from '../../constants'
import { resourcesQueryToSearchParams, transformUpdateIsActiveResourceRequestToRequestBody } from '../../filters'
import {
  GetResourcesQuery,
  GetResourcesResponse,
  UpdateResourceIsActiveRequest,
  UpdateResourceResponse,
} from '../../types'

const resourceMethods = (baseUrl: URL) => {
  const getResourcesRequest = useRequest<GetResourcesQuery, GetResourcesResponse>({
    baseUrl,
    filterQueryToSearchParams: resourcesQueryToSearchParams,
    pathname: ApiPathname.RESOURCES,
  })

  const updateResourceIsActiveRequest = useRequest<UpdateResourceIsActiveRequest, UpdateResourceResponse>({
    baseUrl,
    filterQueryToRequestBody: transformUpdateIsActiveResourceRequestToRequestBody,
    filterQueryToRouteParams: identity,
    method: HttpMethod.PATCH,
    pathname: ApiPathname.RESOURCE,
  })

  const getResources = useCallback(
    (query: GetResourcesQuery) =>
      getResourcesRequest({
        ...legacyDefaultPagination,
        ...query,
      }),
    [getResourcesRequest]
  )

  const updateResourceIsActive = useCallback(
    (payload: UpdateResourceIsActiveRequest) => updateResourceIsActiveRequest({ ...payload }),
    [updateResourceIsActiveRequest]
  )

  return { getResources, updateResourceIsActive }
}

export { resourceMethods }
