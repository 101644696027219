import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      color: theme.palette.secondary.contrastText,
    },
  })
)

export { useStyles }
