import styled from '@emotion/styled'
import { Paper } from '@material-ui/core'
import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
  elevation?: number
}

type Variant = 'elevation' | 'outlined'

const Container = styled(Paper)(() => ({
  overflow: 'visible',
  padding: '1.25rem',
  position: 'relative',
  zIndex: 10,
}))

const ContentContainer = ({ children, elevation = 1 }: Props) => {
  let variant: Variant = 'outlined'

  if (elevation === 0) {
    variant = 'elevation'
  }

  return (
    <Container elevation={elevation} variant={variant}>
      {children}
    </Container>
  )
}

export { ContentContainer }
