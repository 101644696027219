import { defaultQueryOptions } from 'constants/'
import { GetManyUsersRequest, useUserService } from 'hooks/services/useUserService'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'

const hookName = 'useUsers'
const log = logFactory(hookName)
const defaultOptions: QueryOptions = { enabled: true }

const useUsers = (request?: GetManyUsersRequest, options: QueryOptions = defaultOptions) => {
  const { getUsers } = useUserService()

  const params: GetManyUsersRequest = {
    ...request,
  }

  const doQuery = async () => {
    log(`👥 Fetching users...`, params)

    const results = await getUsers(params)

    log(`👥 Returned users...`, results.data)

    return results
  }

  return useQuery([hookName, params.id, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useUsers, hookName as useUsersHookName }
