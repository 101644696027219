enum Permission {
  PARTNER_EMPLOYEES_READ = 'partner_employees:read',
  PARTNER_EMPLOYEES_WRITE = 'partner_employees:write',
  PARTNER_EMPLOYEE_LICENSES_DELETE = 'partner_employee_licenses:delete',
  PARTNER_EMPLOYEE_LICENSES_WRITE = 'partner_employee_licenses:write',
  USERS_DELETE = 'users:delete',
  USERS_WRITE = 'users:write',
  VENDORS_MARKETING_APPROVE = 'vendors-marketing:approve',
  VENDORS_MARKETING_CREATE = 'vendors-marketing:create',
  VENDORS_MARKETING_DELETE = 'vendors-marketing:delete',
  VENDORS_MARKETING_READ = 'vendors-marketing:read',
  VENDORS_MARKETING_REVIEW = 'vendors-marketing:review',
  VENDORS_MARKETING_UPDATE = 'vendors-marketing:update',
}

export { Permission }
