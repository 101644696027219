import { isEmpty } from '../fp'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const applySearchParametersToUrl = (url: URL, searchParameters: Record<string, any> = {}) => {
  const urlTemp = new URL(url)

  Object.keys(searchParameters).forEach((key) => {
    const value = searchParameters[key]

    if (isEmpty(value)) {
      return
    }

    urlTemp.searchParams.set(key, value)
  })

  return urlTemp
}

export { applySearchParametersToUrl }
