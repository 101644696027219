import { MenuItem } from '@material-ui/core'
import { Select } from '@mui/material'
import { DeliveryModel } from 'constants/'
import { useDeliveryModel, useUser } from 'hooks'
import { ChangeEvent, useMemo } from 'react'
import { toDeliveryModelDisplayText } from 'utils'
import { useStyles } from './index.styles'

const DeliveryModelToggle = () => {
  const classes = useStyles()
  const { allowedDeliveryModels } = useUser()
  const { deliveryModel, setDeliveryModel } = useDeliveryModel()
  const onChange = (event: ChangeEvent<{ value: DeliveryModel }>) => setDeliveryModel(event.target.value)

  const options = useMemo(() => {
    if (allowedDeliveryModels.length) {
      return allowedDeliveryModels.map((value) => ({ name: toDeliveryModelDisplayText(value), value }))
    }

    return [{ name: 'Delivery Model Unknown', value: 'UNKNOWN' }]
  }, [allowedDeliveryModels])

  return (
    <Select
      classes={{
        icon: classes.icon,
        select: classes.select,
      }}
      disableUnderline
      label="Delivery Model"
      // @ts-expect-error ??? This is a standard change event
      onChange={onChange}
      size="small"
      value={deliveryModel ?? 'UNKNOWN'}
      variant="standard"
    >
      {options.map(({ name, value }) => (
        <MenuItem key={value} value={value}>
          {name}
        </MenuItem>
      ))}
    </Select>
  )
}

export { DeliveryModelToggle }
