import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { ApiPathname } from '../../constants'
import { GetCountryStatesRequest, GetCountryStatesResponse } from '../../types'

const service = (baseUrl: URL) => {
  const getCountryStatesRequest = useRequest<GetCountryStatesRequest, GetCountryStatesResponse>({
    baseUrl,
    filterQueryToRouteParams: ({ country }) => ({ country }),
    pathname: ApiPathname.OPTIONS_COUNTRY_STATES,
  })

  const getCountryStates = useCallback(
    (query: GetCountryStatesRequest) => getCountryStatesRequest(query),
    [getCountryStatesRequest]
  )

  return {
    getCountryStates,
  }
}

export { service as useOptionsService }
