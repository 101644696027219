import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    subText: {
      display: 'block',
      fontSize: '0.75rem',
      color: theme.palette.grey[600],
    },
  })
) as unknown as UseStylesFunction

export { useStyles }
