import { BaseQuery } from '../types'

// Legacy services
const legacyDefaultPagination: BaseQuery = Object.freeze({
  page: 0,
  page_size: 25,
})

// New services
const defaultPagination: BaseQuery = Object.freeze({
  limit: 25,
  offset: 0,
})

export { defaultPagination, legacyDefaultPagination }
