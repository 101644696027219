import { HttpMethod } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { identity } from 'remeda'
import { pick } from 'utils'
import { ApiPathname } from '../../constants'
import {
  transformCreateCalendarIntegrationRequestToRequestBody,
  transformUpdateCalendarIntegrationRequestToRequestBody,
} from '../../filters'
import {
  AppointmentServiceDeleteResponse,
  CreateCalendarIntegrationRequest,
  CreateCalendarIntegrationResponse,
  DeleteCalendarIntegrationRequest,
  GetCalendarIntegrationShopQuery,
  GetCalendarIntegrationShopReferralSourcesQuery,
  GetCalendarIntegrationShopReferralSourcesResponse,
  GetCalendarIntegrationShopResponse,
  GetCalendarIntegrationsSummariesQuery,
  GetCalendarIntegrationsSummariesResponse,
  GetResourceCalendarIntegrationQuery,
  GetResourceCalendarIntegrationsResponse,
  UpdateCalendarIntegrationRequest,
} from '../../types'

const resourceCalendarIntegrationMethods = (baseUrl: URL) => {
  const createResourceCalendarIntegrationRequest = useRequest<
    CreateCalendarIntegrationRequest,
    CreateCalendarIntegrationResponse
  >({
    baseUrl,
    filterQueryToRequestBody: transformCreateCalendarIntegrationRequestToRequestBody,
    filterQueryToRouteParams: identity,
    method: HttpMethod.POST,
    pathname: ApiPathname.RESOURCE_CALENDAR_INTEGRATIONS,
  })

  const createResourceCalendarIntegration = useCallback(
    (payload: CreateCalendarIntegrationRequest) => createResourceCalendarIntegrationRequest({ ...payload }),
    [createResourceCalendarIntegrationRequest]
  )

  const deleteResourceCalendarIntegrationRequest = useRequest<
    DeleteCalendarIntegrationRequest,
    AppointmentServiceDeleteResponse
  >({
    baseUrl,
    filterQueryToRouteParams: identity,
    method: HttpMethod.DELETE,
    pathname: ApiPathname.RESOURCE_CALENDAR_INTEGRATION,
  })

  const deleteResourceCalendarIntegration = useCallback(
    (payload: DeleteCalendarIntegrationRequest) => deleteResourceCalendarIntegrationRequest({ ...payload }),
    [deleteResourceCalendarIntegrationRequest]
  )

  const updateResourceCalendarIntegrationRequest = useRequest<
    UpdateCalendarIntegrationRequest,
    CreateCalendarIntegrationResponse
  >({
    baseUrl,
    filterQueryToRequestBody: transformUpdateCalendarIntegrationRequestToRequestBody,
    filterQueryToRouteParams: identity,
    method: HttpMethod.PATCH,
    pathname: ApiPathname.RESOURCE_CALENDAR_INTEGRATION,
  })

  const updateResourceCalendarIntegration = useCallback(
    (payload: UpdateCalendarIntegrationRequest) => updateResourceCalendarIntegrationRequest({ ...payload }),
    [updateResourceCalendarIntegrationRequest]
  )

  const getResourceCalendarIntegrationsRequest = useRequest<
    GetResourceCalendarIntegrationQuery,
    GetResourceCalendarIntegrationsResponse
  >({
    baseUrl,
    filterQueryToRouteParams: pick(['resourceId']),
    filterQueryToSearchParams: pick(['type']),
    pathname: ApiPathname.RESOURCE_CALENDAR_INTEGRATIONS,
  })

  const getResourceCalendarIntegrations = useCallback(
    (query: GetResourceCalendarIntegrationQuery) => getResourceCalendarIntegrationsRequest(query),
    [getResourceCalendarIntegrationsRequest]
  )

  const getCalendarIntegrationsSummariesRequest = useRequest<
    GetCalendarIntegrationsSummariesQuery,
    GetCalendarIntegrationsSummariesResponse
  >({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.RESOURCE_CALENDAR_INTEGRATIONS_SUMMARIES,
  })

  const getCalendarIntegrationsSummaries = useCallback(
    (query: GetCalendarIntegrationsSummariesQuery) => getCalendarIntegrationsSummariesRequest(query),
    [getCalendarIntegrationsSummariesRequest]
  )

  const getCalendarIntegrationShopRequest = useRequest<
    GetCalendarIntegrationShopQuery,
    GetCalendarIntegrationShopResponse
  >({
    baseUrl,
    filterQueryToRouteParams: pick(['id', 'type']),
    pathname: ApiPathname.INTEGRATION_SHOP,
  })

  const getCalendarIntegrationShopReferralSources = useRequest<
    GetCalendarIntegrationShopReferralSourcesQuery,
    GetCalendarIntegrationShopReferralSourcesResponse
  >({
    baseUrl,
    filterQueryToRouteParams: pick(['id', 'type']),
    pathname: ApiPathname.INTEGRATION_SHOP_REFERRAL_SOURCES,
  })

  const getCalendarIntegrationShop = useCallback(
    (query: GetCalendarIntegrationShopQuery) => getCalendarIntegrationShopRequest(query),
    [getCalendarIntegrationShopRequest]
  )

  return {
    createResourceCalendarIntegration,
    deleteResourceCalendarIntegration,
    getCalendarIntegrationShop,
    getCalendarIntegrationShopReferralSources,
    getCalendarIntegrationsSummaries,
    getResourceCalendarIntegrations,
    updateResourceCalendarIntegration,
  }
}

export { resourceCalendarIntegrationMethods }
