import { CalendarIntegrationType, defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { AppointmentServiceResourceCalendarIntegration, QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useAppointmentsService } from '../../services'

interface Params {
  resourceIds: string[]
  type?: CalendarIntegrationType
}

const hookName = 'useResourceCalendarIntegrations'
const log = logFactory(hookName)

const useResourceCalendarIntegrations = ({ type, resourceIds }: Params, options: QueryOptions = { enabled: true }) => {
  const {
    getResourceCalendarIntegrations,
    createResourceCalendarIntegration,
    deleteResourceCalendarIntegration,
    updateResourceCalendarIntegration,
  } = useAppointmentsService()

  const doQuery = async () => {
    log(`👥 Fetching resource integrations...`, resourceIds)

    const shopResourceIntegrationRequestPromises = resourceIds.map((resourceId) =>
      getResourceCalendarIntegrations({ resourceId, type })
    )

    const rawShopResourceIntegrations = await Promise.allSettled(shopResourceIntegrationRequestPromises)

    const shopResourceIntegrations = rawShopResourceIntegrations
      .map((result) => {
        if (result.status === 'fulfilled' && result.value.data.length > 0) {
          return result.value.data[0]
        }
      })
      .filter((integration) => !!integration)

    log(`👥 Returned resource integrations...`, resourceIds)

    return shopResourceIntegrations as AppointmentServiceResourceCalendarIntegration[]
  }

  const result = useQuery([hookName, resourceIds, type], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: resourceIds.length > 0,
  })

  return {
    createResourceCalendarIntegration,
    deleteResourceCalendarIntegration,
    updateResourceCalendarIntegration,
    ...result,
  }
}

export { useResourceCalendarIntegrations }
