import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'block',
      height: '2rem',
      fontSize: '1.25rem',
      fontWeight: 400,
    },
  })
)

export { useStyles }
