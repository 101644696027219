import { Country } from 'constants/'
import { useLocale, useMyAccount, useUser, useUserSettings } from 'hooks'
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

type CountryMap = Partial<Record<Country, string>>

interface UseSelectedCountryContext {
  allowedCountries: Country[]
  countryMap: CountryMap
  isLoading: boolean
  selectedCountry?: Country
  setSelectedCountry: (country: Country) => void
}

interface Props {
  children: ReactNode
  country?: Country
}

const context = createContext<UseSelectedCountryContext>({
  allowedCountries: [],
  countryMap: {},
  isLoading: true,
  selectedCountry: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSelectedCountry: (country: Country) => undefined,
})

const { Provider } = context

const SelectedCountryProvider = ({ children, country }: Props) => {
  const { read: readUserSetting, write: writeUserSetting } = useUserSettings()
  const { allowedCountries } = useUser()
  const locale = useLocale()
  const { data: myAccount } = useMyAccount()
  const [isLoading, beLoading] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState<Country>(country as Country)
  const accountCountry = country ?? (myAccount?.country as Country | undefined) ?? 'US'

  const countryFullNameFetcher = useMemo(() => new Intl.DisplayNames([locale], { type: 'region' }), [locale])

  const actualAllowedCountries = useMemo<Country[]>(
    () =>
      [...new Set([...allowedCountries, accountCountry, country])].filter(Boolean).map((country) => country as Country),
    [accountCountry, allowedCountries, country]
  )

  const countryMap = useMemo(
    () =>
      actualAllowedCountries.reduce<CountryMap>(
        (acc, country) => ({
          ...acc,
          [country]: countryFullNameFetcher.of(country),
          [countryFullNameFetcher.of(country) as string]: country,
        }),
        {}
      ),
    [actualAllowedCountries, countryFullNameFetcher]
  )

  useEffect(() => {
    if (!isLoading) {
      return
    }

    if (!myAccount?.country) {
      return
    }

    const userSettingsCountry = readUserSetting('preferredCountry') as Country
    const defaultCountry = actualAllowedCountries.includes(userSettingsCountry) ? userSettingsCountry : accountCountry

    beLoading(false)
    setSelectedCountry(defaultCountry)
  }, [accountCountry, actualAllowedCountries, isLoading, myAccount?.country, readUserSetting])

  useEffect(() => writeUserSetting('preferredCountry', selectedCountry), [selectedCountry, writeUserSetting])

  const value: UseSelectedCountryContext = {
    allowedCountries: actualAllowedCountries,
    countryMap,
    isLoading: !selectedCountry,
    selectedCountry,
    setSelectedCountry,
  }

  const props = {
    children,
    value,
  }

  return <Provider {...props} />
}

const useSelectedCountry = () => useContext(context)

export { SelectedCountryProvider, useSelectedCountry }
