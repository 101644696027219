import { CircularProgress, CircularProgressProps } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { Centered } from '../Centered'

type Props = CircularProgressProps & {
  hasDelay?: boolean
}

const Loader = ({ hasDelay, ...props }: Props) => {
  const [isReady, beReady] = useState(!hasDelay)
  const theme = useTheme()

  useEffect(() => {
    if (isReady) return

    const timeout = setTimeout(() => beReady(true), 1_000)

    return () => clearTimeout(timeout)
  }, [])

  if (!isReady) return null

  return (
    <Centered>
      <CircularProgress {...props} style={{ color: theme.palette.primary.light }} />
    </Centered>
  )
}

export { Loader }
