import { PathEntityName } from 'constants/'
import { CurrentUserProvider } from 'hooks'
import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../../NotFound'
import { Details } from './Details'

const User = lazy(() =>
  import(
    /* webpackChunkName: "User" */
    'components/pages/Users/User'
  ).then(namedExportToDefaultExport('User'))
)

const Router = () => (
  <CurrentUserProvider>
    <Routes>
      <Route element={<Outlet />} path="/">
        <Route element={<User />} index />
        <Route element={<Details />} path={`${PathEntityName.DETAILS}/*`} />
        <Route element={<NotFound />} path="*" />
      </Route>
    </Routes>
  </CurrentUserProvider>
)

export { Router as User }
