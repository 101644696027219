import { Tooltip } from '@mui/material'
import { useCountryStates } from 'app/Router/Providers/CountryStatesProvider'

const StateLabel = ({ code }: { code?: string }) => {
  const states = useCountryStates()
  const stateName = states[code as string] ?? ''

  return (
    <>
      {code ? (
        <Tooltip arrow placement="top" title={stateName}>
          <span>{code}</span>
        </Tooltip>
      ) : (
        '--'
      )}
    </>
  )
}

export { StateLabel }
