import { defaultPagination, defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetPartnerEmployeeLicensesQuery, usePartnerService } from '../../services'
import { useSelectedCountry } from '../../useSelectedCountry'

const EXPIRING_SOON_DAYS_OFFSET = 30

const Status = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  EXPIRING: 'EXPIRING',
} as const

type Status = (typeof Status)[keyof typeof Status]

const hookName = 'usePartnerEmployeeLicenses'
const log = logFactory(hookName)

const transformDateLikeParam = (propName: string, date?: Date[]) => {
  if (!date) {
    return {}
  }

  if (typeof date === 'string') {
    return { [propName]: date }
  }

  if (date instanceof Date) {
    return { [propName]: date.toISOString() }
  }

  if (Array.isArray(date) === false) {
    return {}
  }

  if (date.length !== 2 || date.some((d) => d instanceof Date === false)) {
    return {}
  }

  return {
    [propName]: date.map((d) => d.toISOString()).join(','),
  }
}

const usePartnerEmployeeLicenses = (
  query?: GetPartnerEmployeeLicensesQuery,
  options: QueryOptions = { enabled: true }
) => {
  const { getPartnerEmployeeLicenses, ...adhocMethods } = usePartnerService()
  const { selectedCountry } = useSelectedCountry()

  const params: GetPartnerEmployeeLicensesQuery = {
    ...defaultPagination,
    country: selectedCountry ? [selectedCountry] : undefined,
    sort: 'desc',
    sortBy: 'createdAt',
    ...query,
    ...transformDateLikeParam('validFrom', query?.validFrom),
    ...transformDateLikeParam('validTo', query?.validTo),
  }

  const doQuery = async () => {
    log(`🎓 Fetching partner employee licenses...`, params)

    const response = await getPartnerEmployeeLicenses(params)

    log(`🎓 Returned partner employee licenses...`, response)

    return response
  }

  const { partnerEmployeeIds, ...rest } = params

  // We split out partnerEmployeeIds from the rest of the params to allow for more granular query revalidation
  const response = useQuery([hookName, partnerEmployeeIds, rest], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled),
  })

  return {
    ...response,
    ...adhocMethods,
  }
}

export {
  EXPIRING_SOON_DAYS_OFFSET as LICENSE_EXPIRING_SOON_DAYS_OFFSET,
  Status as UiLicenseStatus,
  usePartnerEmployeeLicenses,
  hookName as usePartnerEmployeeLicensesHookName,
}
