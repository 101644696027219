import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  icon: {
    fill: 'white !important',
  },
  select: {
    background: 'transparent !important',
    color: 'white !important',
    fontWeight: 400,
    fontSize: 16,
  },
}))

export { useStyles }
