import { legacyDefaultPagination, defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetShopsQuery, useAppointmentsService } from '../../services'
import { useSelectedCountry } from '../../useSelectedCountry'

const hookName = 'useShops'
const log = logFactory(hookName)

const useShops = (query?: GetShopsQuery, options: QueryOptions = { enabled: true }) => {
  const { getShops } = useAppointmentsService()
  const { selectedCountry } = useSelectedCountry()

  const params: GetShopsQuery = {
    ...legacyDefaultPagination,
    country: selectedCountry ? [selectedCountry] : undefined,
    sort: 'desc',
    sort_by: 'created_at',
    ...query,
  }

  const doQuery = async () => {
    log(`🏛️ Fetching shops...`, params)

    const shops = await getShops(params)

    log(`🏛️ Returned shops...`, shops)

    return shops
  }

  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled),
  })
}

export { useShops }
