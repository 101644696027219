import { useServiceCountryUrl } from '../useServiceCountryUrl'
import { useVendorMethods } from './methods'
import {
  ActivateVendorRequest,
  CreateVendorRequest,
  GetManyVendorsRequest,
  RejectVendorRequest,
  UpdateVendorRequest,
} from './types/requests'
import { GetManyVendorsResponse } from './types/responses'
import { Vendor } from './types/vendor'

interface UseHookContext {
  activateVendor: (request: ActivateVendorRequest) => Promise<Vendor>
  approveVendor: (id: string) => Promise<Vendor>
  createVendor: (request: CreateVendorRequest) => Promise<Vendor>
  deleteVendor: (id: string) => Promise<void>
  getVendor: (id: string) => Promise<Vendor>
  getVendors: (request: GetManyVendorsRequest) => Promise<GetManyVendorsResponse>
  rejectVendor: (request: RejectVendorRequest) => Promise<Vendor>
  updateVendor: (request: UpdateVendorRequest) => Promise<Vendor>
}

const useVendorService = (): UseHookContext => {
  const baseUrl = useServiceCountryUrl('vendor')

  const {
    activateVendor,
    approveVendor,
    createVendor,
    deleteVendor,
    getVendor,
    getVendors,
    rejectVendor,
    updateVendor,
  } = useVendorMethods(baseUrl)

  return {
    activateVendor,
    approveVendor,
    createVendor,
    deleteVendor,
    getVendor,
    getVendors,
    rejectVendor,
    updateVendor,
  }
}

export { useVendorService }
export * from './types/requests'
export * from './types/responses'
export * from './types/vendor'
