import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core'
import { Route } from 'constants/'
import { useCurrentPartnerEmployee, useCurrentResource, useCurrentShop, useCurrentUser, useCurrentVendor } from 'hooks'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { toDisplayName } from 'utils'
import { Link } from '../../shared/Link'
import { useStyles } from './index.styles'

const Breadcrumbs = () => {
  const { data: shop, isLoading: isShopLoading } = useCurrentShop()
  const { data: resource, isLoading: isResourceLoading } = useCurrentResource()
  const { data: user, isLoading: isUserLoading } = useCurrentUser()
  const { data: partnerEmployee, isLoading: isPartnerEmployeeLoading } = useCurrentPartnerEmployee()
  const { data: vendor, isLoading: isVendorLoading } = useCurrentVendor()

  const routes: { breadcrumb: string; path: string }[] = [{ breadcrumb: 'Home', path: Route.HOME }]

  if (shop) {
    routes.push({ breadcrumb: toDisplayName({ isLoading: isShopLoading, shop }), path: Route.SCHEDULING_SHOPS_SHOP })
  }

  if (resource) {
    routes.push({
      breadcrumb: toDisplayName({ isLoading: isResourceLoading, resource }),
      path: Route.SCHEDULING_RESOURCES_RESOURCE,
    })
  }

  if (user) {
    routes.push({
      breadcrumb: toDisplayName({ isLoading: isUserLoading, user }),
      path: Route.USER,
    })
  }

  if (partnerEmployee) {
    routes.push({
      breadcrumb: toDisplayName({ isLoading: isPartnerEmployeeLoading, user: partnerEmployee }),
      path: Route.PROVIDER,
    })
  }

  if (vendor) {
    routes.push({
      breadcrumb: toDisplayName({ isLoading: isVendorLoading, vendor }),
      path: Route.VENDOR,
    })
  }

  const breadcrumbs = useBreadcrumbs(routes)
  const classes = useStyles()

  if (breadcrumbs.length < 2) {
    return null
  }

  return (
    <MuiBreadcrumbs className={classes.container} separator=">">
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        if (index === breadcrumbs.length - 1) {
          return <span key={match.pathname}>{breadcrumb}</span>
        }

        return (
          <Link color="inherit" key={match} to={match.pathname} underline="hover">
            {breadcrumb}
          </Link>
        )
      })}
    </MuiBreadcrumbs>
  )
}

export { Breadcrumbs }
