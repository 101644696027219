import { FC, createContext } from 'react'
import mixpanel, { Mixpanel } from 'mixpanel-browser'
import { MIXPANEL_PROJECT_TOKEN } from 'config'

interface MixpanelContext {
  mixpanel?: Mixpanel
}

const LIBRARY_NAME = 'partner-portal'

const context = createContext<MixpanelContext>({})
let instance: Mixpanel | undefined = undefined

if (MIXPANEL_PROJECT_TOKEN) {
  // Initialize once this module is imported
  instance = mixpanel.init(MIXPANEL_PROJECT_TOKEN, {}, LIBRARY_NAME)
}

const { Provider } = context
const contextValue = { mixpanel: instance }

const MixpanelProvider: FC = ({ children }) => <Provider value={contextValue}>{children}</Provider>

export { context, MixpanelProvider }
