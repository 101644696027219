import { RouteParameter } from 'constants/'
import { useShops } from 'hooks'
import { createContext, FC, useContext } from 'react'
import { useParams } from 'react-router'
import { AppointmentServiceShop } from 'types'

type RouteParams = {
  [RouteParameter.SHOP_ID]: string
}

type UseShopContext = Omit<ReturnType<typeof useShops>, 'data'> & {
  data?: AppointmentServiceShop
}

const context = createContext({} as UseShopContext)
const { Provider: ContextProvider } = context

const CurrentShopProvider: FC = ({ children }) => {
  const { shopId } = useParams<RouteParams>()
  const { data, ...rest } = useShops({ id: [shopId as string] })
  const shop = data?.data?.[0]
  const value: UseShopContext = { ...rest, data: shop }

  return <ContextProvider value={value}>{children}</ContextProvider>
}

const useCurrentShop = () => useContext(context)

export { CurrentShopProvider, useCurrentShop }
