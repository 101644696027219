import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetCountryStatesRequest, usePartnerService } from '../../services'
import { useSelectedCountry } from '../../useSelectedCountry'

const hookName = 'useCountryStates'
const log = logFactory(hookName)

const useCountryStates = (country?: string, options: QueryOptions = { enabled: true }) => {
  const { getCountryStates } = usePartnerService()
  const { selectedCountry } = useSelectedCountry()
  const actualCountry = (country ?? selectedCountry) as string
  const params: GetCountryStatesRequest = { country: actualCountry }

  const doQuery = async () => {
    log(`🗺️ Fetching country states...`, params)

    const response = await getCountryStates(params)

    log(`🗺️ Returned country states...`, response)

    return response
  }

  return useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled && selectedCountry),
  })
}

export { useCountryStates, hookName as useCountryStatesHookName }
