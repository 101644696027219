enum DeliveryModel {
  DIRECT_TO_CONSUMER = 'DIRECT_TO_CONSUMER',
  NETWORK = 'NETWORK',
  NETWORK_HOME_DELIVERY = 'NETWORK_HOME_DELIVERY',
  TELEAUDIOLOGY = 'TELEAUDIOLOGY',
}

enum DeliveryModelToDisplayText {
  DIRECT_TO_CONSUMER = 'Direct to Consumer',
  NETWORK = 'Network',
  NETWORK_HOME_DELIVERY = 'Network Home Delivery',
  TELEAUDIOLOGY = 'Teleaudiology',
}

export { DeliveryModel, DeliveryModelToDisplayText }
