import { Loader as SharedLoader } from 'components/shared/Loader'

const styles = {
  display: 'grid',
  height: '100vh',
  placeItems: 'center',
  width: '100%',
}

const Loader = () => (
  <div style={styles}>
    <SharedLoader />
  </div>
)

export { Loader }
