import { useAuth0 } from '@auth0/auth0-react'
import { AUTH0_AUDIENCE } from 'config'
import { createContext, FC, useContext, useEffect, useState } from 'react'

const context = createContext<{ data?: string; isLoading: boolean }>({
  isLoading: true,
})

const { Provider } = context

const AccessTokenProvider: FC = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string>()
  const { getAccessTokenSilently, isAuthenticated, isLoading: isAuthLoading } = useAuth0()

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: AUTH0_AUDIENCE,
        })

        setAccessToken(accessToken)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }

    fetchAccessToken()
  }, [isAuthenticated])

  const isLoading = !accessToken || !isAuthenticated || isAuthLoading

  return <Provider value={{ data: accessToken, isLoading }}>{children}</Provider>
}

const useAccessToken = () => useContext(context)

export { AccessTokenProvider, useAccessToken }
