enum ApiPathname {
  IMPERSONATION = '/api/v1/impersonations/:id',
  IMPERSONATIONS = '/api/v1/impersonations',
  OPTIONS_ALLOWED_COUNTRIES = '/api/v1/options/allowed-countries',
  OPTIONS_ROLE_PERMISSIONS = '/api/v1/options/role-permissions',
  OPTIONS_ROLES = '/api/v1/options/roles',
  USER = '/api/v1/users/:id',
  USERS = '/api/v1/users',
}

export { ApiPathname }
