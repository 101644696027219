import { z } from 'zod'
import { schemaVendor } from './vendor'

const schemaGetManyVendorsResponse = z.object({
  data: z.array(schemaVendor),
  meta: z.object({
    itemsTotal: z.number(),
    limit: z.number(),
    offset: z.number(),
    pagesTotal: z.number(),
  }),
})

export type GetManyVendorsResponse = z.infer<typeof schemaGetManyVendorsResponse>
export { schemaGetManyVendorsResponse }
