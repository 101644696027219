const Route = {
  HOME: '/',
  PERMISSION_DENIED: '/permission-denied',
  PROVIDER: '/providers/:partnerEmployeeId',
  PROVIDER_DETAILS: '/providers/:partnerEmployeeId/details',
  PROVIDER_LICENSE: '/providers/:partnerEmployeeId/licenses/:licenseId',
  PROVIDER_LICENSES: '/providers/:partnerEmployeeId/licenses',
  PROVIDER_LICENSES_NEW: '/providers/:partnerEmployeeId/licenses/new',
  PROVIDERS: '/providers',
  PROVIDERS_HEARING_TEST_PACKAGE_TYPE: '/providers/hearing-test-package-types',
  PROVIDERS_LICENSES: '/providers/licenses',
  PROVIDERS_SEARCH: '/providers/search',
  SCHEDULING: '/scheduling',
  SCHEDULING_RESOURCES: '/scheduling/resources',
  SCHEDULING_RESOURCES_RESOURCE: '/scheduling/resources/:resourceId',
  SCHEDULING_RESOURCES_RESOURCE_SCHEDULE: '/scheduling/resources/:resourceId/schedule',
  SCHEDULING_RESOURCES_SCHEDULES: '/scheduling/resources/schedules',
  SCHEDULING_SHOPS: '/scheduling/shops',
  SCHEDULING_SHOPS_SHOP: '/scheduling/shops/:shopId',
  SCHEDULING_SHOPS_SHOP_INTEGRATION: '/scheduling/shops/:shopId/integrations/:integrationId',
  SCHEDULING_SHOPS_SHOP_INTEGRATIONS: '/scheduling/shops/:shopId/integrations',
  USER: '/users/:userId',
  USER_DETAILS: '/users/:userId/details',
  USERS: '/users',
  USERS_NEW: '/users/new',
  VENDOR: '/vendors/:vendorId',
  VENDORS: '/vendors',
  VENDORS_NEW: '/vendors/new',
} as const

type Route = (typeof Route)[keyof typeof Route]

export { Route }
