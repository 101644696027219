import { z } from 'zod'

const deliveryModels = {
  DIRECT_TO_CONSUMER: 'DIRECT_TO_CONSUMER',
  NETWORK: 'NETWORK',
  NETWORK_HOME_DELIVERY: 'NETWORK_HOME_DELIVERY',
  TELEAUDIOLOGY: 'TELEAUDIOLOGY',
} as const

const schemaDeliveryModel = z.preprocess((value: unknown) => {
  if (typeof value === 'string') {
    return value.toUpperCase()
  }

  return value
}, z.nativeEnum(deliveryModels))

type DeliveryModel = (typeof deliveryModels)[keyof typeof deliveryModels]

export type { DeliveryModel }
export { deliveryModels, schemaDeliveryModel }
