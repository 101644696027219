import { Error as ErrorIcon, Warning as WarningIcon } from '@material-ui/icons'
import { Grid, Tooltip, useTheme } from '@mui/material'
import { Date as DateTime } from 'components/shared'
import { addDays, addYears, differenceInDays, isPast } from 'date-fns'
import { LICENSE_EXPIRING_SOON_DAYS_OFFSET, UiLicenseStatus, useLocale } from 'hooks'
import { ReactNode, useMemo } from 'react'
import { PartnerServicePartnerEmployeeLicense } from 'types'

type Props = {
  license: Pick<PartnerServicePartnerEmployeeLicense, 'createdAt' | 'validFrom' | 'validTo'>
}

const licenseExpiryStatus = ({
  validFrom,
  validTo,
}: Pick<PartnerServicePartnerEmployeeLicense, 'validFrom' | 'validTo'>): UiLicenseStatus => {
  const now = new Date()
  const startOfActiveWindow = validFrom ? new Date(validFrom) : now
  const expirationDate = validTo ? new Date(validTo) : addYears(now, 10)
  const hasStartedActiveWindow = isPast(startOfActiveWindow)
  const isExpired = hasStartedActiveWindow ? expirationDate < now : false

  const isExpiring = Boolean(
    hasStartedActiveWindow && !isExpired && expirationDate < addDays(now, LICENSE_EXPIRING_SOON_DAYS_OFFSET)
  )

  switch (true) {
    case isExpired:
      return UiLicenseStatus.EXPIRED
    case isExpiring:
      return UiLicenseStatus.EXPIRING
    default:
      return UiLicenseStatus.ACTIVE
  }
}

const LicenseExpiryDate = ({ license }: Props) => {
  const theme = useTheme()
  const locale = useLocale()
  const status = useMemo(() => licenseExpiryStatus(license), [license])

  const expirationInDays = useMemo(() => {
    if (license.validTo) {
      const expirationDate = new Date(license.validTo)
      const now = new Date()

      return differenceInDays(expirationDate, now)
    }

    return -1
  }, [license.validTo])

  const relativeExpirationDisplayText = useMemo(() => {
    if (status === UiLicenseStatus.ACTIVE) {
      return ''
    }

    const formatter = new Intl.RelativeTimeFormat(locale, {
      numeric: 'auto',
      style: 'long',
    })

    let unit: Intl.RelativeTimeFormatUnit
    let value = expirationInDays

    switch (true) {
      case Math.abs(expirationInDays) > 365:
        unit = 'year'
        value = Math.floor(expirationInDays / 365)
        break
      case Math.abs(expirationInDays) > 30:
        unit = 'month'
        value = Math.floor(expirationInDays / 30)
        break
      default:
        unit = 'day'
        break
    }

    return formatter.format(value, unit)
  }, [expirationInDays, locale, status])

  let Component: ReactNode

  switch (status) {
    case UiLicenseStatus.EXPIRED:
      Component = (
        <Tooltip placement="top" title={`This license expired ${relativeExpirationDisplayText}`}>
          <ErrorIcon color="error" fontSize="small" />
        </Tooltip>
      )
      break
    case UiLicenseStatus.EXPIRING:
      Component = (
        <Tooltip
          placement="top"
          style={{ color: theme.palette.warning.main }}
          title={`This license expires ${relativeExpirationDisplayText}`}
        >
          <WarningIcon fontSize="small" />
        </Tooltip>
      )
      break
    default:
      Component = <></>
      break
  }

  return (
    <Grid alignItems="center" display="flex" gap={0.5}>
      <DateTime date={license.validTo as string} variant="date" />
      {Component}
    </Grid>
  )
}

export { LicenseExpiryDate }
