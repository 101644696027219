enum PathEntityName {
  AVAILABILITIES = 'availabilities',
  ATTRIBUTES = 'attributes',
  DETAILS = 'details',
  HEARING_TEST_PACKAGE_TYPES = 'hearing-test-package-types',
  INTEGRATIONS = 'integrations',
  LICENSES = 'licenses',
  PROVIDERS = 'providers',
  RESOURCES = 'resources',
  SCHEDULE = 'schedule',
  SCHEDULES = 'schedules',
  SCHEDULING = 'scheduling',
  SEARCH = 'search',
  SHOPS = 'shops',
  TELEAUDIOLOGY = 'teleaudiology',
  USERS = 'users',
  VENDORS = 'vendors',
}

export { PathEntityName }
