import Mousetrap from 'mousetrap'
import { useEffect } from 'react'

const useKeyBindings = (bindings: Record<string, () => void>) => {
  useEffect(() => {
    Object.entries(bindings).forEach(([code, fn]) => Mousetrap.bind(code, fn))

    return () => Object.keys(bindings).forEach((code) => Mousetrap.unbind(code))
  }, [bindings])
}

export { useKeyBindings }
