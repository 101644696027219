import { defaultPagination, HttpMethod } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { identity, omit, pick } from 'utils'
import {
  ActivateVendorRequest,
  CreateVendorRequest,
  schemaFilterableSortBy,
  GetManyVendorsRequest,
  RejectVendorRequest,
  schemaSortDirection,
  UpdateVendorRequest,
} from '../types/requests'
import { GetManyVendorsResponse } from '../types/responses'
import { ApiPathname } from '../constants'
import { Vendor } from '../types/vendor'

const defaultGetManyVendorsRequest: GetManyVendorsRequest = {
  ...defaultPagination,
  sort: [schemaSortDirection.Enum.DESC],
  sortBy: [schemaFilterableSortBy.Enum.createdAt],
}

const useVendorMethods = (baseUrl: URL) => {
  const activateVendorRequest = useRequest<ActivateVendorRequest, Vendor>({
    baseUrl,
    filterQueryToRequestBody: omit(['id']),
    filterQueryToRouteParams: pick(['id']),
    method: HttpMethod.PATCH,
    pathname: ApiPathname.VENDOR_ACTIVATE,
  })

  const activateVendor = useCallback(
    (payload: ActivateVendorRequest) => activateVendorRequest({ ...payload }),
    [activateVendorRequest]
  )

  const approveVendorRequest = useRequest<{ id: string }, Vendor>({
    baseUrl,
    filterQueryToRouteParams: pick(['id']),
    method: HttpMethod.PATCH,
    pathname: ApiPathname.VENDOR_APPROVE,
  })

  const approveVendor = useCallback((id: string) => approveVendorRequest({ id }), [approveVendorRequest])

  const createVendorRequest = useRequest<CreateVendorRequest, Vendor>({
    baseUrl,
    filterQueryToRequestBody: identity,
    method: HttpMethod.POST,
    pathname: ApiPathname.VENDORS,
  })

  const createVendor = useCallback(
    (payload: CreateVendorRequest) => createVendorRequest({ ...payload }),
    [createVendorRequest]
  )

  const deleteVendorRequest = useRequest<{ id: string }, void>({
    baseUrl,
    filterQueryToRouteParams: pick(['id']),
    method: HttpMethod.DELETE,
    pathname: ApiPathname.VENDOR,
    transformResponse: () => Promise.resolve(null),
  })

  const deleteVendor = useCallback((id: string) => deleteVendorRequest({ id }), [deleteVendorRequest])

  const getVendorsRequest = useRequest<GetManyVendorsRequest, GetManyVendorsResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.VENDORS,
  })

  const getVendors = useCallback(
    (request: GetManyVendorsRequest) =>
      getVendorsRequest({
        ...defaultGetManyVendorsRequest,
        ...request,
      }),
    [getVendorsRequest]
  )

  const getVendorRequest = useRequest<{ id: string }, Vendor>({
    baseUrl,
    filterQueryToRouteParams: pick(['id']),
    pathname: ApiPathname.VENDOR,
  })

  const getVendor = useCallback((id: string) => getVendorRequest({ id }), [getVendorRequest])

  const rejectVendorRequest = useRequest<RejectVendorRequest, Vendor>({
    baseUrl,
    filterQueryToRequestBody: omit(['id']),
    filterQueryToRouteParams: pick(['id']),
    method: HttpMethod.PATCH,
    pathname: ApiPathname.VENDOR_REJECT,
  })

  const rejectVendor = useCallback(
    (payload: RejectVendorRequest) => rejectVendorRequest({ ...payload }),
    [rejectVendorRequest]
  )

  const transformUpdateVendorRequestToRequestBody = ({
    additionalFiles,
    approverEmail,
    email,
    expiresAt,
    phone,
  }: UpdateVendorRequest) => ({
    additionalFiles,
    approverEmail,
    email,
    expiresAt,
    phone,
  })

  const updateVendorRequest = useRequest<UpdateVendorRequest, Vendor>({
    baseUrl,
    filterQueryToRequestBody: transformUpdateVendorRequestToRequestBody,
    filterQueryToRouteParams: pick(['id']),
    method: HttpMethod.PATCH,
    pathname: ApiPathname.VENDOR,
  })

  const updateVendor = useCallback(
    (request: UpdateVendorRequest) => updateVendorRequest(request),
    [updateVendorRequest]
  )

  return {
    activateVendor,
    approveVendor,
    createVendor,
    deleteVendor,
    getVendor,
    getVendors,
    rejectVendor,
    updateVendor,
  }
}

export { useVendorMethods }
