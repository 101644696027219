import { useServiceCountryUrl } from 'hooks/services'
import { useHttpClientConfig } from 'hooks/services/useHttpClientConfig'
import { useMutation } from 'react-query'
import { ApiPathname } from './contants'
import { HttpHeader } from 'constants/'

interface FileCurrentVersion {
  gid: string
  number: number
  fileGid: string
  sizeInBytes: number
  createdAt: Date
  lastAccessedAt: Date
}

interface CreateFileResponse {
  gid: string
  name: string
  contentType: string
  currentVersion?: FileCurrentVersion
  isDeleted: boolean
  isPersisted: boolean
  isLocked: boolean
  createdAt: Date
  updatedAt: Date
}

const useCreateFile = () => {
  const url = useServiceCountryUrl('document-generation')
  const baseURL = new URL(ApiPathname.FILES, url)
  const http = useHttpClientConfig()

  const postData = async (file: File): Promise<CreateFileResponse> => {
    const config = await http.getConfig()
    const metadata = { name: file.name }
    const formData = new FormData()

    formData.append(
      'metadata',
      new Blob([JSON.stringify(metadata)], {
        type: 'application/json',
      })
    )
    formData.append('file', file, file.name)
    const auth = config.headers ? (config.headers as Record<string, string>)[HttpHeader.AUTHORIZATION] : ''
    const response = await fetch(baseURL, {
      body: formData,
      headers: {
        authorization: auth,
      },
      method: 'POST',
    })

    const data = await response.json()

    return data.data
  }

  return useMutation({
    mutationFn: (file: File) => postData(file),
  })
}

export { useCreateFile }
