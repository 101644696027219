import { DeliveryModel } from 'constants/'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocalStorage } from '../useLocalStorage'
import { useUser } from '../useUser'

interface Props {
  children: ReactNode
}

type DeliveryModelContext = {
  deliveryModel: DeliveryModel
  setDeliveryModel: (deliveryModel: DeliveryModel) => void
}

type DeliveryModelWithUnknown = keyof typeof DeliveryModel | 'UNKNOWN'

const defaultContext: DeliveryModelContext = {
  deliveryModel: DeliveryModel.TELEAUDIOLOGY,
  setDeliveryModel: () => undefined,
}

const context = createContext(defaultContext)
const { Provider } = context

const DeliveryModelProvider = ({ children }: Props) => {
  const { read, write } = useLocalStorage()
  const { allowedDeliveryModels, accountId } = useUser()
  const isReady = Boolean(accountId)

  const storedDeliveryModel = useMemo(() => read('deliveryModel', true) as DeliveryModel | null, [isReady, read])
  const fallbackDeliveryModel = allowedDeliveryModels?.[0] ?? DeliveryModel.TELEAUDIOLOGY

  const selectedDeliveryModel = useMemo(
    () =>
      (allowedDeliveryModels.includes(storedDeliveryModel as DeliveryModel)
        ? (storedDeliveryModel as DeliveryModel)
        : fallbackDeliveryModel ?? 'UNKNOWN') as DeliveryModelWithUnknown,
    [allowedDeliveryModels.length, fallbackDeliveryModel, storedDeliveryModel]
  )

  useEffect(
    () => setDeliveryModel(storedDeliveryModel ?? fallbackDeliveryModel),
    [fallbackDeliveryModel, isReady, storedDeliveryModel]
  )

  const [deliveryModel, setDeliveryModel] = useState<DeliveryModel>(
    selectedDeliveryModel && selectedDeliveryModel !== 'UNKNOWN'
      ? (selectedDeliveryModel as DeliveryModel)
      : defaultContext.deliveryModel
  )

  const setDeliveryModelProxy = useCallback(
    (nextDeliveryModel: DeliveryModel) => {
      write('deliveryModel', nextDeliveryModel, true)
      setDeliveryModel(nextDeliveryModel)
    },
    [write]
  )

  const value = useMemo(
    () => ({
      deliveryModel,
      setDeliveryModel: setDeliveryModelProxy,
    }),
    [deliveryModel, setDeliveryModelProxy]
  )

  return <Provider value={value}>{children}</Provider>
}

const useDeliveryModel = () => useContext(context)

export { DeliveryModelProvider, useDeliveryModel }
