import { Button as MuiButton } from '@material-ui/core'
import type { ButtonProps } from '@material-ui/core'
import cx from 'clsx'
import { useStyles } from './index.styles'

type CustomButtonVariant = 'contained' | 'outlined' | 'primary' | 'secondary' | 'text'

type Props = Omit<ButtonProps, 'variant'> & {
  variant?: CustomButtonVariant
}

const Button = ({ className, variant = 'primary', ...props }: Props) => {
  const classes = useStyles()
  let selectedVariant = 'text' as CustomButtonVariant

  if (variant !== 'text') {
    selectedVariant = variant === 'primary' ? 'contained' : ('outlined' as CustomButtonVariant)
  }

  const actualProps: ButtonProps = {
    color: 'primary',
    ...props,
    className: cx(
      classes.container,
      // @ts-expect-error variant as index
      classes[variant] ?? undefined,
      className
    ),
    // @ts-expect-error variant is optional, yet not allow undefined, yet is always defined as text or line 17
    variant: selectedVariant,
  }

  return <MuiButton {...actualProps} />
}

export { Button }
