const entityNameMap = {
  provider: 'Provider',
  ['scheduling-resource']: 'Scheduling Resource',
  user: 'User',
} as const

type Entity = keyof typeof entityNameMap
type EntityDisplayNameWithStatus = `${(typeof entityNameMap)[Entity]}${'' | ' (inactive)'}`

const relatedEntityDisplayText = (entity: Entity, isActive = true): EntityDisplayNameWithStatus =>
  `${entityNameMap[entity]}${isActive ? '' : ' (inactive)'}`

export { relatedEntityDisplayText }
