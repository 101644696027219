import { parseISO } from 'date-fns'
import { ZodSchema, z } from 'zod'

const sortDirections = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
} as const

type SortDirection = (typeof sortDirections)[keyof typeof sortDirections]

const string = z.string().trim().min(1)

const date = z.preprocess((value) => {
  if (value instanceof Date) {
    return value
  }

  return parseISO(value as string)
}, z.date())

const boolean = z.preprocess((value) => {
  if (typeof value === 'boolean') {
    return value
  }

  if (typeof value !== 'string') {
    return value
  }

  const normalized = value.toLowerCase()

  if (['1', 'true'].includes(normalized)) {
    return true
  } else if (['0', 'false'].includes(normalized)) {
    return false
  }

  return value
}, z.boolean())

const locale = string
  .refine(
    (locale: string) => {
      try {
        const tmpLocal = new Intl.Segmenter(locale).resolvedOptions().locale

        return Boolean(tmpLocal.toLowerCase() === locale.toLowerCase() && tmpLocal.split('-').length >= 2)
      } catch {
        return false
      }
    },
    (value) => ({
      message: `The given locale '${value}' is not valid. Please verify it is valid in the format of 'en-US'`,
    })
  )
  .transform((locale) => new Intl.Segmenter(locale).resolvedOptions().locale)

const search = z.string().trim().min(1)

const timeZone = string
  .refine(
    (timeZone: string) => {
      try {
        Intl.DateTimeFormat(undefined, { timeZone })
      } catch {
        return false
      }

      return true
    },
    {
      message: 'The given time zone is not valid',
    }
  )
  .transform((timeZone) => Intl.DateTimeFormat(undefined, { timeZone }).resolvedOptions().timeZone)

const email = string.email().transform((email) => email.toLowerCase())

const number = z.number()
const url = string.url()
const uuid = z.string().uuid()

const validation = {
  boolean,
  date,
  email,
  locale,
  number,
  search,
  string,
  timeZone,
  url,
  uuid,
}

const filterable = {
  boolean: boolean.array(),
  date: date.array(),
  email: email.array(),
  locale: locale.array(),
  number: number.array(),
  string: string.array(),
  timeZone: timeZone.array(),
  uuid: uuid.array(),
}

const schemaSortDirection = z.preprocess((value: unknown) => {
  if (typeof value === 'string') {
    return value.toUpperCase()
  }

  return value
}, z.nativeEnum(sortDirections))

const DEFAULT_RESULTS_EXTERNAL = 25

const pagination = z.object({
  limit: number.optional().default(DEFAULT_RESULTS_EXTERNAL),
  offset: number.optional().default(0),
  sort: z.array(schemaSortDirection).optional(),
})

const schemaToPaginatedSchema = (schema: ZodSchema): ZodSchema => {
  const meta = z.object({
    itemsTotal: validation.number,
    limit: validation.number,
    offset: validation.number,
    pagesTotal: validation.number,
  })

  const paginatedSchema = z.object({
    data: z.array(schema),
    meta,
  })

  return paginatedSchema
}

export type { SortDirection }
export { filterable, pagination, sortDirections, schemaSortDirection, schemaToPaginatedSchema, validation }
