import { defaultQueryOptions } from 'constants/'
import { useCurrentUser } from 'hooks'
import { ReactNode, createContext, useContext } from 'react'
import { UseQueryResult, useQuery } from 'react-query'
import { logFactory } from 'utils'
import { GetImpersonationRequest, Impersonation, useUserService } from '../services/useUserService'

interface Props {
  children: ReactNode
  id?: string
}

const hookName = 'useImpersonation'
const log = logFactory(hookName)

const context = createContext({} as UseQueryResult<Impersonation | null, unknown>)
const { Provider } = context

const ImpersonationProvider = ({ children }: Props) => {
  const { getImpersonation } = useUserService()
  const { data: user } = useCurrentUser()

  const params: GetImpersonationRequest = {
    id: user?.id as string,
  }

  const doQuery = async () => {
    log(`🕵️‍♀️ Fetching impersonation...`, params)

    const impersonation = await getImpersonation(params)

    log(`🕵️‍♀️ Returned impersonation...`, impersonation)

    return impersonation ?? null
  }

  const results = useQuery([hookName, params.id, params], doQuery, {
    ...defaultQueryOptions,
    enabled: Boolean(params.id),
  })

  return <Provider value={results}>{children}</Provider>
}

const useImpersonation = () => useContext(context)

export { ImpersonationProvider, useImpersonation, hookName as useImpersonationHookName }
