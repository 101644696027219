import { validation } from 'utils'
import { z } from 'zod'
import { schemaAuditUser } from './auditUser'
import { schemaCountry } from './country'
import { schemaDeliveryModel } from './delivery-model'
import { schemaRole } from './role'
import { schemaUserSettings } from './settings'

const schemaRelatedEntityGids = z
  .object({
    partnerEmployeeGid: validation.string.nullish(),
    salesforceId: validation.string.nullish(),
  })
  .strict()

const schemaUser = z
  .object({
    allowedCountries: z.array(schemaCountry),
    allowedDeliveryModels: z.array(schemaDeliveryModel),
    createdAt: validation.date,
    createdBy: schemaAuditUser,
    email: validation.email,
    familyName: validation.string,
    gid: validation.string,
    givenName: validation.string,
    id: validation.uuid,
    isActive: validation.boolean,
    photoUrl: validation.url.nullable(),
    relatedEntityGids: schemaRelatedEntityGids,
    roles: z.array(schemaRole),
    settings: schemaUserSettings,
    updatedAt: validation.date,
    updatedBy: schemaAuditUser,
  })
  .passthrough()

type User = z.infer<typeof schemaUser>

export { schemaUser }
export type { User }
