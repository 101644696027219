import { BrowserRouter } from 'react-router-dom'
import { AuthenticatedRoutes } from '../AuthenticatedRoutes'
import { Providers } from '../Providers'

const Router = () => (
  <BrowserRouter>
    <Providers>
      <AuthenticatedRoutes />
    </Providers>
  </BrowserRouter>
)

export { Router }
