import { createContext, FC, useContext } from 'react'
import { merge } from 'utils'
import { UserSetting, UserSettings } from 'types'

const LS_KEY = 'user-settings'

interface UserSettingsContext {
  purgeAll: () => void
  read: (setting: UserSetting) => boolean | string | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  write: (setting: UserSetting, value: any) => void
}

const purgeAll = () => localStorage.removeItem(LS_KEY)

const readSettings = (): UserSettings => {
  const existing = localStorage.getItem(LS_KEY)

  if (existing) {
    try {
      return JSON.parse(existing) as UserSettings
    } catch {
      return {}
    }
  }

  return {}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const writeSettings = (settings: Record<string, any>) => {
  const merged = merge(readSettings(), settings)

  localStorage.setItem(LS_KEY, JSON.stringify(merged))
}

const read = (setting: UserSetting) => {
  const settings = readSettings()

  return settings[setting]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const write = (setting: UserSetting, value: any) => writeSettings({ [setting]: value })

const defaultContextValue: UserSettingsContext = {
  purgeAll,
  read,
  write,
}

const context = createContext<UserSettingsContext>(defaultContextValue)

const { Provider } = context

const UserSettingsProvider: FC = ({ children }) => {
  const props = { children, value: defaultContextValue }

  return <Provider {...props} />
}

const useUserSettings = () => useContext(context)

export { UserSettingsProvider, useUserSettings }
