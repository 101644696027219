import { Account, PartnerServicePartnerEmployeeCertification } from 'types'

const HearingTestPackageType = {
  CLINIC_IN_A_BOX: 'CLINIC_IN_A_BOX',
  FITKIT: 'FITKIT',
  SALES_PACKAGE: 'SALES_PACKAGE',
} as const

type HearingTestPackageType = (typeof HearingTestPackageType)[keyof typeof HearingTestPackageType]

const HearingTestPackageTypeSupportedCountry = {
  CH: 'CH',
  DE: 'DE',
  US: 'US',
} as const

type HearingTestPackageTypeSupportedCountry =
  (typeof HearingTestPackageTypeSupportedCountry)[keyof typeof HearingTestPackageTypeSupportedCountry]

type StateCertificationConfig = {
  [state: string]: {
    default?: HearingTestPackageType
    certificationMappings?: Partial<Record<PartnerServicePartnerEmployeeCertification, HearingTestPackageType>>
  }
}

type CountryHearingTestPackageTypeConfig = {
  default: HearingTestPackageType
  states: StateCertificationConfig
}

type HearingTestPackageTypeValue = Record<HearingTestPackageTypeSupportedCountry, CountryHearingTestPackageTypeConfig>

type HearingTestPackageTypeConfig = {
  id: string
  value: HearingTestPackageTypeValue
  createdAt: Date
  createdBy: Account
}

type CreateOneHearingTestPackageTypeConfigRequest = {
  country: HearingTestPackageTypeSupportedCountry
  value: CountryHearingTestPackageTypeConfig
}

type UpdateHearingTestPackageTypeConfigRequest = {
  country: HearingTestPackageTypeSupportedCountry
  value: CountryHearingTestPackageTypeConfig
}

export {
  HearingTestPackageType as PartnerServiceHearingTestPackageType,
  HearingTestPackageTypeSupportedCountry as PartnerServiceHearingTestPackageTypeSupportedCountry,
}

export type {
  CountryHearingTestPackageTypeConfig as PartnerServiceCountryHearingTestPackageTypeConfig,
  CreateOneHearingTestPackageTypeConfigRequest as PartnerServiceCreateOneHearingTestPackageTypeConfigRequest,
  HearingTestPackageTypeConfig as PartnerServiceHearingTestPackageTypeConfig,
  StateCertificationConfig as PartnerServiceStateCertificationConfig,
  UpdateHearingTestPackageTypeConfigRequest as PartnerServiceUpdateHearingTestPackageTypeConfigRequest,
}
