import { Debugger } from 'debug'
import { Mixpanel } from 'mixpanel-browser'
import { useContext } from 'react'
import { context as mixpanelContext } from 'app/Router/Providers/Analytics/Mixpanel'
import { logFactory as nativeLogFactory } from '../../utils'

interface UseMixpanelContext {
  logFactory: (method: string) => Debugger
  mixpanel?: Mixpanel
}

const logNamespace = 'mixpanel'

const useMixpanel = (): UseMixpanelContext => {
  const { mixpanel } = useContext(mixpanelContext)
  const logFactory = (method: string) => nativeLogFactory(logNamespace, method)

  return {
    logFactory,
    mixpanel,
  }
}

export { useMixpanel }
