import { z } from 'zod'

const schemaVendorCountry = z.enum(['US', 'CA', 'CH', 'DE'])
const schemaVendorTaxIdentificationType = z.enum(['EIN', 'ITIN', 'SSN'])
const schemaVendorFederalTaxClassifications = z.enum([
  'C_CORPORATION',
  'INDIVIDUAL',
  'LLC',
  'PARTNERSHIP',
  'S_CORPORATION',
  'TRUST_ESTATE',
])
const schemaVendorBankingPaymentMethod = z.enum(['ACH', 'WIRE'])
const schemaAuditUserType = z.enum(['SYSTEM', 'USER'])
const schemaVendorType = z.enum(['MARKETING'])
const schemaVendorStatus = z.enum([
  'ACTIVE',
  'AWAITING_APPROVAL',
  'DENIED',
  'IN_PROCESS',
  'INACTIVE',
  'INTERNAL_PROCESSING',
  'NEEDS_REVISION',
  'NOTIFIED_VENDOR',
  'UNDER_REVIEW',
])

const schemaAuditUser = z.object({
  email: z.string(),
  id: z.string(),
  type: schemaAuditUserType,
})

const schemaVendorAdditionalContact = z.object({
  email: z.string(),
  name: z.string(),
  phone: z.string(),
})

const schemaVendorAdditionalFile = z.object({
  description: z.string(),
  fileGid: z.string(),
})

const schemaVendorAddress = z.object({
  city: z.string(),
  postalCode: z.string(),
  state: z.string(),
  street: z.string(),
})

const schemaVendorBankingInformation = z.object({
  bankAccountNumber: z.string(),
  bankLetterFileGid: z.string(),
  bankName: z.string(),
  bankStatementFileGid: z.string(),
  paymentMethod: schemaVendorBankingPaymentMethod,
  transitNumber: z.string(),
  voidCheckFileGid: z.string(),
})

const schemaVendorTaxationInformation = z.object({
  federalId: z.string(),
  federalTaxClassification: schemaVendorFederalTaxClassifications,
  taxFormFileGid: z.string(),
  taxIdentificationType: schemaVendorTaxIdentificationType,
})

const schemaVendorMarketingDetails = z.object({
  bankingInformation: schemaVendorBankingInformation,
  taxationInformation: schemaVendorTaxationInformation,
})

const schemaVendorNote = z.object({
  comments: z.string(),
  createdAt: z.date(),
  createdBy: schemaAuditUser,
})

const schemaVendor = z.object({
  additionalContacts: z.array(schemaVendorAdditionalContact).optional(),
  additionalFiles: z.array(schemaVendorAdditionalFile).optional(),
  address: schemaVendorAddress.optional(),
  approverEmail: z.string(),
  comments: z.string().optional(),
  country: schemaVendorCountry,
  createdAt: z.date(),
  createdBy: schemaAuditUser,
  details: schemaVendorMarketingDetails.optional(),
  email: z.string(),
  expiresAt: z.date(),
  gid: z.string().optional(),
  id: z.string(),
  name: z.string(),
  notes: z.array(schemaVendorNote).optional(),
  phone: z.string(),
  status: schemaVendorStatus,
  type: schemaVendorType,
  updatedAt: z.date(),
  updatedBy: schemaAuditUser,
})

export type Vendor = z.infer<typeof schemaVendor>
export type VendorCountry = z.infer<typeof schemaVendorCountry>
export type VendorTaxIdentificationType = z.infer<typeof schemaVendorTaxIdentificationType>
export type VendorFederalTaxClassifications = z.infer<typeof schemaVendorFederalTaxClassifications>
export type VendorBankingPaymentMethods = z.infer<typeof schemaVendorBankingPaymentMethod>
export type AuditUserType = z.infer<typeof schemaAuditUserType>
export type VendorType = z.infer<typeof schemaVendorType>
export type VendorStatus = z.infer<typeof schemaVendorStatus>
export type VendorAdditionalFile = z.infer<typeof schemaVendorAdditionalFile>

export {
  schemaVendor,
  schemaVendorAdditionalContact,
  schemaVendorAdditionalFile,
  schemaVendorAddress,
  schemaVendorMarketingDetails,
  schemaVendorTaxationInformation,
  schemaVendorBankingInformation,
  schemaVendorNote,
  schemaAuditUser,
  schemaVendorCountry,
  schemaVendorTaxIdentificationType,
  schemaVendorFederalTaxClassifications,
  schemaVendorBankingPaymentMethod,
  schemaVendorType,
  schemaVendorStatus,
}
