import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../../NotFound'

const ProviderLicenses = lazy(() =>
  import(
    /* webpackChunkName: "Licenses" */
    'components/pages/Providers/Licenses'
  ).then(namedExportToDefaultExport('Licenses'))
)

const Licenses = () => (
  <Routes>
    <Route element={<Outlet />} path="/">
      <Route element={<ProviderLicenses />} index />
      <Route element={<NotFound />} path="*" />
    </Route>
  </Routes>
)

export { Licenses as ProviderLicenses }
