import { defaultQueryOptions } from 'constants/'
import { GetCalendarIntegrationsSummariesQuery, GetCalendarIntegrationsSummariesResponse } from '../../services'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { useAppointmentsService } from '../../services'

const hookName = 'useCalendarIntegrationsSummaries'
const log = logFactory(hookName)

const useCalendarIntegrationsSummaries = (
  query: GetCalendarIntegrationsSummariesQuery,
  options: QueryOptions = { enabled: true }
) => {
  const { getCalendarIntegrationsSummaries } = useAppointmentsService()

  const doQuery = async () => {
    log(`👥 Fetching calendar integrations summaries...`, query)

    const calendarIntegrationsSummaries = await getCalendarIntegrationsSummaries(query)

    return calendarIntegrationsSummaries as unknown as GetCalendarIntegrationsSummariesResponse
  }

  return useQuery([hookName, query], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })
}

export { useCalendarIntegrationsSummaries }
