import { HttpMethod, legacyDefaultPagination } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { identity, omit, pick } from 'remeda'
import { ApiPathname } from '../../constants'
import { transformShopsResponseToGetShopsResponse } from '../../filters'
import { GetShopsQuery, GetShopsResponse, UpdateShopRequest } from '../../types'

const shopsMethods = (baseUrl: URL) => {
  const getShopsRequest = useRequest<GetShopsQuery, GetShopsResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.SHOPS,
    transformResponse: transformShopsResponseToGetShopsResponse,
  })

  const updateShopsRequest = useRequest<UpdateShopRequest, GetShopsResponse>({
    baseUrl,
    filterQueryToRequestBody: omit(['id']),
    filterQueryToRouteParams: pick(['id']),
    method: HttpMethod.PATCH,
    pathname: ApiPathname.SHOP,
    transformResponse: transformShopsResponseToGetShopsResponse,
  })

  const getShops = useCallback(
    (query: GetShopsQuery) =>
      getShopsRequest({
        ...legacyDefaultPagination,
        ...query,
      }),
    [getShopsRequest]
  )

  const updateShop = useCallback(
    (query: UpdateShopRequest) =>
      updateShopsRequest({
        ...query,
      }),
    [updateShopsRequest]
  )

  return { getShops, updateShop }
}

export { shopsMethods }
