import { AppointmentServiceShop } from 'types'
import { identity } from 'utils'
import {
  CreateCalendarIntegrationAppointmentTypeMappingRequest,
  CreateCalendarIntegrationMappingRequest,
  CreateCalendarIntegrationRequest,
  GetAppointmentsQuery,
  GetCalendarIntegrationAppointmentTypeMappingsQuery,
  GetResourcesQuery,
  UpdateCalendarIntegrationAppointmentTypeMappingRequest,
  UpdateCalendarIntegrationMappingRequest,
  UpdateCalendarIntegrationRequest,
  UpdateResourceIsActiveRequest,
} from './types'

const appointmentsQueryToSearchParams = ({
  dateEnd,
  dateStart,
  resourceGids: resource_gid,
  ...rest
}: GetAppointmentsQuery) => ({
  ...rest,
  date_end: dateEnd.toISOString(),
  date_start: dateStart.toISOString(),
  resource_gid,
})
const resourcesQueryToSearchParams = ({ types: type, ...rest }: GetResourcesQuery) => ({
  ...rest,
  type: type?.join(),
})

const transformCreateCalendarIntegrationMappingRequestToRequestBody = ({
  calendarIntegrationType: calendar_integration_type,
  externalId: external_id,
  internalId: internal_id,
  type,
}: CreateCalendarIntegrationMappingRequest) => ({
  calendar_integration_type,
  external_id,
  internal_id,
  type,
})

const transformCreateCalendarIntegrationAppointmentTypeMappingRequestToRequestBody = ({
  calendarIntegrationType: calendar_integration_type,
  deliveryModel: delivery_model,
  externalAppointmentType: external_appointment_type,
  internalAppointmentType: internal_appointment_type,
  shopId: shop_id,
}: CreateCalendarIntegrationAppointmentTypeMappingRequest) => ({
  calendar_integration_type,
  delivery_model,
  external_appointment_type,
  internal_appointment_type,
  shop_id,
})

const transformGetCalendarIntegrationAppointmentTypeMappingRequestToRequestBody = ({
  calendarIntegrationType: calendar_integration_type,
  shopId: shop_id,
}: GetCalendarIntegrationAppointmentTypeMappingsQuery) => ({
  calendar_integration_type,
  shop_id,
})

const transformUpdateCalendarIntegrationAppointmentTypeMappingRequestToRequestBody = ({
  externalAppointmentType: external_appointment_type,
}: UpdateCalendarIntegrationAppointmentTypeMappingRequest) => ({
  external_appointment_type,
})

const calendarIntegrationAppointmentTypeMappingsQueryToSearchParams = identity

const transformCreateCalendarIntegrationRequestToRequestBody = ({
  credentials,
  name,
  type,
  writePolicy: write_policy,
}: CreateCalendarIntegrationRequest) => ({
  credentials,
  name,
  type,
  write_policy,
})

const transformUpdateCalendarIntegrationMappingRequestToRequestBody = ({
  externalId: external_id,
}: UpdateCalendarIntegrationMappingRequest) => ({
  external_id,
})
const transformUpdateCalendarIntegrationRequestToRequestBody = ({
  credentials,
  name,
}: UpdateCalendarIntegrationRequest) => ({
  credentials,
  name,
})

const transformShopsResponseToGetShopsResponse = async (response: Response) => {
  const { data, ...rest } = await response.json()
  const shops: AppointmentServiceShop[] = Array.isArray(data) ? data : [data]

  const transformed = shops.map((shop) => ({
    ...shop,
    created_at: new Date(shop.created_at),
    resources: shop.resources.map((resource) => ({
      ...resource,
      created_at: new Date(resource.created_at),
      updated_at: new Date(resource.updated_at),
    })),
    updated_at: new Date(shop.updated_at),
  }))

  return { ...rest, data: transformed }
}

const transformUpdateIsActiveResourceRequestToRequestBody = ({
  isActive: is_active,
}: UpdateResourceIsActiveRequest) => ({
  is_active,
})

export {
  appointmentsQueryToSearchParams,
  calendarIntegrationAppointmentTypeMappingsQueryToSearchParams,
  identity as calendarIntegrationMappingsQueryToSearchParams,
  resourcesQueryToSearchParams,
  transformCreateCalendarIntegrationAppointmentTypeMappingRequestToRequestBody,
  transformCreateCalendarIntegrationMappingRequestToRequestBody,
  transformCreateCalendarIntegrationRequestToRequestBody,
  transformGetCalendarIntegrationAppointmentTypeMappingRequestToRequestBody,
  transformShopsResponseToGetShopsResponse,
  transformUpdateCalendarIntegrationAppointmentTypeMappingRequestToRequestBody,
  transformUpdateCalendarIntegrationMappingRequestToRequestBody,
  transformUpdateCalendarIntegrationRequestToRequestBody,
  transformUpdateIsActiveResourceRequestToRequestBody,
}
