import { DeliveryModel } from './deliveryModel'
import { Solution } from './solution'

enum Journey {
  DIRECT_TO_CONSUMER_STANDARD = 'DIRECT_TO_CONSUMER_STANDARD',

  NETWORK_BEST_CHOICE = 'NETWORK_BEST_CHOICE',
  /**
   * @deprecated This journey is no longer used
   */
  NETWORK_DIRECT_SALE = 'NETWORK_DIRECT_SALE',
  NETWORK_HEAR_TODAY = 'NETWORK_HEAR_TODAY',
  /**
   * @deprecated Use NETWORK_MEDICARE_ADVANTAGE instead
   */
  NETWORK_MEDICAL_ADVANTAGE = 'NETWORK_MEDICAL_ADVANTAGE',
  NETWORK_MEDICARE_ADVANTAGE = 'NETWORK_MEDICARE_ADVANTAGE',
  NETWORK_STANDARD = 'NETWORK_STANDARD',
  NETWORK_TRUHEARING = 'NETWORK_TRUHEARING',
  NETWORK_YOUNG_HEARING_AID = 'NETWORK_YOUNG_HEARING_AID',

  NETWORK_HOME_DELIVERY_BEST_CHOICE = 'NETWORK_HOME_DELIVERY_BEST_CHOICE',
  NETWORK_HOME_DELIVERY_HEAR_TODAY = 'NETWORK_HOME_DELIVERY_HEAR_TODAY',
  NETWORK_HOME_DELIVERY_MEDICARE_ADVANTAGE = 'NETWORK_HOME_DELIVERY_MEDICARE_ADVANTAGE',
  NETWORK_HOME_DELIVERY_STANDARD = 'NETWORK_HOME_DELIVERY_STANDARD',
  NETWORK_HOME_DELIVERY_TRUHEARING = 'NETWORK_HOME_DELIVERY_TRUHEARING',
  NETWORK_HOME_DELIVERY_YOUNG_HEARING_AID = 'NETWORK_HOME_DELIVERY_YOUNG_HEARING_AID',

  TELEAUDIOLOGY_BEST_CHOICE = 'TELEAUDIOLOGY_BEST_CHOICE',
  TELEAUDIOLOGY_ECONOMY = 'TELEAUDIOLOGY_ECONOMY',
  TELEAUDIOLOGY_HEAR_TODAY = 'TELEAUDIOLOGY_HEAR_TODAY',
  /**
   * @deprecated Use TELEAUDIOLOGY_MEDICARE_ADVANTAGE instead
   */
  TELEAUDIOLOGY_MEDICAL_ADVANTAGE = 'TELEAUDIOLOGY_MEDICAL_ADVANTAGE',
  TELEAUDIOLOGY_MEDICARE_ADVANTAGE = 'TELEAUDIOLOGY_MEDICARE_ADVANTAGE',
  TELEAUDIOLOGY_STANDARD = 'TELEAUDIOLOGY_STANDARD',
  TELEAUDIOLOGY_TRUHEARING = 'TELEAUDIOLOGY_TRUHEARING',
}

const getJourneyFromDeliveryModelAndSolution = (deliveryModel: DeliveryModel, solution: Solution): Journey => {
  const journeyString = `${deliveryModel}_${solution}`.toUpperCase()

  const journey = Journey[journeyString as keyof typeof Journey]

  if (!journey) {
    throw new Error(`Invalid combination of delivery model and solution: ${journeyString}`)
  }

  return journey
}

export { Journey, getJourneyFromDeliveryModelAndSolution }
