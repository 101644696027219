import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetCalendarIntegrationAppointmentTypeMappingsQuery, useAppointmentsService } from '../../services'

const hookName = 'useIntegrationAppointmentTypeMappings'
const log = logFactory(hookName)

const useIntegrationAppointmentTypeMappings = (
  query: GetCalendarIntegrationAppointmentTypeMappingsQuery,
  options: QueryOptions = { enabled: true }
) => {
  const {
    createCalendarIntegrationAppointmentTypeMapping,
    deleteCalendarIntegrationAppointmentTypeMapping,
    getCalendarIntegrationAppointmentTypeMappings,
    updateCalendarIntegrationAppointmentTypeMapping,
  } = useAppointmentsService()

  const params: GetCalendarIntegrationAppointmentTypeMappingsQuery = {
    ...query,
  }

  const doQuery = async () => {
    log(`👥 Fetching integration appointment type mappings...`, params)

    const { data: integrations } = await getCalendarIntegrationAppointmentTypeMappings(params)

    log(`👥 Returned integration appointment type mappings...`, params)

    return integrations
  }

  const result = useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })

  return {
    createCalendarIntegrationAppointmentTypeMapping,
    deleteCalendarIntegrationAppointmentTypeMapping,
    updateCalendarIntegrationAppointmentTypeMapping,
    ...result,
  }
}

export { useIntegrationAppointmentTypeMappings }
