import { RouteParameter } from 'constants/'
import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport, pathnameToRouteParameter } from 'utils'
import { NotFound } from '../../NotFound'
import { Shop } from './$shopId'

const shopIdRouteParameter = pathnameToRouteParameter(RouteParameter.SHOP_ID)

const Shops = lazy(() =>
  import(
    /* webpackChunkName: "Shops" */
    'components/pages/Scheduling/Shops'
  ).then(namedExportToDefaultExport('Shops'))
)

const Router = () => (
  <Routes>
    <Route element={<Outlet />} path="/">
      <Route element={<Shops />} index />
      <Route element={<Shop />} path={`${shopIdRouteParameter}/*`} />
      <Route element={<NotFound />} path="*" />
    </Route>
  </Routes>
)

export { Router as Shops }
