import { useUser } from 'hooks'

const LocalStorageKey = {
  DELIVERY_MODEL: 'deliveryModel',
  LICENSES_SEARCH: 'licenses:search',
  PARTNER_EMPLOYEE_LICENSES_SEARCH: 'licenses:search:partner-employee',
  SOLUTION: 'solution',
} as const

type LocalStorageKey = (typeof LocalStorageKey)[keyof typeof LocalStorageKey]

const toUserSpecificKey = (key: string, userId: string) => `${userId}:${key}`

const useLocalStorage = () => {
  const { accountId: userId, isAuthenticated: isReady } = useUser()

  const read = (key: LocalStorageKey, isUserSpecific = false) => {
    const actualKey = isUserSpecific && isReady ? toUserSpecificKey(key, userId as string) : key
    const raw = localStorage.getItem(actualKey)

    if (!raw) {
      return null
    }

    return JSON.parse(raw)
  }

  const write = (key: LocalStorageKey, value: unknown, isUserSpecific = false) => {
    const actualKey = isUserSpecific && isReady ? toUserSpecificKey(key, userId as string) : key
    const raw = JSON.stringify(value)

    localStorage.setItem(actualKey, raw)
  }

  return {
    read,
    write,
  }
}

export { LocalStorageKey, useLocalStorage }
