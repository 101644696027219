import { DeliveryModelProvider, NotificationProvider, SelectedCountryProvider, UserSettingsProvider } from 'hooks'
import { FC } from 'react'
import { AnalyticsProvider } from '../Analytics'
import { AuthProvider } from '../Auth'
import { CountryStatesProvider } from '../CountryStatesProvider'
import { FeatureFlagProvider } from '../FeatureFlag'
import { NewReleaseProvider } from '../NewRelease'
import { QueryProvider } from '../Query'
import { ThemeProvider } from '../Theme'

const Providers: FC = ({ children }) => (
  <ThemeProvider>
    <AuthProvider>
      <QueryProvider>
        <UserSettingsProvider>
          <SelectedCountryProvider>
            <CountryStatesProvider>
              <FeatureFlagProvider>
                <NotificationProvider>
                  <DeliveryModelProvider>
                    <NewReleaseProvider>
                      <AnalyticsProvider>{children}</AnalyticsProvider>
                    </NewReleaseProvider>
                  </DeliveryModelProvider>
                </NotificationProvider>
              </FeatureFlagProvider>
            </CountryStatesProvider>
          </SelectedCountryProvider>
        </UserSettingsProvider>
      </QueryProvider>
    </AuthProvider>
  </ThemeProvider>
)

export { Providers }
