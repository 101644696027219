import { HttpMethod, SortDirection, defaultPagination } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { identity, omit, pick } from 'utils'
import { ApiPathname } from '../../constants'
import {
  CreateUserRequest,
  DeleteOneUserRequest,
  GetImpersonationRequest,
  GetImpersonationResponse,
  GetManyUsersRequest,
  GetManyUsersResponse,
  Impersonation,
  CreateOneImpersonationRequest as ImpersonationCreateOneRequest,
  DeleteOneImpersonationRequest as ImpersonationDeleteOneRequest,
  UpdateOneUserRequest,
  User,
} from '../../types'

const isActiveAgnostic: GetManyUsersRequest['isActive'] = [false, true]

const defaultGetManyUsersRequest: GetManyUsersRequest = {
  ...defaultPagination,
  isActive: isActiveAgnostic,
  sort: [SortDirection.DESCENDING],
  sortBy: ['createdAt'],
}

const userMethods = (baseUrl: URL) => {
  const createUserRequest = useRequest<CreateUserRequest, User>({
    baseUrl,
    filterQueryToRequestBody: identity,
    method: HttpMethod.POST,
    pathname: ApiPathname.USERS,
  })

  const createUser = useCallback((payload: CreateUserRequest) => createUserRequest({ ...payload }), [createUserRequest])

  const getUsersRequest = useRequest<GetManyUsersRequest, GetManyUsersResponse>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.USERS,
  })

  const getUsers = useCallback(
    (request: GetManyUsersRequest) =>
      getUsersRequest({
        ...defaultGetManyUsersRequest,
        ...request,
      }),
    [getUsersRequest]
  )

  const deleteUserRequest = useRequest<{ id: DeleteOneUserRequest }, void>({
    baseUrl,
    filterQueryToRouteParams: pick(['id']),
    method: HttpMethod.DELETE,
    pathname: ApiPathname.USER,
    transformResponse: () => Promise.resolve(null), // No response body
  })

  const deleteUser = useCallback((id: DeleteOneUserRequest) => deleteUserRequest({ id }), [deleteUserRequest])

  const updateUserRequest = useRequest<UpdateOneUserRequest, User>({
    baseUrl,
    filterQueryToRequestBody: omit(['id']),
    filterQueryToRouteParams: pick(['id']),
    method: HttpMethod.PATCH,
    pathname: ApiPathname.USER,
  })

  const updateUser = useCallback(
    (payload: UpdateOneUserRequest) => updateUserRequest({ ...payload }),
    [updateUserRequest]
  )

  const createImpersonationRequest = useRequest<ImpersonationCreateOneRequest, Impersonation>({
    baseUrl,
    filterQueryToRequestBody: pick(['id', 'userIdToImpersonate']),
    method: HttpMethod.POST,
    pathname: ApiPathname.IMPERSONATIONS,
  })

  const createImpersonation = useCallback(
    (payload: ImpersonationCreateOneRequest) => createImpersonationRequest({ ...payload }),
    [createImpersonationRequest]
  )

  const getImpersonationRequest = useRequest<GetImpersonationRequest, GetImpersonationResponse>({
    baseUrl,
    filterQueryToRouteParams: identity,
    pathname: ApiPathname.IMPERSONATION,
    transformResponse: async (response) => {
      let data = null

      switch (response.status) {
        case 204:
        case 404:
          data = null
          break
        default:
          return response.json()
      }

      return Promise.resolve(data)
    },
  })

  const getImpersonation = useCallback(
    (request: GetImpersonationRequest) =>
      getImpersonationRequest({
        ...request,
      }),
    [getImpersonationRequest]
  )

  const deleteImpersonationRequest = useRequest<ImpersonationDeleteOneRequest, undefined>({
    baseUrl,
    filterQueryToRouteParams: pick(['id']),
    method: HttpMethod.DELETE,
    pathname: ApiPathname.IMPERSONATION,
    transformResponse: () => Promise.resolve(null), // No response body
  })

  const deleteImpersonation = useCallback(
    (payload: ImpersonationDeleteOneRequest) => deleteImpersonationRequest({ ...payload }),
    [deleteImpersonationRequest]
  )

  return {
    createImpersonation,
    createUser,
    deleteImpersonation,
    deleteUser,
    getImpersonation,
    getUsers,
    updateUser,
  }
}

export { userMethods }
