import { CONTENTFUL_ENVIRONMENT, CONTENTFUL_SPACE_ID } from 'config'
import { Country } from 'constants/'
import { useNotification } from 'hooks'
import { useContentfulClient } from 'hooks/services'
import { useCallback } from 'react'
import { logFactory } from 'utils'

type Language = 'de' | 'en' | 'es' | 'fr'

// More tags can be added here as needed
//  - Production: https://app.contentful.com/spaces/k56xl5mqwit7/environments/master/settings/tags
//  - Staging: https://app.contentful.com/spaces/k56xl5mqwit7/environments/staging/settings/tags
//  - Testing: https://app.contentful.com/spaces/k56xl5mqwit7/environments/testing/settings/tags
const contentfulTags = {
  TELEAUDIOLOGY_USER_PHOTO: 'teleaudiologyUserPhoto',
} as const

type Tag = (typeof contentfulTags)[keyof typeof contentfulTags]

type UploadFileParams = {
  description: string
  file: File
  locale?: `${Language}-${Country}`
  tags?: Tag[]
  title: string
}

type UploadFileResponse = {
  id: string
  title: string
  url: URL
}

interface UseContentfulContext {
  uploadFile: (params: UploadFileParams) => Promise<UploadFileResponse | undefined>
}

const contentfulEnvironmentParams = {
  environmentId: CONTENTFUL_ENVIRONMENT,
  spaceId: CONTENTFUL_SPACE_ID,
} as const

const log = logFactory('hooks', 'useContentful')

const useContentful = (): UseContentfulContext => {
  const client = useContentfulClient()
  const { notify } = useNotification()

  const uploadFile = useCallback(
    async ({ description, file, locale = 'en-US', tags, title }: UploadFileParams) => {
      const reader = new FileReader()

      reader.readAsArrayBuffer(file)

      const buffer = await new Promise<ArrayBuffer>((resolve) => {
        reader.onload = () => {
          resolve(reader.result as ArrayBuffer)
        }
      })

      const contentType = file.type
      const fileName = file.name

      try {
        log('⬆️ Uploading file to Contentful...', { description, file: file.name, locale, title, type: file.type })
        const uploaded = await client.upload.create({ spaceId: contentfulEnvironmentParams.spaceId }, { file: buffer })

        log('⬆️ Uploaded file.', uploaded)

        const assetCreate: Parameters<typeof client.asset.create>[1] = {
          fields: {
            description: {
              [locale]: description,
            },
            file: {
              [locale]: {
                contentType,
                fileName,
                uploadFrom: {
                  sys: {
                    id: uploaded.sys.id,
                    linkType: 'Upload',
                    type: 'Link',
                  },
                },
              },
            },
            title: {
              [locale]: title,
            },
          },
        }

        log('⚙️ Creating asset...', { assetCreate, file: file.name, locale })
        let created = await client.asset.create(contentfulEnvironmentParams, assetCreate)

        log('⚙️ Created asset.', created)

        if (tags && tags.length) {
          created.metadata?.tags?.push({
            sys: {
              id: contentfulTags.TELEAUDIOLOGY_USER_PHOTO,
              linkType: 'Tag',
              type: 'Link',
            },
          })

          log('🏷️ Appending tags to asset...', { fileName, tags })
          const tagged = await client.asset.update({ ...contentfulEnvironmentParams, assetId: created.sys.id }, created)

          log('🏷️ Tags appended to asset.', tagged)

          created = tagged
        }

        log('⚙️ Processing asset...', { asset: created, fileName, locale })
        const processed = await client.asset.processForLocale(contentfulEnvironmentParams, created, locale)

        log('⚙️ Processed asset.', processed)

        log('🗺️ Publishing asset to the world...', { asset: processed, fileName, locale })
        const published = await client.asset.publish(
          { assetId: processed.sys.id, ...contentfulEnvironmentParams },
          processed
        )

        log('🗺️ Published.', published)

        const url = new URL(`https:${published?.fields?.file[locale]?.url ?? '//'}`)
        const id = published?.sys?.id as string
        const assetTitle = published?.fields?.title[locale] as string

        log(`🏁 Asset uploaded!`, { assetTitle, url: url.toString() })

        return {
          id,
          title: assetTitle,
          url,
        }
      } catch (error) {
        console.error('🔴 Unable to save asset to Contentful', error)
        notify('Unable to upload image to Contentful at this time. Please try again later.', 'error')
      }
    },
    [client, notify]
  )

  return { uploadFile }
}

export { useContentful }
