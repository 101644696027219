import { Layout } from 'components/layout'
import { PermissionDenied } from 'components/pages/PermissionDenied'
import { PathEntityName, Permission, Route as RoutePaths } from 'constants/'
import { useUser } from 'hooks'
import { Navigate, Route, Routes as RrRoutes } from 'react-router'
import { NotFound } from '../NotFound'
import { Providers } from '../Providers'
import { Scheduling } from '../Scheduling'
import { Users } from '../Users'
import { Vendors } from 'routes/Vendors'

const Routes = () => {
  const user = useUser()

  if (!user.permissions.size) {
    return <div>Loading...</div>
  }

  const { isAdmin, isOperations } = user?.roleFlags ?? {}
  const isVendorMarketingApprover = user.permissions.has(Permission.VENDORS_MARKETING_APPROVE)
  const isVendorMarketingManager = user.permissions.has(Permission.VENDORS_MARKETING_CREATE)
  const isAccessDenied = !isAdmin && !isOperations && !isVendorMarketingApprover && !isVendorMarketingManager

  return (
    <RrRoutes>
      <Route element={<Layout />} path="/">
        <Route element={<PermissionDenied />} path={RoutePaths.PERMISSION_DENIED} />
        {isAccessDenied ? (
          <>
            <Route element={<Navigate to={RoutePaths.PERMISSION_DENIED} />} index />
            <Route element={<Navigate to={RoutePaths.PERMISSION_DENIED} />} path="/*" />
          </>
        ) : (
          <>
            <Route element={<Navigate to={PathEntityName.SCHEDULING} />} index />
            <Route element={<Scheduling />} path={`${PathEntityName.SCHEDULING}/*`} />
            <Route element={<Providers />} path={`${PathEntityName.PROVIDERS}/*`} />
            <Route element={<Users />} path={`${PathEntityName.USERS}/*`} />
            <Route element={<Vendors />} path={`${PathEntityName.VENDORS}/*`} />
          </>
        )}
        <Route element={<NotFound />} path="/*" />
      </Route>
    </RrRoutes>
  )
}

export { Routes }
