import { defaultPagination, HttpMethod } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { PartnerServicePartnerEmployeeLicense } from 'types'
import { pick } from 'utils'
import { ApiPathname } from '../../constants'
import {
  createPartnerEmployeeLicensesRequestToBody,
  deletePartnerEmployeeLicenseRequestToRouteParams,
  getPartnerEmployeeLicensesQueryToSearchParams,
  updatePartnerEmployeeLicenseRequestToBody,
  updatePartnerEmployeeLicenseRequestToRouteParams,
} from '../../filters'
import {
  CreatePartnerEmployeeLicenseRequest,
  DeletePartnerEmployeeLicenseRequest,
  GetPartnerEmployeeEligibleHearingTestPackageTypesRequest,
  GetPartnerEmployeeEligibleHearingTestPackageTypesResponse,
  GetPartnerEmployeeLicensesQuery,
  GetPartnerEmployeeLicensesResponse,
  UpdatePartnerEmployeeLicenseRequest,
} from '../../types'

const service = (baseUrl: URL) => {
  const createPartnerEmployeeLicenseRequest = useRequest<
    CreatePartnerEmployeeLicenseRequest,
    PartnerServicePartnerEmployeeLicense
  >({
    baseUrl,
    filterQueryToRequestBody: createPartnerEmployeeLicensesRequestToBody,
    method: HttpMethod.POST,
    pathname: ApiPathname.LICENSES,
  })

  const createPartnerEmployeeLicense = useCallback(
    (request: CreatePartnerEmployeeLicenseRequest) => createPartnerEmployeeLicenseRequest(request),
    [createPartnerEmployeeLicenseRequest]
  )

  const deletePartnerEmployeeLicenseRequest = useRequest<DeletePartnerEmployeeLicenseRequest, boolean>({
    baseUrl,
    filterQueryToRouteParams: deletePartnerEmployeeLicenseRequestToRouteParams,
    method: HttpMethod.DELETE,
    pathname: ApiPathname.LICENSE,
    transformResponse: () => Promise.resolve(true),
  })

  const deletePartnerEmployeeLicense = useCallback(
    (request: DeletePartnerEmployeeLicenseRequest) => deletePartnerEmployeeLicenseRequest(request),
    [deletePartnerEmployeeLicenseRequest]
  )

  const getPartnerEmployeeLicensesRequest = useRequest<
    GetPartnerEmployeeLicensesQuery,
    GetPartnerEmployeeLicensesResponse
  >({
    baseUrl,
    filterQueryToSearchParams: getPartnerEmployeeLicensesQueryToSearchParams,
    pathname: ApiPathname.LICENSES,
  })

  const getPartnerEmployeeLicenses = useCallback(
    (query: GetPartnerEmployeeLicensesQuery) =>
      getPartnerEmployeeLicensesRequest({
        ...defaultPagination,
        ...query,
      }),
    [getPartnerEmployeeLicensesRequest]
  )

  const updatePartnerEmployeeLicenseRequest = useRequest<
    UpdatePartnerEmployeeLicenseRequest,
    PartnerServicePartnerEmployeeLicense
  >({
    baseUrl,
    filterQueryToRequestBody: updatePartnerEmployeeLicenseRequestToBody,
    filterQueryToRouteParams: updatePartnerEmployeeLicenseRequestToRouteParams,
    method: HttpMethod.PATCH,
    pathname: ApiPathname.LICENSE,
  })

  const updatePartnerEmployeeLicense = useCallback(
    (request: UpdatePartnerEmployeeLicenseRequest) => updatePartnerEmployeeLicenseRequest(request),
    [updatePartnerEmployeeLicenseRequest]
  )

  const getPartnerEmployeeEligibleHearingTestPackageTypesRequest = useRequest<
    GetPartnerEmployeeEligibleHearingTestPackageTypesRequest,
    GetPartnerEmployeeEligibleHearingTestPackageTypesResponse
  >({
    baseUrl,
    filterQueryToSearchParams: pick(['country', 'partnerId', 'state']),
    pathname: ApiPathname.LICENSE_ELIGIBLE_HEARING_TEST_PACKAGE_TYPES,
  })

  const getPartnerEmployeeEligibleHearingTestPackageTypes = useCallback(
    getPartnerEmployeeEligibleHearingTestPackageTypesRequest,
    [getPartnerEmployeeEligibleHearingTestPackageTypesRequest]
  )

  return {
    createPartnerEmployeeLicense,
    deletePartnerEmployeeLicense,
    getPartnerEmployeeEligibleHearingTestPackageTypes,
    getPartnerEmployeeLicenses,
    updatePartnerEmployeeLicense,
  }
}

export { service as usePartnerEmployeeLicensesService }
