import { RouteParameter } from 'constants/'
import { useVendors, Vendor } from 'hooks'
import { createContext, FC, useContext } from 'react'
import { useParams } from 'react-router'

type RouteParams = {
  [RouteParameter.VENDOR_ID]: string
}

type UseVendorContext = Omit<ReturnType<typeof useVendors>, 'data'> & {
  data?: Vendor
}

const context = createContext({} as UseVendorContext)
const { Provider: ContextProvider } = context

const CurrentVendorProvider: FC = ({ children }) => {
  const { vendorId } = useParams<RouteParams>()
  const { data, ...rest } = useVendors({ id: [vendorId as string] }, { enabled: Boolean(vendorId) })
  const value: UseVendorContext = { ...rest, data: data?.data?.[0] }

  return <ContextProvider value={value}>{children}</ContextProvider>
}

const useCurrentVendor = () => useContext(context)

export { CurrentVendorProvider, useCurrentVendor }
