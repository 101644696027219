import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      color: theme.palette.primary.light,

      '&:focus:focus-visible': {
        outlineColor: theme.palette.primary.light,
        outlineOffset: '-1px',
        outlineStyle: 'auto',
        outlineWidth: '1px',
      },
    },
    subText: {
      display: 'block',
      fontSize: '0.75rem',
      color: theme.palette.grey[600],
    },
  })
) as unknown as UseStylesFunction

export { useStyles }
