import { defaultPagination, defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetPartnerEmployeesQuery, usePartnerService } from '../../services'
import { useSelectedCountry } from '../../useSelectedCountry'

const hookName = 'usePartnerEmployees'
const log = logFactory(hookName)

const usePartnerEmployees = (query?: GetPartnerEmployeesQuery, options: QueryOptions = { enabled: true }) => {
  const { getPartnerEmployees } = usePartnerService()
  const { selectedCountry } = useSelectedCountry()

  const params: GetPartnerEmployeesQuery = {
    ...defaultPagination,
    country: selectedCountry ? [selectedCountry] : undefined,
    sort: 'desc',
    sortBy: 'createdAt',
    ...query,
  }

  const doQuery = async () => {
    log(`👨‍⚕️ Fetching partner employees...`, params)

    const response = await getPartnerEmployees(params)

    log(`👨‍⚕️ Returned partner employees...`, response)

    return response
  }

  const { ids, ...rest } = params

  return useQuery([hookName, ids, rest], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled),
  })
}

export { usePartnerEmployees, hookName as usePartnerEmployeesHookName }
