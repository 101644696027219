import { LAUNCH_DARKLY_CLIENT_ID } from 'config'
import { RoleFlag } from 'constants/'
import { featureFlagNameToValueMap, useMyAccount, useSelectedCountry, useUser } from 'hooks'
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useMemo, useState } from 'react'
import { logFactory } from 'utils'

type RoleFlagKeys = keyof typeof RoleFlag
type RoleFlagFields = { [key in RoleFlagKeys]?: boolean }
type FeatureFlagKey = keyof typeof featureFlagNameToValueMap

interface IdentifyCustomProps extends RoleFlagFields {
  country?: string
  email?: string
  fitter_country?: string
  impersonatorEmail?: string
}

type CustomValue = string | number | boolean | (string | number | boolean)[]

interface Identity {
  key: string
  custom: IdentifyCustomProps
}

const log = logFactory('FeatureFlagProvider')

const FeatureFlagProvider: FC = ({ children }) => {
  const { data: myAccount } = useMyAccount()
  const [isLoadingFlags, beLoadingFlags] = useState(true)
  const { accountId, email, roleFlags } = useUser()
  const launchDarklyClient = useLDClient()
  const fitterCountry = myAccount?.country
  const { selectedCountry } = useSelectedCountry()

  const custom = useMemo<Identity['custom']>(
    () => ({
      country: selectedCountry,
      email: myAccount?.email,
      fitter_country: fitterCountry,
      impersonatorEmail: email ?? myAccount?.email,
      ...roleFlags,
    }),
    [email, fitterCountry, myAccount, roleFlags, selectedCountry]
  )

  useEffect(() => {
    if (!accountId || !launchDarklyClient || !selectedCountry) {
      log('⏳ Waiting for feature flag dependencies to load...')

      return
    }

    const identify = async () => {
      const identity = {
        custom: custom as Record<string, CustomValue>,
        key: accountId,
      }

      log('🚩 Launch Darkly identify', identity)

      const flags = await launchDarklyClient?.identify(identity)

      // Invert the flags to make them easier to read in the logs
      const humanReadableFlags = Object.entries(featureFlagNameToValueMap).reduce((acc, [key, value]) => {
        acc[key as FeatureFlagKey] = Boolean(flags?.[value as string])

        return acc
      }, {} as Record<FeatureFlagKey, boolean>)

      log('🚩 Launch Darkly flags', humanReadableFlags)

      beLoadingFlags(false)
    }

    identify()
  }, [accountId, custom, isLoadingFlags, launchDarklyClient, selectedCountry])

  return <>{children}</>
}

const LaunchDarklyProvider = withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(FeatureFlagProvider)

export { LaunchDarklyProvider as FeatureFlagProvider }
