import { RouteParameter } from 'constants/'
import { useResources } from 'hooks'
import { createContext, FC, useContext } from 'react'
import { useParams } from 'react-router'
import { AppointmentServiceResource } from 'types'

type RouteParams = {
  [RouteParameter.RESOURCE_ID]: string
}

type UseResourceContext = Omit<ReturnType<typeof useResources>, 'data'> & {
  data?: AppointmentServiceResource
}

const context = createContext({} as UseResourceContext)
const { Provider: ContextProvider } = context

const CurrentResourceProvider: FC = ({ children }) => {
  const { resourceId } = useParams<RouteParams>()
  const { data, ...rest } = useResources({ id: [resourceId as string] })
  const resource = data?.data?.[0]
  const value: UseResourceContext = { ...rest, data: resource }

  return <ContextProvider value={value}>{children}</ContextProvider>
}

const useCurrentResource = () => useContext(context)

export { CurrentResourceProvider, useCurrentResource }
