import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useAppointmentsService } from 'hooks'
import React, { useEffect, useState } from 'react'

type Props = {
  isActive: boolean | undefined
  isDisabled: boolean
  id: string | undefined
  service: 'appointment' | 'user'
}

const ControlledSwitch = ({ isActive = false, isDisabled, id = '', service }: Props) => {
  const [checked, setChecked] = useState(isActive)

  const { updateResourceIsActive } = useAppointmentsService()

  useEffect(() => {
    setChecked(isActive)
  }, [isActive])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextIsActive = Boolean(event.target.checked)

    switch (service) {
      case 'appointment':
        await updateResourceIsActive({ id, isActive: nextIsActive })
        setChecked(!event.target.checked)
        break
      default:
        setChecked(isActive)
        break
    }
  }

  return (
    <FormControlLabel
      control={<Switch checked={checked} disabled={isDisabled} onChange={handleChange} />}
      label="Active"
    />
  )
}

export { ControlledSwitch }
