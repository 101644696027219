import { RouteParameter, Route as RouteType } from 'constants/'
import { UserAllowedCountriesProvider, UserRolePermissionsProvider, UserRolesProvider, useUser } from 'hooks'
import { lazy, useEffect } from 'react'
import { Outlet, Route, Routes, useNavigate } from 'react-router'
import { namedExportToDefaultExport, pathnameToRouteParameter } from 'utils'
import { NotFound } from '../NotFound'
import { User } from './$userId'

const userIdRouteParameter = pathnameToRouteParameter(RouteParameter.USER_ID)

const Users = lazy(() =>
  import(
    /* webpackChunkName: "Users" */
    'components/pages/Users'
  ).then(namedExportToDefaultExport('Users'))
)

const NewUser = lazy(() =>
  import(
    /* webpackChunkName: "NewUser" */
    'components/pages/Users/NewUser'
  ).then(namedExportToDefaultExport('NewUser'))
)

const Router = () => {
  const authUser = useUser()
  const navigate = useNavigate()
  const isAdmin = Boolean(authUser?.roleFlags?.isAdmin)

  useEffect(() => {
    if (isAdmin) {
      // We're safe to show these routes
      return
    }

    navigate(RouteType.PERMISSION_DENIED, { replace: true })
  }, [isAdmin, navigate])

  return (
    <UserRolesProvider>
      <UserRolePermissionsProvider>
        <UserAllowedCountriesProvider>
          <Routes>
            <Route element={<Outlet />} path="/">
              <Route element={<Users />} index />
              <Route element={<NewUser />} path="new" />
              <Route element={<User />} path={`${userIdRouteParameter}/*`} />
              <Route element={<NotFound />} path="*" />
            </Route>
          </Routes>
        </UserAllowedCountriesProvider>
      </UserRolePermissionsProvider>
    </UserRolesProvider>
  )
}

export { Router as Users }
