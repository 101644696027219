import { RouteParameter } from 'constants/'
import { usePartnerEmployees } from 'hooks'
import { createContext, FC, useContext } from 'react'
import { useParams } from 'react-router'
import { PartnerServicePartnerEmployee } from 'types'

type RouteParams = {
  [RouteParameter.PARTNER_EMPLOYEE_ID]: string
}

type UsePartnerEmployeeContext = Omit<ReturnType<typeof usePartnerEmployees>, 'data'> & {
  data?: PartnerServicePartnerEmployee
}

const context = createContext({} as UsePartnerEmployeeContext)
const { Provider: ContextProvider } = context

const CurrentPartnerEmployeeProvider: FC = ({ children }) => {
  const { [RouteParameter.PARTNER_EMPLOYEE_ID]: partnerEmployeeId } = useParams<RouteParams>()
  const { data, ...rest } = usePartnerEmployees({ ids: [partnerEmployeeId as string] })
  const partnerEmployee = data?.data?.[0]
  const value: UsePartnerEmployeeContext = { ...rest, data: partnerEmployee }

  return <ContextProvider value={value}>{children}</ContextProvider>
}

const useCurrentPartnerEmployee = () => useContext(context)

export { CurrentPartnerEmployeeProvider, useCurrentPartnerEmployee }
