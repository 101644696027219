enum RouteParameter {
  INTEGRATION_TYPE = 'integrationType',
  LICENSE_ID = 'licenseId',
  PARTNER_EMPLOYEE_ID = 'partnerEmployeeId',
  RESOURCE_ID = 'resourceId',
  SHOP_ID = 'shopId',
  USER_ID = 'userId',
  VENDOR_ID = 'vendorId',
}

export { RouteParameter }
