import { ErrorBoundary } from 'components/shared/ErrorBoundary'
import { Suspense } from 'react'
import { GlobalStyles } from './GlobalStyles'
import { Loader } from './Loader'
import { Router } from './Router'

const App = () => (
  <Suspense fallback={<Loader />}>
    <ErrorBoundary>
      <GlobalStyles />
      <Router />
    </ErrorBoundary>
  </Suspense>
)

export { App }
