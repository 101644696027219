import { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { namedExportToDefaultExport } from 'utils'
import { NotFound } from '../../NotFound'

const ProviderSearch = lazy(() =>
  import(
    /* webpackChunkName: "ProviderSearch" */
    'components/pages/Providers/Search'
  ).then(namedExportToDefaultExport('Search'))
)

const Search = () => (
  <Routes>
    <Route element={<Outlet />} path="/">
      <Route element={<ProviderSearch />} index />
      <Route element={<NotFound />} path="*" />
    </Route>
  </Routes>
)

export { Search as ProviderSearch }
