import { getServiceCountryUrl } from '@audibene/ta.utils-get-service-country-url'
import { useSelectedCountry } from 'hooks'
import { useMemo } from 'react'
import * as config from 'config'

type Params = Parameters<typeof getServiceCountryUrl>[0]

const FALLBACK_COUNTRY: Params['country'] = 'US'
const FALLBACK_URL = new URL('file://UNABLE_TO_DETERMINE_SERVICE_COUNTRY_URL')
const overrides = Object.fromEntries(Object.entries(config).filter(([key]) => key.includes('_SERVICE_URL_')))

const useServiceCountryUrl = (service: Params['service']) => {
  const { selectedCountry } = useSelectedCountry()

  const url = useMemo(() => {
    // This is needed to fetch account information before the selectedCountry is determined (🐔 before the 🥚)
    // We know that the account service is NOT multi-region so this is safe
    const country = (selectedCountry as Params['country']) ?? FALLBACK_COUNTRY

    const options: Params = {
      country,
      environment: config.ENVIRONMENT as Params['environment'],
      overrides, // NOTE: `overrides` will be ignored in `staging` and `production` environments.
      service,
    }

    let url: URL | undefined

    try {
      url = getServiceCountryUrl(options) ?? undefined
    } catch (error) {
      console.error(error)
    }

    return url
  }, [selectedCountry])

  return url ?? FALLBACK_URL
}

export { useServiceCountryUrl }
