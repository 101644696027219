import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.secondary.contrastText,
    },
    info: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.contrastText,
    },
    success: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.secondary.contrastText,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.secondary.contrastText,
    },
  })
)

export { useStyles }
