enum ApiPathname {
  APPOINTMENTS = '/api/v1/appointments',
  RESOURCE = '/api/v1/resources/:id',
  RESOURCES = '/api/v1/resources',
  RESOURCE_CALENDAR_INTEGRATIONS = '/api/v1/resources/:resourceId/calendar-integrations',
  RESOURCE_CALENDAR_INTEGRATIONS_SUMMARIES = '/api/v1/calendar-integrations-summaries',
  RESOURCE_CALENDAR_INTEGRATION = '/api/v1/resources/:resourceId/calendar-integrations/:id',
  SHOPS = '/api/v1/shops',
  SHOP = '/api/v1/shops/:id',
  INTEGRATION_APPOINTMENT_TYPE_MAPPINGS = '/api/v1/calendar-integration-appointment-type-mappings',
  INTEGRATION_APPOINTMENT_TYPE_MAPPING = '/api/v1/calendar-integration-appointment-type-mappings/:id',
  INTEGRATION_MAPPINGS = '/api/v1/calendar-integration-identity-mappings',
  INTEGRATION_MAPPING = '/api/v1/calendar-integration-identity-mappings/:id',
  INTEGRATION_SHOP = '/api/v1/integrations/:type/shops/:id',
  INTEGRATION_SHOP_REFERRAL_SOURCES = '/api/v1/integrations/:type/shops/:id/referral-sources',
}

export { ApiPathname }
