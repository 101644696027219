import styled from '@emotion/styled'
import { TableRow as MuiTableRow } from '@material-ui/core'
import { MouseEvent, ReactNode } from 'react'
import { useNavigate } from 'react-router'

type Props = {
  children: ReactNode
  onClick?: () => void
  to?: string
}

const clickableChildElements = ['A', 'BODY', 'BUTTON']

// @ts-expect-error ???
const isClickableChildElement = (element: HTMLElement) => {
  if (clickableChildElements.includes(element?.nodeName)) {
    return true
  }

  if (element.nodeName === 'TR') {
    return false
  }

  if (element.parentElement) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return isClickableChildElement(element.parentElement)
  }

  return false
}

const TableRow = styled(MuiTableRow)`
  &:hover {
    cursor: pointer;
    background-color: #efefef;
  }

  &:focus {
    background-color: #efefef;
    outline: 1px solid #1976d2;
    border-radius: 0.125rem;
  }

  &:active {
    outline: none;
  }
`

const ClickableTableRow = ({ children, onClick, to, ...props }: Props) => {
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onNavigateClick = (e: MouseEvent<HTMLElement>) => {
    if (isClickableChildElement(e.target as HTMLElement)) {
      return false
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    to ? navigate(to) : onClick!()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeyUp = (e: any) => {
    if (isClickableChildElement(e?.target)) {
      return
    }

    if (e.code === 'Enter') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      to ? navigate(to) : onClick!()
    }
  }

  return (
    <TableRow {...props} onClick={onNavigateClick} onKeyUp={onKeyUp} tabIndex={0}>
      {children}
    </TableRow>
  )
}

export { ClickableTableRow }
