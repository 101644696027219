import { FC } from 'react'
import { LogRocketProvider } from './LogRocket'
import { MixpanelProvider } from './Mixpanel'

const AnalyticsProvider: FC = ({ children }) => (
  <LogRocketProvider>
    <MixpanelProvider>{children}</MixpanelProvider>
  </LogRocketProvider>
)

export { AnalyticsProvider }
