import { Typography } from '@material-ui/core'
import { LayoutContainer } from 'components/layout'
import { Helmet } from 'react-helmet'
import { Link } from '../../shared/Link'
import { useStyles } from './index.styles'

const appTitle = 'Hear.com | Admin Portal'

const PermissionDenied = () => {
  const styles = useStyles()

  return (
    <>
      <Helmet>
        <title>{`${appTitle}: Not Found`}</title>
      </Helmet>
      <LayoutContainer>
        <div className={styles.container}>
          <Typography className={styles.reset} variant="h1">
            403 &nbsp;
            <span aria-label="thinking emoji">🤔</span>
          </Typography>
          <Typography className={styles.reset} variant="h2">
            Permission Denied
          </Typography>
          <Typography className={styles.reset} variant="body1">
            Permission Denied If you would like to return to known territory try&nbsp;
            <Link aria-label="Navigate home" to="http://hear.com">
              going home
            </Link>
          </Typography>
        </div>
      </LayoutContainer>
    </>
  )
}

export { PermissionDenied }
