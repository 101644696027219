import { Environment } from './environment'

const contentfulEnvironments = {
  PRODUCTION: 'master',
  STAGING: 'staging',
  TESTING: 'testing',
} as const

const contentfulContentTypes = {
  ASSET: 'Asset',
} as const

const runtimeEnvironmentToContentfulEnvironment: Record<
  Environment,
  (typeof contentfulEnvironments)[keyof typeof contentfulEnvironments]
> = {
  development: contentfulEnvironments.TESTING,
  production: contentfulEnvironments.PRODUCTION,
  staging: contentfulEnvironments.STAGING,
  testing: contentfulEnvironments.TESTING,
}

export { contentfulContentTypes, contentfulEnvironments, runtimeEnvironmentToContentfulEnvironment }
