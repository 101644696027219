import { Box, CircularProgress } from '@mui/material'

interface Props {
  minHeight?: number | string
}

const Loading = ({ minHeight = '2rem' }: Props) => (
  <Box
    sx={{
      display: 'grid',
      minHeight,
      placeItems: 'center',
      width: '100%',
    }}
  >
    <CircularProgress />
  </Box>
)

export { Loading }
