import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  nav: {
    gridArea: 'nav',
    padding: '0.5rem 0 0.5rem 0.675rem',
  },
  main: {
    alignContent: 'start',
    display: 'grid',
    gap: '0.5rem',
    gridArea: 'main',
    margin: '1rem',
    maxWidth: '100%',
    padding: '0 0 0 0',
  },
})

export { useStyles }
