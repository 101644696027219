import { FORCE_ENABLED_FEATURE_FLAGS as featureFlagOverrides, isLiveEnvironment } from 'config'
import { useFlags } from 'launchdarkly-react-client-sdk'

type FeatureFlagName = 'ENABLE_VENDOR_MANAGEMENT' | 'LICENSE_HEARING_TEST_PACKAGE_TYPES' | 'PROVIDER_EXCLUSION_FORM'
type Uuid = string

// Feature flag key values can be found here: https://app.launchdarkly.com/ta-team-america/production/features
const featureFlagNameToValueMap: Record<FeatureFlagName, Uuid> = {
  ENABLE_VENDOR_MANAGEMENT: '6e83ec4b-0383-4f5e-a03c-193222997c29',
  LICENSE_HEARING_TEST_PACKAGE_TYPES: 'fd3d1b67-692c-43ca-8370-fa25fcd59ab5',
  PROVIDER_EXCLUSION_FORM: '930bc6c2-ee03-43a3-a11e-b2ada68e064c',
} as const

type FeatureFlag = keyof typeof featureFlagNameToValueMap
type NonEmptyArray<T> = [T, ...T[]]

const overrides = (isLiveEnvironment ? [] : featureFlagOverrides) as FeatureFlag[]

if (overrides.length) {
  console.warn(`⚠️ WARNING ⚠️: The following feature flags are enabled via local environment config`, overrides)
}

const forceEnabledFlags = new Set(overrides)

/**
 * @param flags The list of feature flags to fetch values for
 * @returns An ordered array of boolean values matching the provided flags array
 */
const useFeatureFlags = (flags: NonEmptyArray<FeatureFlag>) => {
  const featureFlags = useFlags()

  return flags.map((flag) => forceEnabledFlags.has(flag) || Boolean(featureFlags[featureFlagNameToValueMap[flag]]))
}

export { featureFlagNameToValueMap, useFeatureFlags }
