enum AppointmentCancellationReason {
  CUSTOMER_NOT_INTERESTED = 'CUSTOMER_NOT_INTERESTED',
  OTHER = 'OTHER',
  SYSTEM_ROLLBACK = 'SYSTEM_ROLLBACK',
}

enum AppointmentRescheduleReason {
  CUSTOMER_NOT_AVAILABLE = 'CUSTOMER_NOT_AVAILABLE',
  OTHER = 'OTHER',
}

enum AppointmentType {
  AFTERCARE_FOLLOW_UP = 'AFTERCARE_FOLLOW_UP',
  FOLLOW_UP = 'FOLLOW_UP',
  HEARING_AID_EVALUATION = 'HEARING_AID_EVALUATION',
  VIDEO_CARE_CALL = 'VIDEO_CARE_CALL',
}

// This is for pre-appointment service appointments
enum LegacyAppointmentType {
  FITTING_APPOINTMENT = 'FITTING APPOINTMENT',
  FOLLOW_UP_APPOINTMENT = 'FOLLOW UP APPOINTMENT',
  INITIAL_FITTING = 'HEARING AID EVALUATION',
}

// This is for pre-appointment service appointments
enum LegacyAppointmentStatus {
  PENDING = 'UNKNOWN',
  CANCELLED = 'CANCELLED',
  RESCHEDULED = 'RESCHEDULE',
}

enum AppointmentStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
  RESCHEDULED = 'RESCHEDULED',
}

enum AppointmentResourceType {
  CABIN = 'CABIN',
  CONSULTANT = 'CONSULTANT',
  FITTER = 'FITTER',
}

enum Salutation {
  DOCTOR = 'DOCTOR',
  DOCTOR_MEDICAL = 'DOCTOR_MEDICAL',
  MISS = 'MISS',
  MISSES = 'MISSES',
  MISTER = 'MISTER',
  PROFESSOR = 'PROFESSOR',
  PROFESSOR_DOCTOR = 'PROFESSOR_DOCTOR',
  PROFESSOR_DOCTOR_DOCTOR = 'PROFESSOR_DOCTOR_DOCTOR',
}

export {
  AppointmentCancellationReason,
  AppointmentRescheduleReason,
  AppointmentResourceType,
  AppointmentStatus,
  AppointmentType,
  LegacyAppointmentStatus,
  LegacyAppointmentType,
  Salutation,
}
