import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      fontWeight: 500,
      textDecoration: 'uppercase',
    },
    primary: {
      backgroundColor: theme.palette.primary.light,
      '&:focus,&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    secondary: {
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.light,
      opacity: 0.75,
      '&:focus,&:hover': {
        border: `1px solid ${theme.palette.primary.light}`,
        opacity: 1,
      },
    },
    text: {
      border: 'none',
      color: theme.palette.primary.light,
      opacity: 0.75,
      '&:focus,&:hover': {
        border: 'none',
        opacity: 1,
      },
    },
  })
)

export { useStyles }
