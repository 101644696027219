import { HttpMethod } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { identity } from 'remeda'
import { ApiPathname } from '../../constants'
import {
  calendarIntegrationAppointmentTypeMappingsQueryToSearchParams,
  transformCreateCalendarIntegrationAppointmentTypeMappingRequestToRequestBody,
  transformGetCalendarIntegrationAppointmentTypeMappingRequestToRequestBody,
  transformUpdateCalendarIntegrationAppointmentTypeMappingRequestToRequestBody,
} from '../../filters'
import {
  AppointmentServiceDeleteResponse,
  CreateCalendarIntegrationAppointmentTypeMappingRequest,
  CreateCalendarIntegrationAppointmentTypeMappingResponse,
  DeleteCalendarIntegrationAppointmentTypeMappingRequest,
  GetCalendarIntegrationAppointmentTypeMappingsQuery,
  GetCalendarIntegrationAppointmentTypeMappingsResponse,
  UpdateCalendarIntegrationAppointmentTypeMappingRequest,
} from '../../types'

const calendarIntegrationAppointmentTypeMappingMethods = (baseUrl: URL) => {
  const getCalendarIntegrationAppointmentTypeMappingsRequest = useRequest<
    GetCalendarIntegrationAppointmentTypeMappingsQuery,
    GetCalendarIntegrationAppointmentTypeMappingsResponse
  >({
    baseUrl,
    filterQueryToSearchParams: transformGetCalendarIntegrationAppointmentTypeMappingRequestToRequestBody,
    pathname: ApiPathname.INTEGRATION_APPOINTMENT_TYPE_MAPPINGS,
  })

  const getCalendarIntegrationAppointmentTypeMappings = useCallback(
    (query: GetCalendarIntegrationAppointmentTypeMappingsQuery) =>
      getCalendarIntegrationAppointmentTypeMappingsRequest({
        ...query,
      }),
    [getCalendarIntegrationAppointmentTypeMappingsRequest]
  )

  const createCalendarIntegrationAppointmentTypeMappingRequest = useRequest<
    CreateCalendarIntegrationAppointmentTypeMappingRequest,
    CreateCalendarIntegrationAppointmentTypeMappingResponse
  >({
    baseUrl,
    filterQueryToRequestBody: transformCreateCalendarIntegrationAppointmentTypeMappingRequestToRequestBody,
    method: HttpMethod.POST,
    pathname: ApiPathname.INTEGRATION_APPOINTMENT_TYPE_MAPPINGS,
  })

  const createCalendarIntegrationAppointmentTypeMapping = useCallback(
    (payload: CreateCalendarIntegrationAppointmentTypeMappingRequest) =>
      createCalendarIntegrationAppointmentTypeMappingRequest({
        ...payload,
      }),
    [createCalendarIntegrationAppointmentTypeMappingRequest]
  )

  const updateCalendarIntegrationAppointmentTypeMappingRequest = useRequest<
    UpdateCalendarIntegrationAppointmentTypeMappingRequest,
    CreateCalendarIntegrationAppointmentTypeMappingResponse
  >({
    baseUrl,
    filterQueryToRequestBody: transformUpdateCalendarIntegrationAppointmentTypeMappingRequestToRequestBody,
    filterQueryToRouteParams: calendarIntegrationAppointmentTypeMappingsQueryToSearchParams,
    method: HttpMethod.PATCH,
    pathname: ApiPathname.INTEGRATION_APPOINTMENT_TYPE_MAPPING,
  })

  const updateCalendarIntegrationAppointmentTypeMapping = useCallback(
    (payload: UpdateCalendarIntegrationAppointmentTypeMappingRequest) =>
      updateCalendarIntegrationAppointmentTypeMappingRequest({
        ...payload,
      }),
    [updateCalendarIntegrationAppointmentTypeMappingRequest]
  )

  const deleteCalendarIntegrationAppointmentTypeMappingRequest = useRequest<
    DeleteCalendarIntegrationAppointmentTypeMappingRequest,
    AppointmentServiceDeleteResponse
  >({
    baseUrl,
    filterQueryToRouteParams: identity,
    method: HttpMethod.DELETE,
    pathname: ApiPathname.INTEGRATION_APPOINTMENT_TYPE_MAPPING,
  })

  const deleteCalendarIntegrationAppointmentTypeMapping = useCallback(
    (payload: DeleteCalendarIntegrationAppointmentTypeMappingRequest) =>
      deleteCalendarIntegrationAppointmentTypeMappingRequest({
        ...payload,
      }),
    [deleteCalendarIntegrationAppointmentTypeMappingRequest]
  )

  return {
    createCalendarIntegrationAppointmentTypeMapping,
    deleteCalendarIntegrationAppointmentTypeMapping,
    getCalendarIntegrationAppointmentTypeMappings,
    updateCalendarIntegrationAppointmentTypeMapping,
  }
}

export { calendarIntegrationAppointmentTypeMappingMethods }
