import { useServiceCountryUrl } from '../useServiceCountryUrl'
import { appointmentMethods } from './methods/appointments'
import { calendarIntegrationAppointmentTypeMappingMethods } from './methods/calendarIntegrationAppointmentTypeMapping'
import { calendarIntegrationMappingsMethods } from './methods/calendarIntegrationMapping'
import { resourceCalendarIntegrationMethods } from './methods/calendarIntegrations'
import { resourceMethods } from './methods/resources'
import { shopsMethods } from './methods/shops'
import {
  AppointmentServiceDeleteResponse,
  CreateCalendarIntegrationAppointmentTypeMappingRequest,
  CreateCalendarIntegrationAppointmentTypeMappingResponse,
  CreateCalendarIntegrationMappingRequest,
  CreateCalendarIntegrationMappingResponse,
  CreateCalendarIntegrationRequest,
  CreateCalendarIntegrationResponse,
  DeleteCalendarIntegrationAppointmentTypeMappingRequest,
  DeleteCalendarIntegrationMappingRequest,
  DeleteCalendarIntegrationRequest,
  GetAppointmentsQuery,
  GetAppointmentsResponse,
  GetCalendarIntegrationAppointmentTypeMappingsQuery,
  GetCalendarIntegrationAppointmentTypeMappingsResponse,
  GetCalendarIntegrationMappingsQuery,
  GetCalendarIntegrationMappingsResponse,
  GetCalendarIntegrationShopQuery,
  GetCalendarIntegrationShopReferralSourcesQuery,
  GetCalendarIntegrationShopReferralSourcesResponse,
  GetCalendarIntegrationShopResponse,
  GetCalendarIntegrationsSummariesQuery,
  GetCalendarIntegrationsSummariesResponse,
  GetResourceCalendarIntegrationQuery,
  GetResourceCalendarIntegrationsResponse,
  GetResourcesQuery,
  GetResourcesResponse,
  GetShopsQuery,
  GetShopsResponse,
  UpdateCalendarIntegrationAppointmentTypeMappingRequest,
  UpdateCalendarIntegrationMappingRequest,
  UpdateCalendarIntegrationRequest,
  UpdateResourceIsActiveRequest,
  UpdateResourceResponse,
  UpdateShopRequest,
} from './types'

interface UseHookContext {
  createCalendarIntegrationMapping: (
    payload: CreateCalendarIntegrationMappingRequest
  ) => Promise<CreateCalendarIntegrationMappingResponse>
  deleteCalendarIntegrationMapping: (
    payload: DeleteCalendarIntegrationMappingRequest
  ) => Promise<AppointmentServiceDeleteResponse>
  deleteResourceCalendarIntegration: (
    payload: DeleteCalendarIntegrationRequest
  ) => Promise<AppointmentServiceDeleteResponse>
  getAppointments: (query: GetAppointmentsQuery) => Promise<GetAppointmentsResponse>
  getCalendarIntegrationMappings: (
    query: GetCalendarIntegrationMappingsQuery
  ) => Promise<GetCalendarIntegrationMappingsResponse>
  getCalendarIntegrationShop: (query: GetCalendarIntegrationShopQuery) => Promise<GetCalendarIntegrationShopResponse>
  getCalendarIntegrationShopReferralSources: (
    query: GetCalendarIntegrationShopReferralSourcesQuery
  ) => Promise<GetCalendarIntegrationShopReferralSourcesResponse>
  getCalendarIntegrationsSummaries: (
    query: GetCalendarIntegrationsSummariesQuery
  ) => Promise<GetCalendarIntegrationsSummariesResponse>
  getResources: (query: GetResourcesQuery) => Promise<GetResourcesResponse>
  getShops: (query: GetShopsQuery) => Promise<GetShopsResponse>
  getResourceCalendarIntegrations: (
    query: GetResourceCalendarIntegrationQuery
  ) => Promise<GetResourceCalendarIntegrationsResponse>
  createResourceCalendarIntegration: (
    payload: CreateCalendarIntegrationRequest
  ) => Promise<CreateCalendarIntegrationResponse>
  updateCalendarIntegrationMapping: (
    payload: UpdateCalendarIntegrationMappingRequest
  ) => Promise<CreateCalendarIntegrationMappingResponse>
  updateResourceCalendarIntegration: (
    payload: UpdateCalendarIntegrationRequest
  ) => Promise<CreateCalendarIntegrationResponse>
  createCalendarIntegrationAppointmentTypeMapping: (
    payload: CreateCalendarIntegrationAppointmentTypeMappingRequest
  ) => Promise<CreateCalendarIntegrationAppointmentTypeMappingResponse>
  deleteCalendarIntegrationAppointmentTypeMapping: (
    payload: DeleteCalendarIntegrationAppointmentTypeMappingRequest
  ) => Promise<AppointmentServiceDeleteResponse>
  getCalendarIntegrationAppointmentTypeMappings: (
    payload: GetCalendarIntegrationAppointmentTypeMappingsQuery
  ) => Promise<GetCalendarIntegrationAppointmentTypeMappingsResponse>
  updateCalendarIntegrationAppointmentTypeMapping: (
    payload: UpdateCalendarIntegrationAppointmentTypeMappingRequest
  ) => Promise<CreateCalendarIntegrationAppointmentTypeMappingResponse>
  updateResourceIsActive: (payload: UpdateResourceIsActiveRequest) => Promise<UpdateResourceResponse>
  updateShop: (payload: UpdateShopRequest) => Promise<GetShopsResponse>
}

const useAppointmentsService = (): UseHookContext => {
  const baseUrl = useServiceCountryUrl('appointment')
  const { getAppointments } = appointmentMethods(baseUrl)

  const {
    createCalendarIntegrationAppointmentTypeMapping,
    deleteCalendarIntegrationAppointmentTypeMapping,
    getCalendarIntegrationAppointmentTypeMappings,
    updateCalendarIntegrationAppointmentTypeMapping,
  } = calendarIntegrationAppointmentTypeMappingMethods(baseUrl)

  const {
    createCalendarIntegrationMapping,
    deleteCalendarIntegrationMapping,
    getCalendarIntegrationMappings,
    updateCalendarIntegrationMapping,
  } = calendarIntegrationMappingsMethods(baseUrl)

  const {
    createResourceCalendarIntegration,
    deleteResourceCalendarIntegration,
    getResourceCalendarIntegrations,
    getCalendarIntegrationShop,
    getCalendarIntegrationShopReferralSources,
    getCalendarIntegrationsSummaries,
    updateResourceCalendarIntegration,
  } = resourceCalendarIntegrationMethods(baseUrl)

  const { getResources, updateResourceIsActive } = resourceMethods(baseUrl)
  const { getShops, updateShop } = shopsMethods(baseUrl)

  return {
    createCalendarIntegrationAppointmentTypeMapping,
    createCalendarIntegrationMapping,
    createResourceCalendarIntegration,
    deleteCalendarIntegrationAppointmentTypeMapping,
    deleteCalendarIntegrationMapping,
    deleteResourceCalendarIntegration,
    getAppointments,
    getCalendarIntegrationAppointmentTypeMappings,
    getCalendarIntegrationMappings,
    getCalendarIntegrationShop,
    getCalendarIntegrationShopReferralSources,
    getCalendarIntegrationsSummaries,
    getResourceCalendarIntegrations,
    getResources,
    getShops,
    updateCalendarIntegrationAppointmentTypeMapping,
    updateCalendarIntegrationMapping,
    updateResourceCalendarIntegration,
    updateResourceIsActive,
    updateShop,
  }
}

export * from './types'
export { useAppointmentsService }
