import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    borderRadius: '100%',
    color: theme.palette.primary.light,
    display: 'none',
    height: '2rem',
    margin: 0,
    minWidth: '2rem',
    padding: 0,
    placeItems: 'center',
    position: 'absolute',
    right: '-1rem',
    top: '-0.25rem',
    width: '2rem',
    zIndex: 10000,
    '& span': {
      margin: 0,
      padding: 0,
    },
    '&:active': {
      background: 'white',
    },
    '&:focus': {
      background: 'white',
    },
    '&:hover': {
      background: 'white',
    },
  },
  icon: {
    transform: 'scaleX(-1)',
    transition: 'transform 0.3s ease-in-out',

    '&[aria-expanded="true"]': {
      transform: 'scaleX(1)',
    },
  },
}))

export { useStyles }
