import { IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { useStyles } from './index.styles'

interface Props {
  key: string
}

const CloseButton = ({ key }: Props) => {
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()

  const onClick = () => closeSnackbar(key)

  return (
    <IconButton className={classes.container} onClick={onClick} size="small">
      <CloseIcon />
    </IconButton>
  )
}

export { CloseButton }
