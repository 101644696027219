import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    fontSize: '0.875rem',
    marginLeft: '0.0625rem',
  },
})

export { useStyles }
