import { Environment, contentfulEnvironments, runtimeEnvironmentToContentfulEnvironment } from 'constants/'

if (!process.env.REACT_APP_ACCOUNT_SERVICE_URL_NA) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const dotenv = require('dotenv')

  dotenv.config({
    path: './environments/.env.testing',
  })
}

const ACCOUNT_SERVICE_URL_EU = process.env.REACT_APP_ACCOUNT_SERVICE_URL_EU as string
const ACCOUNT_SERVICE_URL_NA = process.env.REACT_APP_ACCOUNT_SERVICE_URL_NA as string
const APP_VERSION = process.env.REACT_APP_VERSION as string
const APPOINTMENT_SERVICE_URL_EU = process.env.REACT_APP_APPOINTMENT_SERVICE_URL_EU as string
const APPOINTMENT_SERVICE_URL_NA = process.env.REACT_APP_APPOINTMENT_SERVICE_URL_NA as string
const PROVIDER_RECOMMENDATION_SERVICE_URL_EU = process.env.REACT_APP_PROVIDER_RECOMMENDATION_SERVICE_URL_EU as string
const PROVIDER_RECOMMENDATION_SERVICE_URL_NA = process.env.REACT_APP_PROVIDER_RECOMMENDATION_SERVICE_URL_NA as string
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE as string
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string

const CONTENTFUL_MANAGEMENT_API_PERSONAL_ACCESS_TOKEN = process.env
  .REACT_APP_CONTENTFUL_MANAGEMENT_API_PERSONAL_ACCESS_TOKEN as string

const CUSTOMER_SERVICE_URL_EU = process.env.REACT_APP_CUSTOMER_SERVICE_URL_EU as string
const CUSTOMER_SERVICE_URL_NA = process.env.REACT_APP_CUSTOMER_SERVICE_URL_NA as string
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as Environment

const FORCE_ENABLED_FEATURE_FLAGS =
  process.env.REACT_APP_FORCE_ENABLED_FEATURE_FLAGS?.split(',')
    ?.map((flag) => flag || undefined)
    .filter(Boolean) ?? []

const LAUNCH_DARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string
const LOGROCKET_PROJECT_ID = process.env.REACT_APP_LOGROCKET_PROJECT_ID as string
const MIXPANEL_PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string
const PARTNER_SERVICE_URL_EU = process.env.REACT_APP_PARTNER_SERVICE_URL_EU as string
const PARTNER_SERVICE_URL_NA = process.env.REACT_APP_PARTNER_SERVICE_URL_NA as string
const USER_SERVICE_URL_EU = process.env.REACT_APP_USER_SERVICE_URL_EU as string
const USER_SERVICE_URL_NA = process.env.REACT_APP_USER_SERVICE_URL_NA as string
const SCHEDULING_ASSISTANT_URL = process.env.REACT_APP_SCHEDULING_ASSISTANT_URL as string

const CONTENTFUL_SPACE_ID = 'k56xl5mqwit7' as const
const CONTENTFUL_ENVIRONMENT = runtimeEnvironmentToContentfulEnvironment[ENVIRONMENT] ?? contentfulEnvironments.TESTING
const isDev = ENVIRONMENT === Environment.DEVELOPMENT
const liveEnvironments = [Environment.DEVELOPMENT, Environment.PRODUCTION, Environment.STAGING]
const isLiveEnvironment = liveEnvironments.includes(ENVIRONMENT as Environment)

export {
  ACCOUNT_SERVICE_URL_EU,
  ACCOUNT_SERVICE_URL_NA,
  APP_VERSION,
  APPOINTMENT_SERVICE_URL_EU,
  APPOINTMENT_SERVICE_URL_NA,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_MANAGEMENT_API_PERSONAL_ACCESS_TOKEN,
  CONTENTFUL_SPACE_ID,
  CUSTOMER_SERVICE_URL_EU,
  CUSTOMER_SERVICE_URL_NA,
  SCHEDULING_ASSISTANT_URL,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  ENVIRONMENT,
  FORCE_ENABLED_FEATURE_FLAGS,
  LAUNCH_DARKLY_CLIENT_ID,
  LOGROCKET_PROJECT_ID,
  MIXPANEL_PROJECT_TOKEN,
  PARTNER_SERVICE_URL_EU,
  PARTNER_SERVICE_URL_NA,
  PROVIDER_RECOMMENDATION_SERVICE_URL_EU,
  PROVIDER_RECOMMENDATION_SERVICE_URL_NA,
  USER_SERVICE_URL_EU,
  USER_SERVICE_URL_NA,
  isDev,
  isLiveEnvironment,
}
