import { HttpMethod } from 'constants/'
import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { identity } from 'remeda'
import { ApiPathname } from '../../constants'
import {
  calendarIntegrationMappingsQueryToSearchParams,
  transformCreateCalendarIntegrationMappingRequestToRequestBody,
  transformUpdateCalendarIntegrationMappingRequestToRequestBody,
} from '../../filters'
import {
  AppointmentServiceDeleteResponse,
  CreateCalendarIntegrationMappingRequest,
  CreateCalendarIntegrationMappingResponse,
  DeleteCalendarIntegrationMappingRequest,
  GetCalendarIntegrationMappingsQuery,
  GetCalendarIntegrationMappingsResponse,
  UpdateCalendarIntegrationMappingRequest,
} from '../../types'

const calendarIntegrationMappingsMethods = (baseUrl: URL) => {
  const getCalendarIntegrationMappingsRequest = useRequest<
    GetCalendarIntegrationMappingsQuery,
    GetCalendarIntegrationMappingsResponse
  >({
    baseUrl,
    filterQueryToSearchParams: calendarIntegrationMappingsQueryToSearchParams,
    pathname: ApiPathname.INTEGRATION_MAPPINGS,
  })

  const getCalendarIntegrationMappings = useCallback(
    (query: GetCalendarIntegrationMappingsQuery) => getCalendarIntegrationMappingsRequest(query),
    [getCalendarIntegrationMappingsRequest]
  )

  const createCalendarIntegrationMappingRequest = useRequest<
    CreateCalendarIntegrationMappingRequest,
    CreateCalendarIntegrationMappingResponse
  >({
    baseUrl,
    filterQueryToRequestBody: transformCreateCalendarIntegrationMappingRequestToRequestBody,
    method: HttpMethod.POST,
    pathname: ApiPathname.INTEGRATION_MAPPINGS,
  })

  const createCalendarIntegrationMapping = useCallback(
    (payload: CreateCalendarIntegrationMappingRequest) => createCalendarIntegrationMappingRequest(payload),
    [createCalendarIntegrationMappingRequest]
  )

  const updateCalendarIntegrationMappingRequest = useRequest<
    UpdateCalendarIntegrationMappingRequest,
    CreateCalendarIntegrationMappingResponse
  >({
    baseUrl,
    filterQueryToRequestBody: transformUpdateCalendarIntegrationMappingRequestToRequestBody,
    filterQueryToRouteParams: identity,
    method: HttpMethod.PATCH,
    pathname: ApiPathname.INTEGRATION_MAPPING,
  })

  const updateCalendarIntegrationMapping = useCallback(
    (payload: UpdateCalendarIntegrationMappingRequest) => updateCalendarIntegrationMappingRequest(payload),
    [updateCalendarIntegrationMappingRequest]
  )

  const deleteCalendarIntegrationMappingRequest = useRequest<
    DeleteCalendarIntegrationMappingRequest,
    AppointmentServiceDeleteResponse
  >({
    baseUrl,
    filterQueryToRouteParams: identity,
    method: HttpMethod.DELETE,
    pathname: ApiPathname.INTEGRATION_MAPPING,
  })

  const deleteCalendarIntegrationMapping = useCallback(
    (payload: DeleteCalendarIntegrationMappingRequest) => deleteCalendarIntegrationMappingRequest(payload),
    [deleteCalendarIntegrationMappingRequest]
  )

  return {
    createCalendarIntegrationMapping,
    deleteCalendarIntegrationMapping,
    getCalendarIntegrationMappings,
    updateCalendarIntegrationMapping,
  }
}

export { calendarIntegrationMappingsMethods }
