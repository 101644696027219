import { UserRole } from 'hooks/services/useUserService'

const userRoleDisplayText: Record<UserRole, string> = {
  ADMIN: 'Admin',
  ADMIN_LIGHT: 'Admin Light',
  EXTERNAL_TA_FITTER: 'External TA Fitter',
  FITTER_EXTERNAL: 'External Fitter (deprecated)',
  FITTER_INTERNAL: 'Internal Fitter (deprecated)',
  INTERNAL_TA_FITTER: 'Internal TA Fitter',
  OPERATIONS: 'Operations',
  OPS: 'Operations (deprecated)',
  PARTNER_MANAGEMENT: 'Partner Management',
  READ_ONLY: 'Read Only',
  SALES: 'Sales',
  SUPPORT: 'Support',
  VENDOR_MARKETING_APPROVER: 'Marketing Vendor Approver', // Permissions to approve new Marketing Vendors
  VENDOR_MARKETING_MANAGER: 'Marketing Vendor Manager', // Permissions to manage Marketing Vendors
}

const toUserRoleDisplayText = (role: UserRole) => userRoleDisplayText[role] ?? role

export { toUserRoleDisplayText }
