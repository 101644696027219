import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetPartnerEmployeeEligibleHearingTestPackageTypesRequest, usePartnerService } from '../../services'
import { useSelectedCountry } from '../../useSelectedCountry'

const hookName = 'useLicenseEligibleHearingTestPackageTypes'
const log = logFactory(hookName)

const useLicenseEligibleHearingTestPackageTypes = (
  query: Omit<GetPartnerEmployeeEligibleHearingTestPackageTypesRequest, 'country'>,
  options: QueryOptions = { enabled: true }
) => {
  const { getPartnerEmployeeEligibleHearingTestPackageTypes } = usePartnerService()
  const { selectedCountry } = useSelectedCountry()

  const params: GetPartnerEmployeeEligibleHearingTestPackageTypesRequest = {
    ...query,
    country: selectedCountry as string,
  }

  const doQuery = async () => {
    log(`🧪 Fetching license eligible hearing test package types...`, params)

    const response = await getPartnerEmployeeEligibleHearingTestPackageTypes(params)

    log(`🧪 Returned license eligible hearing test package types...`, response)

    return response
  }

  const { partnerId, ...rest } = params

  return useQuery([hookName, partnerId, rest], doQuery, {
    ...defaultQueryOptions,
    ...options,
    enabled: Boolean(options.enabled),
  })
}

export { useLicenseEligibleHearingTestPackageTypes, hookName as useLicenseEligibleHearingTestPackageTypesHookName }
