import styled from '@emotion/styled'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  title?: string
}

const FieldSet = styled('fieldset')`
  border: 1px solid #dedede;
  border-radius: 0.25rem;
  margin: 0;
  padding: 0rem 1rem 0.25rem 1rem;
`

const Legend = styled('legend')`
  color: #555;
  font-size: 1.05rem;
  font-weight: 500;
  margin-left: -0.5rem;
  padding: 0 0.5rem;
`

const CompactSection = ({ children, title }: Props) => {
  const style = title ? { minHeight: '60px' } : { minHeight: '60px', padding: '1rem' }

  return (
    <FieldSet style={style}>
      {title && <Legend>{title}</Legend>}
      {children}
    </FieldSet>
  )
}

export { CompactSection }
