import { FC } from 'react'
import { useStyles } from './index.styles'

const Centered: FC = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.container}>{children}</div>
}

export { Centered }
