import { ReactNode, createContext, useContext } from 'react'
import { UseQueryResult, useQuery } from 'react-query'
import { logFactory } from 'utils'
import { UserRole, useUserService } from '../services/useUserService'

const hookName = 'useUserRolePermissions'
const log = logFactory(hookName)

type UserRolePermissionsContext = UseQueryResult<Record<UserRole, Record<string, string>>>

const context = createContext<UserRolePermissionsContext>({} as UserRolePermissionsContext)
const { Provider } = context

const UserRolePermissionsProvider = ({ children }: { children: ReactNode }) => {
  const { getRolePermissions } = useUserService()

  const doQuery = async () => {
    log(`🙄 Fetching role permissions...`)

    const options = await getRolePermissions(undefined)

    log(`🙄 Returned role permissions...`, options)

    return options
  }

  const value = useQuery([hookName], doQuery, {
    refetchOnWindowFocus: false,
  })

  return <Provider value={value}>{children}</Provider>
}

const useUserRolePermissions = () => useContext(context)

export {
  type UserRolePermissionsContext,
  UserRolePermissionsProvider,
  useUserRolePermissions,
  hookName as useUserRolePermissionsHookName,
}
