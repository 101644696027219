import { useRequest } from 'hooks/services/useRequest'
import { useCallback } from 'react'
import { identity } from 'utils'
import { ApiPathname } from '../../constants'
import { UserCountry, UserRole } from '../../types'

const optionMethods = (baseUrl: URL) => {
  const getAllowedCountriesRequest = useRequest<undefined, UserCountry[]>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.OPTIONS_ALLOWED_COUNTRIES,
  })

  const getAllowedCountries = useCallback(getAllowedCountriesRequest, [getAllowedCountriesRequest])

  const getRolesRequest = useRequest<undefined, UserRole[]>({
    baseUrl,
    filterQueryToSearchParams: identity,
    pathname: ApiPathname.OPTIONS_ROLES,
  })

  const getRoles = useCallback(getRolesRequest, [getRolesRequest])

  const getRolePermissionsRequest = useRequest<undefined, Record<UserRole, Record<string, string>>>({
    baseUrl,
    pathname: ApiPathname.OPTIONS_ROLE_PERMISSIONS,
  })

  const getRolePermissions = useCallback(getRolePermissionsRequest, [getRolePermissionsRequest])

  return { getAllowedCountries, getRolePermissions, getRoles }
}

export { optionMethods }
