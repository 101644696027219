import { useMemo } from 'react'
import { useUser } from '../useUser'

const useUserPermissions = () => {
  const user = useUser()

  return useMemo(
    () => ({
      hasPermission: (permission: string) => Boolean(user?.permissions.has(permission)),
    }),
    [user.permissions]
  )
}

export { useUserPermissions }
