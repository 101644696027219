import { Country } from 'constants/'
import { useLocale } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import { useStyles } from './index.styles'

interface Props {
  country?: Country
}

const Flag = ({ country }: Props) => {
  const classes = useStyles()
  const locale = useLocale()
  const regionNameFetcher = useMemo(() => new Intl.DisplayNames([locale], { type: 'region' }), [locale])
  const [icon, setIcon] = useState('')

  useEffect(() => {
    const asyncWork = async () => {
      const importedIcon = await import(`../../../assets/flags/${country?.toUpperCase()}.svg`)

      setIcon(importedIcon.default)
    }

    country && asyncWork()
  }, [country])

  if (!country) {
    return null
  }

  return <img alt={regionNameFetcher.of(country)} className={classes.flag} src={icon} />
}

export { Flag }
