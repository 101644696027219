import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    height: 'calc(100vh - 7rem)',
    placeItems: 'center',
    placeContent: 'center',
    rowGap: '1rem',
    width: '100%',
  },
  reset: {
    padding: 0,
    margin: 0,
  },
})) as unknown as UseStylesFunction

export { useStyles }
