import { useAuth0 } from '@auth0/auth0-react'
import { CLIENT_REQUEST_ID, ContentType, HttpHeader, HttpMethod } from 'constants/'
import { useCallback } from 'react'
import { AUTH0_AUDIENCE } from 'config'

type HttpClientConfig = Partial<RequestInit>

interface UseHttpClientContext {
  getConfig: (overrides?: Partial<RequestInit>) => Promise<HttpClientConfig>
}

const defaults: Partial<RequestInit> = {
  headers: {
    [HttpHeader.CONTENT_TYPE]: ContentType.JSON,
    [HttpHeader.CLIENT_REQUEST_ID]: CLIENT_REQUEST_ID,
  },
  method: HttpMethod.GET,
}

const useHttpClientConfig = (): UseHttpClientContext => {
  const { getAccessTokenSilently } = useAuth0()

  const getConfig = useCallback(
    async (overrides: Partial<RequestInit> = {}) => {
      const accessToken = await getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
      })

      return {
        ...overrides,
        headers: {
          ...defaults.headers,
          [HttpHeader.AUTHORIZATION]: `Bearer ${accessToken}`,
          ...overrides?.headers,
        },
      }
    },
    [getAccessTokenSilently]
  )

  return { getConfig }
}

export type { HttpClientConfig }
export { useHttpClientConfig }
