import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { QueryOptions } from 'types'
import { logFactory } from 'utils'
import { GetCalendarIntegrationMappingsQuery, useAppointmentsService } from '../../services'

const hookName = 'useIntegrationMappings'
const log = logFactory(hookName)

const useCalendarIntegrationMappings = (
  query: GetCalendarIntegrationMappingsQuery,
  options: QueryOptions = { enabled: true }
) => {
  const {
    createCalendarIntegrationMapping,
    deleteCalendarIntegrationMapping,
    getCalendarIntegrationMappings,
    updateCalendarIntegrationMapping,
  } = useAppointmentsService()

  const params: GetCalendarIntegrationMappingsQuery = {
    ...query,
  }

  const doQuery = async () => {
    log(`👥 Fetching resource integrations...`, params)

    const integrations = await getCalendarIntegrationMappings(params)

    log(`👥 Returned resource integrations...`, params)

    return integrations
  }

  const result = useQuery([hookName, params], doQuery, {
    ...defaultQueryOptions,
    ...options,
  })

  return {
    createCalendarIntegrationMapping,
    deleteCalendarIntegrationMapping,
    updateCalendarIntegrationMapping,
    useCalendarIntegrationMappingsHookName: hookName,
    ...result,
  }
}

export { useCalendarIntegrationMappings }
