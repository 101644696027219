import { defaultQueryOptions } from 'constants/'
import { useQuery } from 'react-query'
import { FitterAccount } from 'types'
import { logFactory } from 'utils'
import { useAccountService } from '../services'

interface UseHookContext {
  data?: FitterAccount
  isError: boolean
  isLoading: boolean
}

const hookName = 'useMyAccount'
const log = logFactory(hookName)

const useMyAccount = (): UseHookContext => {
  const { getMyAccount } = useAccountService()

  const doQuery = async () => {
    log(`🆔 Getting my account...`)

    const myAccount = await getMyAccount(undefined)

    log(`🆔 Found my account`, myAccount)

    return myAccount
  }

  return useQuery([hookName], doQuery, defaultQueryOptions)
}

export { useMyAccount }
