import { RouteParameter } from 'constants/'
import { useUsers } from 'hooks'
import { createContext, FC, useContext } from 'react'
import { useParams } from 'react-router'
import { User } from 'hooks/services/useUserService'

type RouteParams = {
  [RouteParameter.USER_ID]: string
}

type UseUserContext = Omit<ReturnType<typeof useUsers>, 'data'> & {
  data?: User
}

const context = createContext({} as UseUserContext)
const { Provider: ContextProvider } = context

const CurrentUserProvider: FC = ({ children }) => {
  const { userId } = useParams<RouteParams>()
  const { data, ...rest } = useUsers({ id: [userId as string] }, { enabled: Boolean(userId) })
  const value: UseUserContext = { ...rest, data: data?.data?.[0] }

  return <ContextProvider value={value}>{children}</ContextProvider>
}

const useCurrentUser = () => useContext(context)

export { CurrentUserProvider, useCurrentUser }
